import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Checkbox } from 'primereact/checkbox';
import { type CartDataItem, type CartDataProduct } from '@libs/types';
import { type AppDispatch } from '@store/store';
import { deleteCartItemThunk, updateCartItemThunk } from '@store/store/thunk/cart.thunk';
import { downloadFileThunk } from '@store/store/thunk/attachment.thunk';

import { DeleteConfirmModal } from '../DeleteConfirmModal';
import { TrashBlock } from './components';
import styles from './styles.module.scss';

type ProductProps = {
    product: CartDataItem & Omit<CartDataProduct, 'id'>;
};

export const ProductItem = ({ product }: ProductProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
    const [logo, setLogo] = useState<any>();

    useEffect(() => {
        dispatch(downloadFileThunk({ bucket: product.image.bucket, ticket: product.image.fileId }))
            .then((resp) => {
                const result = unwrapResult(resp);
                setLogo(result);
            })
            .catch((e) => console.error(e));
    }, []);

    const closeModal = useCallback(() => {
        setIsConfirmModalOpen(false);
    }, []);

    const openModal = useCallback(() => {
        setIsConfirmModalOpen(true);
    }, []);

    const deleteProduct = () => {
        dispatch(deleteCartItemThunk(product.id));
    };

    const navigateToPage = useCallback((link: string) => {
        navigate(link);
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.productContainer}>
            <div className={classNames(styles.product)}>
                <div className={classNames(styles.productHead, 'flex', 'justify-content-between')}>
                    <div onClick={() => navigateToPage(`/${product.landingPage}/${product.productId}`)}>
                        <img src={logo} />
                    </div>
                    <div className={styles.trash}>
                        <TrashBlock onClick={openModal} />
                    </div>
                </div>
                <div className={classNames(styles.productContent)}>
                    {/* <div className={classNames(styles.title, 'flex', 'align-items-center')}> */}
                    {/*    <div>Основной тариф</div> */}
                    {/* </div> */}
                    <div className={classNames(styles.tariffBody, 'flex')}>
                        <div className={classNames('w-full')}>
                            <div>
                                <div className={classNames(styles.controls, 'flex')}>
                                    {/* <div className={styles.tariffs}> */}
                                    {/* <Dropdown options={tariffs} optionLabel="name" value={tariff} /> */}
                                    {/* </div> */}
                                    {/* <div className={styles.period}> */}
                                    {/*    <Dropdown options={periods} optionLabel="name" value={period} placeholder="Период оплаты" /> */}
                                    {/* </div> */}
                                    <div className={classNames(styles.price)}>
                                        <span>{product.cost}</span> <span>₽</span>
                                    </div>
                                </div>
                            </div>
                            <div className={classNames('flex', 'align-items-center')}>
                                <Checkbox
                                    checked={product.renewal || false}
                                    onChange={async () => await dispatch(
                                            updateCartItemThunk({
                                                itemId: product.id,
                                                body: {
                                                    productId: product.productId,
                                                    tariffId: product.tariffId,
                                                    tariffParams: product.tariffParams,
                                                    renewal: !product.renewal,
                                                },
                                            }),
                                        )}
                                />{' '}
                                <div className={styles.prolongation}>Автопродление</div>
                            </div>
                        </div>
                        <div className={classNames(styles.priceMobile)}>
                            <div className={classNames('flex', 'justify-content-between', 'align-items-center')}>
                                <div>
                                    <span className={styles.cost}>{product.cost}</span> <span>₽</span>
                                </div>
                                <div>
                                    <TrashBlock onClick={openModal} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteConfirmModal isVisible={isConfirmModalOpen} onClick={deleteProduct} closeModal={closeModal} />
        </div>
    );
};
