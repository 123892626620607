import { LogoBlock } from '@modules/ProPackLanding/MainContent/components/LogoBlock';
import { ProductOpportunity } from '@modules/ProPackLanding/MainContent/components/ProductOpportunity';
import { ContentSlider, GainAccess } from '@libs/components';
import React from 'react';
import { type RefObjectType } from '@libs/types';

import { contentSliderList } from './data';

export const MainContent = () => {
    const refs: RefObjectType<HTMLDivElement> = {};

    const setChildRefs = (childRefs: RefObjectType<HTMLDivElement>) => {
        Object.assign(refs, childRefs);
    };

    return (
        <>
            <LogoBlock refs={refs} />
            <ProductOpportunity />
            <ContentSlider list={contentSliderList} />
            <GainAccess
                setRefs={setChildRefs}
                telephoneNumber={'+7 (495) 363-32-32 доб. 5656'}
                productGuid={process.env.REACT_APP_PROPACK_GUID}
            />
        </>
    );
};
