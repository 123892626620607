import classNames from 'classnames';
import { useResize } from '@libs/utils';
import { PriceCenterMainMediumForm } from '@modules/PriceCenterProduct/InteractiveMap/FilterContainer/MainMediumForm';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { ModalWindow } from '@libs/components';
import { ExtendedSearchMediumForm } from '@modules/PriceCenterProduct/InteractiveMap/FilterContainer/ExtendedSearchMediumForm';
import { MainSmallForm } from '@modules/PriceCenterProduct/InteractiveMap/FilterContainer/MainSmallForm';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { getAllChartsThunk } from '@store/store/thunk/pricecenter/getAllCharts.thunk';
import { useForm } from 'react-hook-form';
import { type PriceCenterFilter } from '@store/store/thunk/pricecenter/Models';
import { initialState } from '@store/store/slices/pricecenter.slice';

import styles from './styles.module.scss';

export const FiltersContainer = () => {
    const { isScreenLessSm, isScreenSm, isScreenMd, isScreenLg, isScreenXl } = useResize();

    const dispatch = useDispatch<AppDispatch>();
    const charts = useSelector((state: RootState) => state.priceCenter.charts);

    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const form = useForm<PriceCenterFilter>();

    useEffect(() => {
        dispatch(getAllChartsThunk(charts.filter));
    }, []);

    useEffect(() => {
        if (!charts.isLoading) {
            form.reset(charts.filter);
        }
    }, [charts]);

    const onSubmit = () => {
        dispatch(getAllChartsThunk(form.getValues()));
        setModalVisible(false);
    };

    const closeModal = (e?: any) => {
        if (e) {
            e.stopPropagation();
        }
        setModalVisible(false);
    };

    const buttons = (
        <div className="w-full flex justify-content-start align-items-center gap-3">
            <Button
type="button" label="Показать" className={classNames(styles.showButton)}
onClick={onSubmit} />
            <Button
                type="button"
                label="Сбросить"
                className={classNames(styles.clearButton, 'p-button-outlined')}
                onClick={() => {
                    dispatch(getAllChartsThunk(initialState.charts.filter));
                    closeModal();
                }}
            />
        </div>
    );

    return (
        <div
            className={classNames(styles.filtersContainer)}
            onClick={() => {
                if (!(isScreenMd || isScreenLg || isScreenXl)) {
                    setModalVisible(true);
                }
            }}
        >
            {isScreenMd || isScreenLg || isScreenXl
? (
                <>
                    <PriceCenterMainMediumForm
                        openModal={() => setModalVisible(true)}
                        form={form}
                        isLoading={charts.isLoading}
                    />
                    {buttons}
                </>
            )
: (
                <>
                    <h2>Фильтры</h2>
                    {!isScreenLessSm
? (
                        <Button type="button" label="Сбросить" className={styles.clearButton} />
                    )
: (
                        <div />
                    )}
                </>
            )}
            <ModalWindow isVisible={modalVisible} closeModal={closeModal}>
                <div className={styles.filterModal}>
                    <h3>{isScreenLessSm || isScreenSm ? 'Фильтры' : 'Расширенный поиск'}</h3>
                    {isScreenLessSm || isScreenSm
? (
                        <MainSmallForm form={form} isLoading={charts.isLoading} />
                    )
: (
                        <>
                            <div className={styles.blockDivider} />
                            <ExtendedSearchMediumForm />
                        </>
                    )}
                    <div className={styles.blockDivider} />
                    {buttons}
                </div>
            </ModalWindow>
        </div>
    );
};
