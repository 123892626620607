import React, { useEffect, useLayoutEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AuthProvider, type TAuthConfig, type TRefreshTokenExpiredEvent } from 'react-oauth2-code-pkce';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { loadScript } from '@libs/services';
import { generateRandomString, generateState } from '@libs/utils';

import { store } from './store/store';
import { router } from './routes';

function App() {
    useLayoutEffect(() => {
        window.browserIdValue = crypto.randomUUID();
        loadScript('browser-id.js', '', false);
    }, []);

    const onRefreshTokenExpire = () => {
        window.location.replace('/session-expired');
    };

    const authConfig: TAuthConfig = {
        clientId: process.env.REACT_APP_CLIENT_ID || '',
        authorizationEndpoint: process.env.REACT_APP_AUTH_ENDPOINT || '',
        tokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT || '',
        logoutEndpoint: process.env.REACT_APP_LOGOUT_ENDPOINT || '',
        redirectUri: process.env.REACT_APP_DS_URL || '',
        preLogin: () => localStorage.setItem('preLoginPath', window.location.pathname),
        postLogin: () => window.location.replace(
                localStorage.getItem('preLoginPath') !== '/session-expired'
                    ? localStorage.getItem('preLoginPath') || ''
                    : '',
            ),
        logoutRedirect: `${process.env.REACT_APP_DS_URL}/logout`,
        scope: process.env.REACT_APP_SCOPE || '',
        onRefreshTokenExpire,
        autoLogin: false,
        state: generateState(),
        extraAuthParameters: {
            nonce: crypto.randomUUID(),
            response_mode: 'query',
            response_type: 'code',
        },
    };

    // console.log('authConfig.redirectUri', authConfig.redirectUri);

    const gtmParams = {
        id: 'GTM-TPQVWHKT',
        dataLayerName: 'dataLayer',
    };

    const isGTM = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return <RouterProvider router={router} />;
        } else {
            return (
                <GTMProvider state={gtmParams}>
                    <RouterProvider router={router} />
                </GTMProvider>
            );
        }
    };

    return (
        <Provider store={store}>
            <AuthProvider authConfig={authConfig}>{isGTM()}</AuthProvider>
        </Provider>
    );
}

export default App;
