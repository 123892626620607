import icon1 from '@shared/images/priceCenter/svg/productCapabilities/icon1.svg';
import icon2 from '@shared/images/priceCenter/svg/productCapabilities/icon2.svg';
import icon3 from '@shared/images/priceCenter/svg/productCapabilities/icon3.svg';
import icon4 from '@shared/images/priceCenter/svg/productCapabilities/icon4.svg';

export type CapabilityItem = {
    logo: string;
    title: string;
    description: string;
};

export const capabilitiesItems: CapabilityItem[] = [
    {
        logo: icon1,
        title: 'Актуальная ценовая информация по облигациям',
        description: 'Ежедневный расчет цен в соответствии с перечнем методик, согласно требованиям МСФО 13',
    },
    {
        logo: icon2,
        title: 'Анализ специфических для облигации рисков',
        description: 'Оценка потенциальных потерь, связанных со срочной продажей неликвидных выпусков облигаций',
    },
    {
        logo: icon3,
        title: 'Определение стоимости неликвидных инструментов',
        description: 'Ежедневный расчет стоимости 2 000 облигаций не менее чем по трем методикам',
    },
    {
        logo: icon4,
        title: 'Методики, опирающиеся на зарубежные инструменты',
        description: 'Использование международных бенчмарков при оценке стоимости российских инструментов',
    },
];
