import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTariffs } from "@libs/components/pageBlocks/TariffBlock/services/tariff.service";
import { type TariffFilter } from "@libs/types";

export const getTariffsThunk = createAsyncThunk('tariff/getTariffsThunk', async (body: TariffFilter, { rejectWithValue }) => {
    try {
        const { data } = await getTariffs(body);

        return data.data.rows;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});
