import { httpClient } from "@configs/httpclient.config";
import { AxiosResponse } from "axios";
import { CartItemReq, UpdateCartItemReq } from "../../../libs/types/cart/cartItem.type";
import { Cart } from "../../../libs/types/cart/cart.type";
import { ResponseInfo } from "@libs/types";

export const CART_URL = '/moex-datashop-orderservice/api/datashop/order-service/v1/cart';

export const getCart = (): Promise<AxiosResponse<ResponseInfo<Cart>>> => httpClient.get(CART_URL);

export const getCartCount = (): Promise<AxiosResponse<ResponseInfo<number>>> => httpClient.get(CART_URL + '/count');

export const addCartItem = (requestData: CartItemReq): Promise<AxiosResponse<CartItemReq>> =>
    httpClient.post(CART_URL, requestData);

export const updateCartItem = (itemId: string, requestData: UpdateCartItemReq): Promise<AxiosResponse<ResponseInfo<Cart>>> =>
    httpClient.post(`${CART_URL}/${itemId}`, requestData);

export const deleteCartItem = (itemId: string): Promise<AxiosResponse<ResponseInfo<Cart>>> =>
    httpClient.delete(`${CART_URL}/${itemId}`);
