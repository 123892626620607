import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    addCartItem,
    deleteCartItem,
    getCart,
    getCartCount,
    updateCartItem
} from "@modules/CartPage/services/cart.service";
import { CartItemReq, UpdateCartItemReq } from "@libs/types";

export const getCartThunk = createAsyncThunk('cart/getCart', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getCart();

        return data.data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const getCartCountThunk = createAsyncThunk('cart/getCartCountThunk', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getCartCount();

        return data.data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const addCartItemThunk = createAsyncThunk('cart/addCartItem', async (body: CartItemReq, { rejectWithValue }) => {
    try {
        await addCartItem(body);
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const updateCartItemThunk = createAsyncThunk(
    'cart/updateCartItem',
    async ({ itemId, body }: { itemId: string; body: UpdateCartItemReq }, { rejectWithValue }) => {
        try {
            const { data } = await updateCartItem(itemId, body);

            return data.data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

export const deleteCartItemThunk = createAsyncThunk('cart/deleteCartItem', async (itemId: string, { rejectWithValue }) => {
    try {
        const { data } = await deleteCartItem(itemId);

        return data.data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});
