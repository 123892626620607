import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RangeChart } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/RangeChart';
import { PriceProfitHeader } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/ChartHeaders/PriceProfitHeader';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import {
    type PriceCenterBond,
    type PriceCenterChart,
    type PriceCenterChartPoint,
    type PriceCenterValuation,
} from '@store/store/thunk/pricecenter/Models';
import { parse } from 'date-fns';
import { selectMarketPoint } from '@store/store/thunk/pricecenter/getChartsSelectors.thunk';
import {
    selectPricePoint,
    selectProfitPoint,
    toggleChartVisibility,
    unSelectMarketPoint,
    unSelectPricePoint,
    unSelectProfitPoint,
} from '@store/store/slices/pricecenter.slice';
import { VisibilityToggles } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/VisibilityToggles';
import { MarketHeader } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/ChartHeaders/MarketHeader';
import {
    MarketPointPanel,
    PointHover,
    type PointHoverData,
    priceCenterClickCallback,
    priceCenterTooltipCallback,
    PricePointPanel,
} from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/ChartTooltips';
import { ru } from 'date-fns/locale';

import styles from './styles.module.scss';

import 'chartjs-adapter-date-fns';

export const MapsContainer = () => {
    const dispatch = useDispatch<AppDispatch>();
    const charts = useSelector((state: RootState) => state.priceCenter.charts);

    const [marketChartData, setMarketChartData] = useState<object | undefined>(undefined);
    const [marketChartOptions, setMarketChartOptions] = useState<object | undefined>(undefined);
    const [marketHover, setMarketHover] = useState<PointHoverData<PriceCenterBond> | null>(null);

    const [priceChartData, setPriceChartData] = useState<object | undefined>(undefined);
    const [priceChartOptions, setPriceChartOptions] = useState<object | undefined>(undefined);
    const [priceHover, setPriceHover] = useState<PointHoverData<PriceCenterValuation> | null>(null);

    const [profitChartData, setProfitChartData] = useState<object | undefined>(undefined);
    const [profitChartOptions, setProfitChartOptions] = useState<object | undefined>(undefined);
    const [profitHover, setProfitHover] = useState<PointHoverData<PriceCenterValuation> | null>(null);

    const [profitSelected, setProfitSelected] = useState<boolean>(false);

    useEffect(() => {
        // Карта рынка
        setMarketChartData({
            datasets: mapToMarketDatasets(charts.data.marketCharts),
        });
        setMarketChartOptions({
            animation: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: priceCenterTooltipCallback(
                        'market',
                        charts.data.marketCharts,
                        charts.data.marketSelectedPoints,
                        setMarketHover,
                    ),
                },
            },
            onClick: priceCenterClickCallback(
                charts.data.marketCharts,
                charts.data.marketSelectedPoints,
                (point) => {
                    if (charts.data.marketSelectedPoints.length < 5) {
                        dispatch(selectMarketPoint(point));
                    }
                },
                (point) => {
                    dispatch(unSelectMarketPoint(point));
                },
            ),
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Лет до погашения',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: '% годовых',
                    },
                    position: 'right',
                    ticks: {
                        callback: (value: any) => `${SEPARATED_DECIMAL_2_FORMAT.format(value)}%`,
                    },
                },
            },
        });
        setPriceChartData({
            datasets: mapToPriceDatasets(charts.data.priceCharts),
        });
        setPriceChartOptions({
            animation: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: priceCenterTooltipCallback(
                        'price',
                        charts.data.priceCharts,
                        charts.data.priceSelectedPoints,
                        setPriceHover,
                    ),
                },
            },
            onClick: priceCenterClickCallback(
                charts.data.priceCharts,
                charts.data.priceSelectedPoints,
                (point) => {
                    if (charts.data.priceSelectedPoints.length < 5) {
                        dispatch(selectPricePoint(point));
                    }
                },
                (point) => {
                    dispatch(unSelectPricePoint(point));
                },
            ),
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Дата',
                    },
                    type: 'time',
                    distribution: 'linear',
                    adapters: {
                        date: {
                            locale: ru,
                        },
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'И.П.',
                    },
                    position: 'right',
                    ticks: {
                        callback: (value: any) => SEPARATED_DECIMAL_2_FORMAT.format(value),
                    },
                },
            },
        });
        setProfitChartData({
            datasets: mapToPriceDatasets(charts.data.profitCharts),
        });
        setProfitChartOptions({
            animation: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: priceCenterTooltipCallback(
                        'profit',
                        charts.data.profitCharts,
                        charts.data.profitSelectedPoints,
                        setProfitHover,
                    ),
                },
            },
            onClick: priceCenterClickCallback(
                charts.data.profitCharts,
                charts.data.profitSelectedPoints,
                (point) => {
                    if (charts.data.profitSelectedPoints.length < 5) {
                        dispatch(selectProfitPoint(point));
                    }
                },
                (point) => {
                    dispatch(unSelectProfitPoint(point));
                },
            ),
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Дата',
                    },
                    type: 'time',
                    distribution: 'linear',
                    adapters: {
                        date: {
                            locale: ru,
                        },
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'И.П.',
                    },
                    position: 'right',
                    ticks: {
                        callback: (value: any) => SEPARATED_DECIMAL_2_FORMAT.format(value),
                    },
                },
            },
        });
    }, [charts.data]);

    const generateMarketStaticTooltip = useCallback((point: PriceCenterChartPoint<PriceCenterBond>) => {
        return <MarketPointPanel point={point} />;
    }, []);

    const generatePriceProfitStaticTooltip = useCallback((point: PriceCenterChartPoint<PriceCenterValuation>) => {
        return <PricePointPanel point={point} />;
    }, []);

    const priceAndProfitHeader = useMemo(() => {
        const toggle = () => setProfitSelected(!profitSelected);

        return <PriceProfitHeader profitSelected={profitSelected} onChange={toggle} />;
    }, [profitSelected]);

    return (
        <div className={classNames(styles.mapsContainer)}>
            <div className="relative w-full flex flex-column align-items-center gap-4">
                <MarketHeader />
                {marketHover && <PointHover {...marketHover} />}
                <RangeChart
                    groupChartCode="market"
                    rawChartData={marketChartData}
                    rawChartOptions={marketChartOptions}
                    selectedPoints={charts.data.marketSelectedPoints}
                    generatePointTooltip={generateMarketStaticTooltip}
                />
                <VisibilityToggles
                    groupChartCode="market"
                    data={charts.data.marketCharts}
                    toggleChartVisibility={(chartCode: string) => {
                        dispatch(
                            toggleChartVisibility({
                                groupChartCode: 'market',
                                chartCode,
                            }),
                        );
                    }}
                    colors={priceCenterMarketColors}
                />
            </div>

            {!profitSelected && (
                <div className="relative w-full flex flex-column align-items-center gap-4">
                    {priceAndProfitHeader}
                    {priceHover && <PointHover {...priceHover} />}
                    <RangeChart
                        groupChartCode="price"
                        rawChartData={priceChartData}
                        rawChartOptions={priceChartOptions}
                        selectedPoints={charts.data.priceSelectedPoints}
                        generatePointTooltip={generatePriceProfitStaticTooltip}
                    />
                    <VisibilityToggles
                        groupChartCode="price"
                        data={charts.data.priceCharts}
                        toggleChartVisibility={(chartCode: string) => {
                            dispatch(
                                toggleChartVisibility({
                                    groupChartCode: 'price',
                                    chartCode,
                                }),
                            );
                        }}
                        colors={priceCenterPriceProfitColor}
                    />
                </div>
            )}
            {profitSelected && (
                <div className="relative w-full flex flex-column align-items-center gap-4">
                    {priceAndProfitHeader}
                    {profitHover && <PointHover {...profitHover} />}
                    <RangeChart
                        groupChartCode="profit"
                        rawChartData={profitChartData}
                        rawChartOptions={profitChartOptions}
                        selectedPoints={charts.data.profitSelectedPoints}
                        generatePointTooltip={generatePriceProfitStaticTooltip}
                    />
                    <VisibilityToggles
                        groupChartCode="profit"
                        data={charts.data.profitCharts}
                        toggleChartVisibility={(chartCode: string) => {
                            dispatch(
                                toggleChartVisibility({
                                    groupChartCode: 'profit',
                                    chartCode,
                                }),
                            );
                        }}
                        colors={priceCenterPriceProfitColor}
                    />
                </div>
            )}
        </div>
    );
};

const mapToMarketDatasets = (charts: Array<PriceCenterChart<PriceCenterBond[]>>): object[] => {
    const datasets: object[] = [];

    for (let index = 0; index < charts.length; index++) {
        const chartData = charts[index];

        if (index === 0) {
            // кривая доходности
            const newChart = {
                type: 'scatter',
                showLine: true,
                tension: 0.4,
                data: [] as any,
                pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                pointBorderColor: 'rgba(0, 0, 0, 0)',
                backgroundColor: getProfitCurveGradient,
                borderColor: priceCenterMarketColors[index],
                borderWidth: 2,
                fill: true,
            };

            if (chartData.visible) {
                for (const bond of chartData?.data ?? []) {
                    newChart.data.push({
                        x: bond.valDate,
                        y: bond.value,
                    });
                }
            }
            datasets.push(newChart);
        } else {
            // точки
            const newChart = {
                type: 'scatter',
                data: [] as any,
                backgroundColor: priceCenterMarketColors[index],
                pointRadius: 4,
                borderColor: 'white',
                hoverBorderColor: 'white',
                borderWidth: 1,
            };

            if (chartData.visible) {
                for (const bond of chartData?.data ?? []) {
                    newChart.data.push({
                        x: bond.valDate,
                        y: bond.value,
                    });
                }
            }
            datasets.push(newChart);
        }
    }

    return datasets;
};

const getProfitCurveGradient = (context: any) => {
    const { chart } = context;
    const { ctx, chartArea } = chart;

    if (!chartArea) {
        // This case happens on initial chart load
        return;
    }
    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(1, 'rgba(115, 72, 237, 0.4)');
    gradient.addColorStop(0.5, 'rgba(115, 72, 237, 0.2)');
    gradient.addColorStop(0, 'rgba(0, 0, 0, 0)');

    return gradient;
};

const mapToPriceDatasets = (charts: Array<PriceCenterChart<PriceCenterValuation[]>>): object[] => {
    const datasets: object[] = [];

    for (let index = 0; index < charts.length; index++) {
        const chartData = charts[index];
        const newChart = {
            type: 'scatter',
            showLine: true,
            tension: 0.1,
            data: [] as any,
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            borderColor: priceCenterPriceProfitColor[index],
            borderWidth: 2,
        };

        if (chartData.visible) {
            for (const bond of chartData?.data ?? []) {
                newChart.data.push({
                    x: parse(bond.date, 'yyyy-MM-dd', new Date()),
                    y: bond.value,
                });
            }
        }
        datasets.push(newChart);
    }

    return datasets;
};

export const priceCenterMarketColors = ['#7348ED', '#48A31A', '#2380EB', '#EBC713'];

export const priceCenterPriceProfitColor = [
    '#48A31A',
    '#2380EB',
    '#EBC713',
    '#0090FF',
    '#8EDC1F',
    '#8B52FF',
    '#EDD567',
    '#1A51C7',
];

const SEPARATED_DECIMAL_2_FORMAT = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});
