import classNames from 'classnames';
import { useState } from 'react';
import { SliderButtons } from '@libs/components';
import parse from 'html-react-parser';

import styles from './styles.module.scss';

type Props = {
    list: ContentSliderItem[];
};

type ContentSliderItem = {
    heading: string;
    title: string;
    text: string;
    image: string;
    styles?: string;
    customHtmlText?: string;
};

export const ContentSlider = ({ list }: Props) => {
    const [currentSlide, setCurrentSlide] = useState<number>(0);

    const DotList = () => (
        <div className={classNames(styles.dotsList)}>
            {list.map((_, i) => {
                return (
                    <div
                        key={_.title}
                        className={classNames(i === currentSlide && styles.activeDot)}
                        onClick={() => setCurrentSlide(i)}
                    />
                );
            })}
        </div>
    );

    const Buttons = () => (
        <SliderButtons
            className={styles.sliderButton}
            activeSlide={currentSlide}
            setActiveSlide={setCurrentSlide}
            totalNumber={list.length}
        />
    );

    return (
        <section className={classNames(styles.sliderWrapper, 'flex')}>
            <div className={classNames(styles.textBlock)}>
                <div>
                    <div
                        style={{
                            transform: `translateX(-${currentSlide * 100}%)`,
                        }}
                    >
                        {list.map((item) => (
                            <div key={item.title} className={classNames(styles.textItem, item.styles)}>
                                <p>{item.heading}</p>
                                <h2>{item.title}</h2>
                                <span>{item.text}</span>
                                {item.customHtmlText && parse(item.customHtmlText)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <DotList />
            <div>
                <div className={classNames(styles.imageBlock)}>
                    <div
                        style={{
                            transform: `translateX(-${currentSlide * 100}%)`,
                        }}
                    >
                        {list.map((item) => {
                            return <img key={item.title} src={item.image} />;
                        })}
                    </div>
                </div>
                <Buttons />
            </div>
        </section>
    );
};
