import { Button } from 'primereact/button';
import classNames from 'classnames';
import { fields } from '@modules/PersonalAccount/PersonalAccountPage/components/MainContent/components/AccountCard/components/GetUserDataForm/data';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, getPersonalInformationThunk, type RootState } from '@store/store';
import { useEffect } from 'react';
import { type PersonalInfo } from '@libs/types';

import styles from './styles.module.scss';

export const GetUserDataForm = ({ closeOuterModal }: { closeOuterModal: () => void }) => {
    const dispatch = useDispatch<AppDispatch>();

    const personalInfo = useSelector((state: RootState) => state.user.personalInfo);

    const openInfoHandler = () => {
        window.location.href = '/docs/Инструкция по удалению аккаунта в Datashop и отзыв согласия на обработку.docx';
    };

    useEffect(() => {
        dispatch(getPersonalInformationThunk());
    }, []);

    return (
        <>
            <div className={classNames(styles.userDataWrapper)}>
                <h2>Личные данные</h2>
                <div className={classNames(styles.userData)}>
                    {fields.map(({ title, code }) => {
                        return (
                            <div
                                key={code}
                                className={classNames('flex', 'justify-content-between', 'align-items-top')}
                            >
                                <span>{title}:</span>
                                <span>{personalInfo[code as keyof PersonalInfo] || '-'}</span>
                            </div>
                        );
                    })}
                </div>
                <div className={classNames('flex', 'align-items-center')}>
                    <Button onClick={closeOuterModal} label="Закрыть" />
                    <span onClick={openInfoHandler} role={'button'}>
                        Удалить персональные данные
                    </span>
                </div>
            </div>
        </>
    );
};
