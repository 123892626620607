import { PriceCenterDict } from '@store/store/thunk/pricecenter/Models';

export const CALC_METHODS_DICT: Array<PriceCenterDict> = [
    {
        code: '1',
        name: '1 метод'
    },
    {
        code: '2',
        name: '2 метод'
    },
    {
        code: '3',
        name: '3 метод'
    }
];

export const STATUS_DICT: Array<PriceCenterDict> = [
    {
        code: '1',
        name: 'Прочие'
    },
    {
        code: '2',
        name: 'Аннулирован'
    },
    {
        code: '3',
        name: 'В обращении'
    },
    {
        code: '4',
        name: 'Готовится'
    },
    {
        code: '5',
        name: 'Дефолт'
    },
    {
        code: '6',
        name: 'Не определено'
    },
    {
        code: '7',
        name: 'Прекращение'
    },
    {
        code: '8',
        name: 'Приостановлен'
    },
    {
        code: '9',
        name: 'Размещается'
    },
    {
        code: '10',
        name: 'Размещен'
    },
    {
        code: '11',
        name: 'Списано'
    },
    {
        code: '12',
        name: 'Аннулирован как недействительный'
    },
    {
        code: '13',
        name: 'Аннулирован как несостоявшийся'
    },
    {
        code: '14',
        name: 'Аннулирован при конвертации/редомициляции'
    },
    {
        code: '15',
        name: 'Аннулирован при объединении'
    },
    {
        code: '16',
        name: 'В проекте'
    },
    {
        code: '17',
        name: 'Погашен'
    }
];

export const LISTING_LEVEL_DICT: Array<PriceCenterDict> = [
    {
        code: '1',
        name: 'Все уровни'
    },
    {
        code: '2',
        name: '1 уровень'
    },
    {
        code: '3',
        name: '2 уровень'
    },
    {
        code: '4',
        name: '3 уровень'
    }
];

export const SECTOR_DICT: Array<PriceCenterDict> = [
    {
        code: '1',
        name: 'Все сектора'
    },
    {
        code: '2',
        name: 'Государственные облигации'
    },
    {
        code: '3',
        name: 'Корпоративные облигации'
    },
    {
        code: '4',
        name: 'Муниципальные облигации'
    },
    {
        code: '5',
        name: 'Еврооблигации'
    },
    {
        code: '6',
        name: 'Прочие'
    },
    {
        code: '7',
        name: 'Не определено'
    }
];

export const ISSUER_SECTOR_DICT: Array<PriceCenterDict> = [
    {
        code: '1',
        name: 'Все отрасли'
    },
    {
        code: '2',
        name: 'Энергоресурсы'
    },
    {
        code: '3',
        name: 'Электроэнергетика'
    },
    {
        code: '4',
        name: 'Телекоммуникации'
    },
    {
        code: '5',
        name: 'СМИ'
    },
    {
        code: '6',
        name: 'Тяжёлое машиностроение'
    },
    {
        code: '7',
        name: 'Автомобилестроениe'
    },
    {
        code: '8',
        name: 'Металлы и добыча'
    },
    {
        code: '9',
        name: 'Финансы'
    },
    {
        code: '10',
        name: 'Строительство и недвижимость'
    },
    {
        code: '11',
        name: 'ЖКХ'
    },
    {
        code: '12',
        name: 'Легкая промышленность'
    },
    {
        code: '13',
        name: 'Пищевая промышленность'
    },
    {
        code: '14',
        name: 'Химия и хипром'
    },
    {
        code: '15',
        name: 'Потребительский сектор'
    },
    {
        code: '16',
        name: 'Транспорт'
    },
    {
        code: '17',
        name: 'Фармацевтика и медицина'
    },
    {
        code: '18',
        name: 'ИТ и технологии'
    },
    {
        code: '19',
        name: 'Лесная промышленность'
    },
    {
        code: '20',
        name: 'Прочие'
    }
];
