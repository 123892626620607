import mainStyles from '@modules/Algopack/AlgopackPage/components/DataConstitution/DataItem/styles.module.scss';
import { type DataItemType } from '@modules/Algopack/AlgopackPage/components/DataConstitution/DataItem';
import { ReactComponent as UpdateLogo } from '@shared/images/algopack/dataConstitution/updateLogo.svg';
import { ReactComponent as GreenMark } from '@shared/images/algopack/dataConstitution/greenMark.svg';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const MobileVersion = ({
    title,
    tags,
    description,
    logo,
    dataUpdateTime,
    historicalData,
    isPythonLib,
}: DataItemType) => {
    return (
        <>
            <div className={classNames(styles.titleWrapper, 'flex')}>
                <img src={logo} />
                <div className={classNames(mainStyles.titleWrapper, 'flex', 'flex-column')}>
                    <h4>{title}</h4>
                    <div className={classNames(mainStyles.tagsWrapper, 'flex')}>
                        {tags.map((tag) => (
                            <span key={tag}>{tag}</span>
                        ))}
                    </div>
                </div>
            </div>

            <p className={styles.descriptionWrapper}>
                {description}
                <br />
                {historicalData && `Исторические данные ${historicalData}.`}
            </p>

            <div className={classNames(styles.infoWrapper, 'flex')}>
                <div className={classNames('flex', 'align-items-center')}>
                    <UpdateLogo />
                    <span>{dataUpdateTime}</span>
                </div>

                {isPythonLib && (
                    <div className={classNames('flex', 'align-items-center')}>
                        <GreenMark />
                        <span>Python библиотека</span>
                    </div>
                )}
            </div>
        </>
    );
};
