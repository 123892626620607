import { useEffect, useState } from 'react';

import styles from './styles.module.scss';

const DYMNAMIC_TITLE_TIMEOUT = 3000;

export const DynamicTitle = () => {
    const titles = process.env.REACT_APP_INVESTORPRO_DYNAMIC_TITLES?.split('&');

    const [currentTitle, setCurrentTitle] = useState<string>(titles?.length ? titles[0] : '');
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
        const timerId = setInterval(() => {
            if (titles?.length && currentIndex < titles.length - 1) {
                const nextIndex = currentIndex !== titles.length - 1 ? currentIndex + 1 : 0;
                setCurrentTitle(titles[nextIndex]);
                setCurrentIndex(nextIndex);
            }
        }, DYMNAMIC_TITLE_TIMEOUT);

        return () => {
            clearInterval(timerId);
        };
    }, [currentIndex]);

    return (
        <section className={styles.dynamicTitleWrapper}>
            <p>Сделано профессионалами для профессионалов</p>
            <h2>
                Идеально подойдет <br />
                для <span>{currentTitle}</span>
            </h2>
        </section>
    );
};
