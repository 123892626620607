import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { RadioButton } from 'primereact/radiobutton';

import styles from './styles.module.scss';

export const ExtendedSearchMediumForm = () => {
    return (
        <>
            <div className="w-full flex flex-column align-items-start gap-2">
                <div className="w-full flex justify-content-between align-items-center">
                    <p className={styles.inputLabel}>Страна эмитента</p>
                    <Dropdown placeholder="Неважно" className={styles.dropdownInput} />
                </div>

                <div className="w-full flex justify-content-between align-items-center">
                    <p className={styles.inputLabel}>Валюта номинала</p>
                    <Dropdown placeholder="Рубль" className={styles.dropdownInput} />
                </div>

                <div className="w-full flex justify-content-between align-items-center">
                    <p className={styles.inputLabel}>Вид купона</p>
                    <Dropdown placeholder="Любой" className={styles.dropdownInput} />
                </div>

                <div className="w-full flex justify-content-between align-items-center">
                    <p className={styles.inputLabel}>Вид выпуска</p>
                    <Dropdown placeholder="Все выпуски" className={styles.dropdownInput} />
                </div>

                <div className="w-full flex justify-content-between align-items-center">
                    <p className={styles.inputLabel}>Наличие рейтинга</p>
                    <Dropdown placeholder="Не имеет значения" className={styles.dropdownInput} />
                </div>
            </div>

            <div className="w-full flex justify-content-between align-items-center">
                <p className={styles.inputLabel}>Погашение</p>
                <div className={styles.dateRangeBlock}>
                    <Calendar
value={new Date()} dateFormat="dd.mm.yy" showIcon
className={styles.calendarInput} />
                    <i className="pi pi-minus" />
                    <Calendar
value={new Date()} dateFormat="dd.mm.yy" showIcon
className={styles.calendarInput} />
                </div>
            </div>

            <div className="w-full flex flex-column align-items-start gap-2">
                <div className="w-full flex justify-content-between align-items-center">
                    <p className={styles.inputLabel}>Купонов в год</p>
                    <div className="flex justify-content-between align-items-center gap-2">
                        <InputText className={classNames(styles.inputBoundary)} />
                        <i className="pi pi-minus" style={{ fontSize: '8px' }} />
                        <InputText className={classNames(styles.inputBoundary)} />
                    </div>
                </div>

                <div className="w-full flex justify-content-between align-items-center">
                    <p className={styles.inputLabel}>Ставка купона (%)</p>
                    <div className="flex justify-content-between align-items-center gap-2">
                        <InputText className={classNames(styles.inputBoundary)} />
                        <i className="pi pi-minus" style={{ fontSize: '8px' }} />
                        <InputText className={classNames(styles.inputBoundary)} />
                    </div>
                </div>

                <div className="w-full flex justify-content-between align-items-center">
                    <p className={styles.inputLabel}>Дюрация Маколея (лет)</p>
                    <div className="flex justify-content-between align-items-center gap-2">
                        <InputText className={classNames(styles.inputBoundary)} />
                        <i className="pi pi-minus" style={{ fontSize: '8px' }} />
                        <InputText className={classNames(styles.inputBoundary)} />
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-column align-items-start gap-3">
                <div className="w-full flex justify-content-between align-items-center">
                    <p className={styles.inputLabel}>Гарантия</p>
                    <RadioGroup />
                </div>
                <div className="w-full flex justify-content-between align-items-center">
                    <p className={styles.inputLabel}>Амортизация</p>
                    <RadioGroup />
                </div>
                <div className="w-full flex justify-content-between align-items-center">
                    <p className={styles.inputLabel}>Выкуп эмитентом</p>
                    <RadioGroup />
                </div>
                <div className="w-full flex justify-content-between align-items-center">
                    <p className={styles.inputLabel}>Оферта</p>
                    <RadioGroup />
                </div>
                <div className="w-full flex justify-content-between align-items-center">
                    <p className={styles.inputLabel}>Индексируемый номинал</p>
                    <RadioGroup />
                </div>
            </div>
        </>
    );
};

const RadioGroup = () => {
    return (
        <div className="flex flex-wrap gap-4">
            <div className="flex align-items-center">
                <RadioButton inputId="radio1" name="radio1" value="Есть" />
                <label htmlFor="radio1" className={classNames('ml-2', styles.inputLabel)}>
                    Есть
                </label>
            </div>

            <div className="flex align-items-center">
                <RadioButton inputId="radio1" name="radio1" value="Нет" />
                <label htmlFor="radio1" className={classNames('ml-2', styles.inputLabel)}>
                    Нет
                </label>
            </div>

            <div className="flex align-items-center">
                <RadioButton
inputId="radio1" name="radio1" value="Неважно"
checked />
                <label htmlFor="radio1" className={classNames('ml-2', styles.inputLabel)}>
                    Неважно
                </label>
            </div>
        </div>
    );
};
