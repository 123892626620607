import { addChatHandler, getChatHash, removeChatHandler } from '@libs/services';
import { useAuthorize } from '@libs/utils';
import { type AppDispatch, checkEmailThunk, type RootState } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, ScrollRestoration, useNavigate, useSearchParams } from 'react-router-dom';
import { httpClient } from '@configs/httpclient.config';
import { useEffect } from 'react';
import { YandexMetrica } from '@libs/ym/YandexMetrica';
import { unwrapResult } from '@reduxjs/toolkit';

import { Loader } from '../../loaders/Loader';
import { Header } from '../../pageBlocks/Header';

type chatData = {
    uuid: string;
    hash: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
};

export const Layout = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { isAuthorized, token, tokenData } = useAuthorize();

    if (token) {
        httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    const isCommonLoading = useSelector((state: RootState) => state.loading.isLoading);
    const isAlgopackLoading = useSelector((state: RootState) => state.algopack.isLoading);
    const isUserDataLoading = useSelector((state: RootState) => state.user.isLoading);
    const isChartsLoading = useSelector((state: RootState) => state.priceCenter.charts.isLoading);

    const isLoading = isCommonLoading || isAlgopackLoading || isUserDataLoading || isChartsLoading;

    useEffect(() => {
        const emailToken = searchParams.get('checkemail');

        if (emailToken) {
            dispatch(checkEmailThunk(emailToken))
                .then((res) => {
                    const result = unwrapResult(res);

                    if (result?.status?.errorCode) {
                        navigate('/personal-account/not-valid-link');
                    } else {
                        navigate('/personal-account?confirmed=true');
                    }
                })
                .catch((e) => {
                    console.log(e);
                    navigate('/personal-account/session-expired');
                });
        }
    }, []);

    useEffect(() => {
        localStorage.removeItem('webchat-channel_377e8bf-uuid');
        localStorage.removeItem('webchat-channel_3c68cbb-uuid');
        localStorage.removeItem('webchat-channel_395750d-uuid');

        if (isAuthorized) {
            getChatHash()
                .then((value: any) => {
                    const data: chatData = {
                        uuid: value.data.data.uuid,
                        hash: value.data.data.hash,
                        first_name: value.data.data.firstName,
                        last_name: value.data.data.lastName,
                    };

                    window.__WebchatUserCallback = () => {
                        if (data.uuid && data.first_name && data.last_name) {
                            //console.log('__WebchatUserCallback fully authorized', data);

                            return data;
                        } else if (data.uuid) {
                            const data: chatData = {
                                uuid: value.data.data.uuid,
                                hash: value.data.data.hash,
                            };

                            //console.log('__WebchatUserCallback partially authorized', data);

                            return data;
                        }
                    };

                    if (data.uuid && data.first_name && data.last_name) {
                        removeChatHandler();
                        addChatHandler('fully_authorized');
                    } else {
                        removeChatHandler();
                        addChatHandler('partially_authorized');
                    }
                })
                .catch((err: any) => {
                    console.log(err);
                });
        } else {
            //console.log('__WebchatUserCallback unauthorized');
            removeChatHandler();
            addChatHandler('unauthorized');
        }
    }, [isAuthorized]);

    return (
        <YandexMetrica>
            <Header />
            <Outlet />
            <ScrollRestoration />
            <Loader isLoading={isLoading} />
        </YandexMetrica>
    );
};
