import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { ReactComponent as StarFilledIcon } from '../../../../../../shared/images/svg/StarFilled.svg';
import { ReactComponent as StarIcon } from '../../../../../../shared/images/svg/Star.svg';
import { Search } from '../../../../../../shared/components/Search';
import { type ScreenerRowType } from '../../../../services/types';

type IsFavouritePropsType = {
    data: ScreenerRowType;
    onSetIsFavourite: (event: { id: string; isFavourite: boolean }) => void;
};

export const IsFavourite = ({ data, onSetIsFavourite }: IsFavouritePropsType) => {
    // region mock
    const listsMock = [
        {
            id: 1,
            title: 'Акции голубых фишек',
            subtitle: '320 инструментов',
            checked: true,
        },
        {
            id: 2,
            title: 'Мои любимые облигации',
            subtitle: '94 инструмента',
        },
        {
            id: 3,
            title: 'Еще какой-нибудь список избранного',
            subtitle: '104 инструмента',
        },
    ];
    // endregion
    const [isVisible, setIsVisible] = useState(false);
    const [lists, setList] = useState(listsMock);

    function handleCellClick() {
        if (!data?.isFavourite) {
            setIsVisible(true);
        } else {
            onSetIsFavourite({ id: data.id, isFavourite: !data.isFavourite });
        }
    }

    function handleCheckListItem(id: number) {
        setList(
            lists.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        checked: !item.checked,
                    };
                }

                return item;
            }),
        );
    }

    function handleHideDialog() {
        onSetIsFavourite({ id: data.id, isFavourite: !data.isFavourite });
        setIsVisible(false);
    }

    const dialogFooterContent = (
        <div className={styles.dialogFooter}>
            <Button className={styles.secondaryButton}>Создать новый список</Button>
            <Button onClick={handleHideDialog}>Сохранить</Button>
        </div>
    );

    return (
        <>
            {data?.isFavourite
? (
                <StarFilledIcon className={styles.isFavouriteIcon} onClick={handleCellClick} />
            )
: (
                <StarIcon className={styles.isFavouriteIcon} onClick={handleCellClick} />
            )}
            <Dialog
                header="Добавить в избранное"
                footer={dialogFooterContent}
                visible={isVisible}
                style={{ width: '564px', height: '619px' }}
                className={styles.dialog}
                onHide={() => setIsVisible(false)}
            >
                <Search />
                <div className={styles.listWrapper}>
                    {lists.map((list) => (
                        <div
                            key={list.id}
                            className={classNames(
                                'flex',
                                'align-items-center',
                                'justify-content-between',
                                styles.list,
                                list.checked ? styles.listChecked : '',
                            )}
                            onClick={() => handleCheckListItem(list.id)}
                        >
                            <div>
                                <div className={styles.title}>{list.title}</div>
                                <div className={styles.subtitle}>{list.subtitle}</div>
                            </div>
                            {list.checked ? <Checkbox checked={!!list.checked} className={styles.checkbox} /> : null}
                        </div>
                    ))}
                </div>
            </Dialog>
        </>
    );
};
