import { subscribeById } from '@modules/Algopack/AlgopackPage/services/algopack.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

const subscribeThunk = createAsyncThunk('algopack/subscribe', async (_, { rejectWithValue }) => {
    try {
        const { data } = await subscribeById();

        return data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export { subscribeThunk };
