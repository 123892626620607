import React from 'react';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { ModalWindow } from '@libs/components';

import styles from './style.module.scss';

type Props = {
    isVisible: boolean;
    onClick: () => void;
    closeModal: () => void;
};

export const DeleteConfirmModal = ({ isVisible, onClick, closeModal }: Props) => {
    return (
        <ModalWindow isVisible={isVisible} closeModal={closeModal}>
            <div className={classNames(styles.confirmContainer)}>
                <h2>Удалить из корзины</h2>
                <p>Вы уверены, что хотите удалить товар из корзины?</p>
                <div className={'flex'}>
                    <Button onClick={onClick} label={'Удалить'} />
                    <Button onClick={closeModal} label={'Отменить'} />
                </div>
            </div>
        </ModalWindow>
    );
};
