import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    PriceCenterBond,
    PriceCenterChart,
    PriceCenterChartPoint,
    PriceCenterFilter,
    PriceCenterStateChartsData,
    PriceCenterValuation,
} from '@store/store/thunk/pricecenter/Models';
import { differenceInDays, parse } from 'date-fns';
import { isEmpty } from 'lodash';
import { getMarketBonds, getMarketPoints, getPricePoints, getProfitCurves, getProfitPoints } from '@libs/services';
import {
    GetMarketBond,
    GetMarketBondsRespBody,
    GetMarketPointsRespBody,
    GetPricePointsRespBody,
    GetProfitPointsRespBody,
    ResponseInfo,
} from '@libs/types';
import { ISSUER_SECTOR_DICT, LISTING_LEVEL_DICT, SECTOR_DICT } from '@store/store/thunk/pricecenter/consts';

const DEFAULT_PRICE_PROFIT_INDEXES = ['RGBI', 'RUCBICP', 'RUMBICP'];

const getAllChartsThunk = createAsyncThunk(
    'priceCenter/getAllChartsThunk',
    async (filter: PriceCenterFilter, { rejectWithValue }) => {
        try {
            const profitCurveResponse = await getProfitCurves(
                //TODO: УБРАТЬ ПОСЛЕ ДЕМО
                '2024-05-08',
            );
            const marketPointsResponse = await getMarketPoints({
                //tradeDate: filter.tradeDate ?? '',
                tradeDate: '2024-05-08',
                methodNumber: Number(filter.calcMethod?.code ?? '0'),
                minYield: filter.minYield ?? 0,
                maxYield: filter.maxYield ?? 10000,
            });
            const bondsResponse = await getMarketBonds({
                // trade_date: filter.tradeDate,
                trade_date: '2024-05-08',
                status: (filter.status ?? []).map((status) => status.name),
                listing_level:
                    filter.listingLevel && filter.listingLevel.code !== LISTING_LEVEL_DICT[0].code
                        ? Number(filter.listingLevel?.code)
                        : undefined,
                sector: filter.sector && filter.sector.code !== SECTOR_DICT[0].code ? filter.sector.name : undefined,
                issuer_sector:
                    filter.issuerSector && filter.issuerSector.code !== ISSUER_SECTOR_DICT[0].code
                        ? filter.issuerSector.name
                        : undefined,
            });
            const pricesResponse = await getPricePoints({
                isinList: DEFAULT_PRICE_PROFIT_INDEXES,
            });
            const profitResponse = await getProfitPoints({
                isinList: DEFAULT_PRICE_PROFIT_INDEXES,
            });
            return {
                marketCharts: mapMarketCharts(profitCurveResponse.data, marketPointsResponse.data, bondsResponse.data),
                marketSelectedPoints: [],

                priceCharts: mapPricesCharts(pricesResponse.data),
                priceSelectedPoints: [],

                profitCharts: mapProfitCharts(profitResponse.data),
                profitSelectedPoints: [],
            } as PriceCenterStateChartsData;
        } catch (e) {
            console.error('e', e);
            return rejectWithValue(e);
        }
    },
);

/**
 * Маппинг данных для графиков Карты рынка
 */
const mapMarketCharts = (
    profitCurve: ResponseInfo<PriceCenterBond[]>,
    marketPoints: GetMarketPointsRespBody[],
    bonds: GetMarketBondsRespBody,
): Array<PriceCenterChart<Array<PriceCenterBond>>> => {
    const gov: Array<PriceCenterBond> = [];
    const corp: Array<PriceCenterBond> = [];
    const local: Array<PriceCenterBond> = [];
    for (const bond of bonds?.bonds ?? []) {
        const result = mapMarketPoint(bond, marketPoints ?? []);
        if (result) {
            if (bond.security_type === 'Гос' || bond.security_type === 'ЕвроГос') {
                gov.push(result);
            } else if (bond.security_type === 'Корп' || bond.security_type === 'ЕвроКорп') {
                corp.push(result);
            } else if (bond.security_type === 'Муни') {
                local.push(result);
            }
        }
    }
    return [
        {
            chartCode: 'PROFIT_CURVE',
            chartName: 'Кривая бескупонной доходности',
            visible: true,
            data: profitCurve.data,
        },
        {
            chartCode: 'GOV_BONDS_CHART',
            chartName: 'Гос. облигации',
            visible: true,
            data: gov,
        },
        {
            chartCode: 'CORP_BONDS_CHART',
            chartName: 'Корп. облигации',
            visible: true,
            data: corp,
        },
        {
            chartCode: 'LOCAL_BONDS_CHART',
            chartName: 'Корп. облигации',
            visible: true,
            data: local,
        },
    ];
};

/**
 * Сопоставление и маппинг точки из integrationservice/instrument-points и
 * instrument/bonds_params для Карты рынка
 */
const mapMarketPoint = (bond: GetMarketBond, points: Array<GetMarketPointsRespBody>): PriceCenterBond | null => {
    if (bond.mat_date && !isEmpty(bond.mat_date)) {
        const point = points.find((point) => point.isin === bond.isin);
        if (point) {
            const valDate = +(
                differenceInDays(parse(bond.mat_date, 'yyyy-MM-dd', new Date()), new Date()) / 365
            ).toFixed(2);
            return {
                code: point.isin,
                name: point.name,
                securityType: point.securityType,
                valDate: valDate,
                value: point.value,
            };
        }
    }
    return null;
};

/**
 * Маппинг данных для графиков Цена
 */
export const mapPricesCharts = (
    pricesData: GetPricePointsRespBody,
    point?: PriceCenterChartPoint<PriceCenterBond>,
): Array<PriceCenterChart<Array<PriceCenterValuation>>> => {
    const result: Array<PriceCenterChart<Array<PriceCenterValuation>>> = [];
    for (const [key, value] of Object.entries(pricesData)) {
        let chartName;
        switch (key) {
            case 'RGBI':
                chartName = 'Индекс гос. облигаций(RGBI)';
                break;
            case 'RUCBICP':
                chartName = 'Индекс корп. облигаций(RUCBICP)';
                break;
            case 'RUMBICP':
                chartName = 'Индекс муниципальных облигаций(RUMBICP)';
                break;
            default:
                chartName = point?.rawData.name ?? 'unknown';
        }
        const data = (value ?? []).map((point) => {
            return {
                code: point.isin,
                date: point.valDate,
                value: point.rate,
            };
        });
        result.push({
            chartCode: key,
            chartName: chartName,
            visible: true,
            data: data,
        });
    }
    return result;
};

/**
 * Маппинг данных для графиков Доходность
 */
export const mapProfitCharts = (
    profitData: GetProfitPointsRespBody,
    point?: PriceCenterChartPoint<PriceCenterBond>,
): Array<PriceCenterChart<Array<PriceCenterValuation>>> => {
    const result: Array<PriceCenterChart<Array<PriceCenterValuation>>> = [];
    for (const [key, value] of Object.entries(profitData)) {
        let chartName;
        switch (key) {
            case 'RGBI':
                chartName = 'Индекс гос. облигаций(RGBI)';
                break;
            case 'RUCBICP':
                chartName = 'Индекс корп. облигаций(RUCBICP)';
                break;
            case 'RUMBICP':
                chartName = 'Индекс муниципальных облигаций(RUMBICP)';
                break;
            default:
                chartName = point?.rawData.name ?? 'unknown';
        }
        const data = (value ?? []).map((point) => {
            return {
                code: point.isin,
                date: point.tradeDate,
                value: point.value,
            };
        });
        result.push({
            chartCode: key,
            chartName: chartName,
            visible: true,
            data: data,
        });
    }
    return result;
};

export {getAllChartsThunk};
