import classNames from 'classnames';
import { capabilitiesItems } from '@modules/PriceCenter/ProductCapabilities/data';
import { CapabilityItem } from '@modules/PriceCenter/ProductCapabilities/CapabilityItem';

import styles from './styles.module.scss';

export const ProductCapabilities = () => {
    return (
        <div className={classNames(styles.productCapabilitiesWrapper, 'flex')}>
            <h2>Возможности продукта</h2>
            <div className={styles.contentWrapper}>
                {capabilitiesItems.map((item, index) => (
                    <CapabilityItem key={index} {...item} />
                ))}
            </div>
        </div>
    );
};
