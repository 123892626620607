import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { type AppDispatch, type RootState } from '@store/store';
import { getCartThunk } from '@store/store/thunk/cart.thunk';
import { type CartDataProduct } from '@libs/types';

import { Payment, Basket, ProductItem } from './components';
import styles from './styles.module.scss';
import { Tabs } from './components/Tabs';

export const ProductsList = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [tab, setTab] = useState<number>(0);
    const cart = useSelector((state: RootState) => state.cart.cart);

    const handleChangeTab = useCallback((index: number) => {
        setTab(index);
    }, []);

    useEffect(() => {
        dispatch(getCartThunk());
    }, []);

    return (
        <section className={styles.cartProductsListSection}>
            <Tabs activeIndex={tab} changeTab={handleChangeTab} />
            <div className={classNames(styles.productsContainer, 'flex')}>
                <div className={classNames(styles.productsList, 'w-full')}>
                    {cart &&
                        cart.items.map((item) => {
                            const product: CartDataProduct = cart.products.find(
                                (product) => product.id === item.productId,
                            )!;
                            const result = {
                                ...item,
                                name: product.name,
                                landingPage: product.landingPage,
                                image: product.image,
                            };

                            return <ProductItem key={item.id} product={result} />;
                        })}
                </div>
                <div>
                    <Basket />
                    <Payment />
                </div>
            </div>
        </section>
    );
};
