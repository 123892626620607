import classNames from 'classnames';
import { useResize } from '@libs/utils';

import styles from './styles.module.scss';

type Props = {
    title: string;
    description: string;
    image: string;
    imagePosition: 'left' | 'right';
};

export const FunctionalItem = ({ title, description, image, imagePosition }: Props) => {
    const { isScreenXl, isScreenLg } = useResize();

    return (
        <div
            className={classNames(
                styles.functionalItemWrapper,
                'flex',
                isScreenXl || isScreenLg ? imagePosition === 'left' && 'flex-row-reverse' : 'flex-column-reverse',
            )}
        >
            <div className={styles.imageWrapper} style={{ backgroundImage: `url(${image})` }} />
            <div className={classNames('flex', 'flex-column', 'justify-content-center', styles.textWrapper)}>
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
        </div>
    );
};
