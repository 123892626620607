import { Chip } from 'primereact/chip';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { ReactComponent as RemoveChipIcon } from '@shared/images/propack/svg/remove_chip_icon.svg';
import { MAX_PLOTS_NUMBER, firstDateWithData, scatterOptions } from '@modules/ProPack/shared/utils/dataConfig';
import { Calendar } from 'primereact/calendar';
import { type Dispatch, type SetStateAction, useMemo } from 'react';
import { type Nullable } from 'primereact/ts-helpers';
import { type MultiSelectChangeEvent, MultiSelect } from 'primereact/multiselect';
import { useResize } from '@libs/utils';
import { format } from 'date-fns';
import { ReactComponent as AddIcon } from '@shared/images/algopack/plusIcon.svg';
import { MultiSelectCheckedIcon } from '@modules/ProPack/shared/components/MultiSelectCheckedIcon';
import { Dropdown } from '@modules/ProPack/shared/components/Dropdown';

import { type Market, type CurveGroup } from '../../types';
import { ReactComponent as InfoIcon } from '../../../../shared/images/svg/InfoIcon.svg';
import styles from './styles.module.scss';

type ChipType = {
    date?: string;
    color: string;
    rate?: string;
};

type ScatterControlPanelProps = {
    dateChips: Array<Omit<ChipType, 'rate'>>;
    rateChips: Array<Omit<ChipType, 'date'>>;
    scatterType: string;
    selectedOption: string;
    handleScatterChange: (item: string) => void;
    removeTrace: (chip: string | number) => void;
    selectedMarket: Market;
    selectedCurveGroup: CurveGroup;
    handleCurveGroupChange: (curveGroupName: string) => void;
    handleDatePicked: (date: Nullable<Date>) => void;
    rateOptions: any[];
    handleRatePicked: (rate: Nullable<number>) => void;
    spfiDate: Nullable<Date>;
    spfiRate: string | undefined;
    handleSpfiChartChange: (curves: string[]) => Promise<void>;
    selectedSpfiCurves: string[];
    selectedSpfiRates: string[];
};

export const ScatterControlPanel = ({
    dateChips,
    rateChips,
    scatterType,
    selectedOption,
    handleScatterChange,
    removeTrace,
    selectedMarket,
    selectedCurveGroup,
    handleCurveGroupChange,
    handleDatePicked,
    rateOptions,
    handleRatePicked,
    spfiDate,
    spfiRate,
    handleSpfiChartChange,
    selectedSpfiCurves,
    selectedSpfiRates,
}: ScatterControlPanelProps) => {
    const { isScreenSm, isScreenLg, width } = useResize();

    const AddDateButton = () => {
        const isAddButtonDisabled = dateChips.length === MAX_PLOTS_NUMBER;

        return (
            <div className={classNames(styles.addButtonBlock)}>
                <Button className={styles.addChipButton} disabled={isAddButtonDisabled} label="Добавить дату" />
                {!isAddButtonDisabled && (
                    <Calendar
                        className={classNames(styles.calendar)}
                        placeholder="Добавить дату"
                        onChange={(e) => handleDatePicked(e.value)}
                        readOnlyInput
                        locale="ru"
                        minDate={firstDateWithData}
                        maxDate={new Date()}
                        inputClassName={classNames(styles.calendarInput, styles.addChipButton)}
                    />
                )}
            </div>
        );
    };

    const dateChipsWithFormattedDates = useMemo(() => {
        try {
            return dateChips.map(({ date, color }) => ({
                date: format(new Date(date!), 'dd.MM.yyyy'),
                color,
            }));
        } catch (e) {
            return dateChips;
        }
    }, [dateChips]);

    const AddRateButton = () => {
        const isAddButtonDisabled = rateChips.length === MAX_PLOTS_NUMBER;

        return (
            <div className={classNames(styles.addButtonBlock)}>
                <Button className={styles.addChipButton} disabled={isAddButtonDisabled} label="Добавить срок" />
                {!isAddButtonDisabled && (
                    <Dropdown
                        onChange={(e) => handleRatePicked(e.value)}
                        options={rateOptions}
                        className={classNames(styles.rateDropdown)}
                    />
                )}
            </div>
        );
    };

    const renderChipsContainer = (type: 'date' | 'rate') => {
        const isDetached = isScreenSm;
        const title = type === 'date' ? 'Выбранные даты' : selectedMarket.xAxisTitle;
        const AddButton = type === 'date' ? <AddDateButton /> : <AddRateButton />;
        const chips = type === 'date' ? (dateChipsWithFormattedDates as ChipType[]) : (rateChips as ChipType[]);
        const chipsForRemove = type === 'date' ? (dateChips as ChipType[]) : (rateChips as ChipType[]);

        return (
            <>
                <div className={classNames(styles.titleWrapper, 'flex')}>
                    <p>{title}</p>
                    {/* <InfoIcon /> */}
                </div>
                <div className={styles.chipsWrapper}>
                    <div className={styles.chipsContainer}>
                        {chips?.map(({ color, ...chip }, index) => (
                            <Chip
                                className={styles.infoChip}
                                style={{ borderColor: color }}
                                key={chip[type]}
                                removeIcon={
                                    <RemoveChipIcon
                                        className={styles.removeIcon}
                                        onClick={() => {
                                            removeTrace(chipsForRemove[index][type]!);
                                        }}
                                    />
                                }
                                label={chip[type]}
                                removable
                            />
                        ))}
                        {!isDetached && AddButton}
                    </div>
                    {isDetached && AddButton}
                </div>
            </>
        );
    };

    return (
        <div className={classNames(styles.controlsWrapper)}>
            <div className={styles.selectorWrapper}>
                {scatterOptions.map((item) => (
                    <div
                        key={item}
                        onClick={() => handleScatterChange(item)}
                        className={classNames(styles.labelSelector, selectedOption === item && styles.activeSelector)}
                    >
                        {item}
                    </div>
                ))}
            </div>
            {selectedMarket.value === 'spfi' ? (
                <div className={classNames(styles.dropdownsBlockWrapper)}>
                    <div className={classNames(styles.dropdownWrapper)}>
                        <div className={classNames(styles.titleWrapper, 'flex')}>
                            <p>Тип кривой СПФИ </p>
                            {/* <InfoIcon /> */}
                        </div>

                        <div className={classNames('flex justify-content-start', styles.smallDropdown)}>
                            <Dropdown
                                value={selectedCurveGroup}
                                onChange={(e) => handleCurveGroupChange(e.value)}
                                options={selectedMarket.curveGroups}
                                optionValue="value"
                                optionLabel="label"
                                placeholder={selectedCurveGroup.label}
                            />
                        </div>
                    </div>
                    <div className={classNames(styles.dropdownWrapper)}>
                        <div className={classNames(styles.titleWrapper, 'flex')}>
                            <p>Конкретная кривая </p>
                            {/* <InfoIcon /> */}
                        </div>

                        <div className={classNames('flex justify-content-start', styles.smallDropdown)}>
                            <MultiSelect
                                value={selectedOption === scatterOptions[0] ? selectedSpfiCurves : selectedSpfiRates}
                                options={selectedCurveGroup.curves}
                                onChange={(e: MultiSelectChangeEvent) => {
                                    handleSpfiChartChange(e.value);
                                }}
                                className={classNames(styles.rateMultiSelectDropdown)}
                                focusOnHover={false}
                                selectionLimit={5}
                                maxSelectedLabels={1}
                                showSelectAll={false}
                                placeholder="Выберите кривую"
                                selectedItemsLabel={`Выбрано ${selectedOption === scatterOptions[0] ? selectedSpfiCurves.length : selectedSpfiRates.length} кривых`}
                                itemTemplate={({ value, label }) => {
                                    const isYieldCurvesSelected = selectedOption === scatterOptions[0];
                                    const activeScatterOption = isYieldCurvesSelected
                                        ? selectedSpfiCurves
                                        : selectedSpfiRates;
                                    const isActive = activeScatterOption.includes(value);
                                    const chip = isYieldCurvesSelected
                                        ? dateChips.find((item) => item.date === value)
                                        : rateChips.find((item) => item.rate === value);

                                    const color = isActive && chip?.color ? chip?.color : 'none';

                                    return (
                                        <>
                                            <span className={isActive ? styles.active : ''}>{label}</span>
                                            <span className={styles.multiselectItemCheckedMark}>
                                                {isActive
? (
                                                    <MultiSelectCheckedIcon color={color} />
                                                )
: (
                                                    <AddIcon className={styles.addIcon} />
                                                )}
                                            </span>
                                        </>
                                    );
                                }}
                                pt={{
                                    header: { style: { display: 'none' } },
                                    panel: { className: styles.multiselectPanel },
                                    item: { className: styles.multiselectItem },
                                }}
                            />
                        </div>
                    </div>
                    {selectedOption === scatterOptions[0]
? (
                        <div className={classNames(styles.dropdownWrapper)}>
                            <div className={classNames(styles.titleWrapper)}>
                                <p>За дату</p>
                            </div>
                            <div className={classNames(styles.calendarWrapper)}>
                                <Calendar
                                    value={spfiDate}
                                    onChange={(e) => handleDatePicked(e.value)}
                                    readOnlyInput
                                    dateFormat="dd.mm.yy"
                                    locale="ru"
                                    showIcon
                                    minDate={firstDateWithData}
                                    maxDate={new Date()}
                                />
                            </div>
                        </div>
                    )
: (
                        <div className={classNames(styles.dropdownWrapper)}>
                            <div className={classNames(styles.titleWrapper)}>
                                <p>За срок</p>
                            </div>
                            <Dropdown
                                value={spfiRate}
                                onChange={(e) => handleRatePicked(e.value)}
                                options={rateOptions}
                                className={classNames(styles.rateDropdown)}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    {scatterType === scatterOptions[0] ? renderChipsContainer('date') : renderChipsContainer('rate')}
                </div>
            )}
        </div>
    );
};
