import { Button } from 'primereact/button';
import { ReactComponent as StarIcon } from '@shared/images/svg/star.svg';
import { useModal } from '@libs/utils';
import { InfoModal } from '@modules/Investorpro/shared/components/InfoModal';

import styles from './styles.module.scss';

export const Footer = () => {
    const { closeModal, openModal, isOpen } = useModal();

    return (
        <section className={styles.footerWrapper}>
            <h2>Для высоких доходностей нужны высокие технологии</h2>
            <Button
                className={'flex align-items-end'}
                icon={<StarIcon />}
                label={'Получить доступ'}
                onClick={openModal}
            />
            <InfoModal isOpen={isOpen} closeModal={closeModal} />
        </section>
    );
};
