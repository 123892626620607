import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { ReactComponent as ProPackLogo } from '../../images/svg/ProPackLogo.svg';
import { ReactComponent as LockIcon } from '../../images/svg/LockIcon.svg';
import { ReactComponent as DropdownIcon } from '../../images/svg/DropdownIcon.svg';
import styles from './styles.module.scss';

const navData = [
    { title: 'Волатильность', link: '', isDisabled: true },
    { title: 'Процентные ставки', link: '', isDisabled: false, hasChildren: true },
    { title: 'Маркировка', link: '', isDisabled: true },
    { title: 'Ставки риска', link: '', isDisabled: true },
    { title: 'Дубликаты ИИС', link: '', isDisabled: true },
];

export const Header = () => {
    const navigate = useNavigate();

    const redirectToPropackLanding = () => navigate('/products/propack');

    return (
        <section className={styles.headerWrapper}>
            <div className={styles.headerLinksBlock}>
                <Link to={''} onClick={redirectToPropackLanding}>
                    <ProPackLogo className={styles.proPackLogo} />
                </Link>
                <nav>
                    <ul className={styles.navigationList}>
                        {navData.map((item) => (
                            <li key={item.title} className={styles.navigationItem}>
                                <Link
                                    to={item.link}
                                    className={classNames(
                                        styles.navigationLink,
                                        item.isDisabled ? styles.isDisabledLink : '',
                                    )}
                                >
                                    {item.isDisabled ? <LockIcon /> : null}
                                    {item.title}
                                    {item.hasChildren ? <DropdownIcon /> : null}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </section>
    );
};
