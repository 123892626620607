import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';

import { ReactComponent as SearchIcon } from '../../images/svg/Search.svg';
import styles from './styles.module.scss';

export const Search = () => {
    return (
        <div className={classNames(styles.searchWrapper, 'p-input-icon-right')}>
            <InputText placeholder="Поиск" className={styles.searchInput} />
            <SearchIcon />
        </div>
    );
};
