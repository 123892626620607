export const fields = [
    {
        title: 'ФИО',
        code: 'fullName',
    },
    {
        title: 'Дата рождения',
        code: 'birthday',
    },
    {
        title: 'Серия и номер паспорта',
        code: 'passportNumber',
    },
    {
        title: 'Код подразделения',
        code: 'issueAuthorityCode',
    },
    {
        title: 'Кем выдан',
        code: 'issueAuthorityName',
    },
    {
        title: 'Дата выдачи',
        code: 'issueDate',
    },
    {
        title: 'Место регистрации',
        code: 'registrationAddress',
    },
];
