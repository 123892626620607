import { type ChartOptions, type ChartData } from 'chart.js';
import { Chart } from 'primereact/chart';
import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';

import styles from './styles.module.scss';
import { useChartTooltip } from '../../../../shared/utils/tooltip.hook.util';
import { type Market } from '../../types';

type CurvesChartWithTooltipsProps = {
    coordsObj: Partial<ChartData<'line', Array<{ x: number | string; y: string }>>>;
    market: Market;
};

type ChartAxisTitlesPoses = {
    x: {
        x: number;
        y: number;
    };
    y: {
        x: number;
        y: number;
    };
};

export const InterestRateChartWithTooltips = ({ coordsObj, market }: CurvesChartWithTooltipsProps) => {
    const chartRef = useRef<Chart>(null);
    const documentStyle = getComputedStyle(document.documentElement);
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    const printXAxisAnnotationLine = (context: any) => {
        const { tooltip } = context;
        const { chart } = context;
        const { ctx } = chart;

        if (!ctx) return;

        type Point = { x: number; y: number; color: string };

        const points: Point[] = tooltip.dataPoints.map((point: any) => ({
            x: point.element.x,
            y: point.element.y,
            color: point.element.options.backgroundColor,
        }));

        let highestIndex = 0;

        for (let i = 0; i < points.length; i++) {
            if (points[i].y < points[highestIndex].y) {
                highestIndex = i;
            }
        }

        ctx.lineWidth = 1;
        ctx.strokeStyle = '#2380EB';
        const { x } = points[highestIndex];
        const { y } = points[highestIndex];

        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([6, 6]);

        ctx.moveTo(x, chart.chartArea.top);
        ctx.lineTo(x, chart.chartArea.bottom);
        ctx.stroke();

        ctx.setLineDash([]);
        const pointRadius = 4;

        points.forEach((point) => {
            ctx.beginPath();
            const { x, y, color } = point;
            const strokeWidth = 4;
            ctx.lineWidth = strokeWidth;
            ctx.arc(x, y, pointRadius, 0, 2 * Math.PI); // Рисуем круг
            ctx.strokeStyle = 'white'; // Цвет рамки
            ctx.stroke();
            ctx.fillStyle = color;
            ctx.fill();
        });

        ctx.restore();
    };

    const mapTooltipPoints = (point: any) => {
        return {
            label: point.dataset.label,
            xValue: point.label,
            yValue: point.formattedValue,
            color: point.dataset.borderColor,
        };
    };

    const sortTooltipData = (a: any, b: any) => parseFloat(b.yValue.replace(',', '.')) - parseFloat(a.yValue.replace(',', '.'));

    const setTooltipPosition = (context: any, el: HTMLDivElement) => {
        const { chartArea } = context.chart;
        const isRightAligned = context.tooltip.caretX > chartArea.left + chartArea.width / 2;
        const offset = isRightAligned ? -14 : 10;
        const className = styles.alignRight;
        const method = isRightAligned ? 'add' : 'remove';

        el.classList[method](className);
        el.style.left = context.tooltip.caretX + offset + 'px';
        el.style.top = context.tooltip.caretY + 'px';
    };

    const [tooltipRef, tooltipData, tooltipCallback] = useChartTooltip({
        handleToolTipHover: printXAxisAnnotationLine,
        setTooltipPosition,
        mapTooltipData: mapTooltipPoints,
        sortTooltipData,
        debounceTime: 0,
    });

    const isBondsType = market.value === 'bonds';
    const isSpfiType = market.value === 'spfi';

    let min: number | undefined = isBondsType
        ? Math.round(Number(coordsObj?.datasets?.[0]?.data?.at(0)?.x))
        : undefined;
    let max: number | undefined = isBondsType
        ? Math.round(Number(coordsObj?.datasets?.[0]?.data?.at(-1)?.x))
        : undefined;
    min = Number.isNaN(min) ? undefined : min;
    max = Number.isNaN(max) ? undefined : max;

    const options: ChartOptions = useMemo(() => {
        return {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            elements: {
                point: {
                    radius: 0,
                    tension: 0.4,
                    hoverRadius: 5,
                    hoverBorderWidth: 2,
                    hoverBorderColor: 'white',
                },
                line: {
                    borderWidth: 1.5,
                },
            },
            animation: false,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            plugins: {
                legend: {
                    display: isSpfiType && (coordsObj?.datasets?.length ?? 0) > 1,
                    position: 'bottom',
                    align: 'start',
                    fullSize: true,
                    labels: {
                        boxWidth: 20,
                        boxHeight: 0,
                        color: '#8A96A6',
                        padding: 32,
                        font: {
                            family: 'Favorit Pro',
                            size: 14,
                            weight: 'normal',
                            lineHeight: 16,
                            textAlign: 'left',
                        },
                    },
                },
                tooltip: {
                    enabled: false,
                    external: tooltipCallback,
                },
            },
            scales: {
                x: {
                    ...(isBondsType
                        ? {
                              beginAtZero: true,
                              suggestedMin: 0,
                          }
                        : {}),
                    ticks: {
                        padding: 7,
                        color: textColorSecondary,
                        stepSize: isBondsType ? 2 : undefined,
                        display: !!coordsObj?.datasets?.length,
                    },
                    grid: {
                        offset: false,
                        color: surfaceBorder,
                        tickLength: 0,
                        zeroLineWidth: 2,
                    },
                    border: {
                        color: '#A5B1C0',
                    },
                    min,
                    max,
                },
                y: {
                    border: {
                        dash: [2, 2],
                        color: surfaceBorder,
                    },
                    grace: 1,
                    type: isBondsType ? 'linear' : undefined,
                    grid: {
                        color: surfaceBorder,
                        offset: false,
                        tickLength: 0,
                        zeroLineWidth: 2,
                    },
                    position: 'right',
                    ticks: {
                        major: {
                            enabled: false,
                        },
                        padding: 15,
                        font: {
                            size: 11,
                            weight: 'normal',
                        },
                        maxTicksLimit: 7,
                        callback: (value: number | string) => {
                            return Number(value).toFixed(1);
                        },
                    },
                },
            },
        };
    }, [max, min, market.value, coordsObj.datasets?.length]);

    const [xAxisTitlePos, setXAxisTitlePos] = useState<ChartAxisTitlesPoses>({
        x: {
            x: 0,
            y: 0,
        },
        y: {
            x: 0,
            y: 0,
        },
    });

    const chartArea = chartRef.current?.getChart()?.chartArea;

    useEffect(() => {
        if (chartArea) {
            setTimeout(() => {
                setXAxisTitlePos({
                    x: {
                        x: chartArea.left + 5,
                        y: chartArea.bottom - 5,
                    },
                    y: {
                        x: chartArea.right - 10,
                        y: chartArea.top + 5,
                    },
                });
            });
        }
    }, [
        market.value,
        chartArea?.left,
        chartArea?.bottom,
        chartArea?.right,
        chartArea?.top,
        coordsObj.datasets?.length,
    ]);

    return (
        <div className={classNames(styles.chartContainer)}>
            <h4
                className={classNames(styles.chartAxisTitle, styles.yAxis)}
                style={{ top: xAxisTitlePos.y.y || undefined, left: xAxisTitlePos.y.x || undefined }}
            >
                Доходность
            </h4>
            <h4
                className={classNames(styles.chartAxisTitle, styles.xAxis)}
                style={{
                    top: xAxisTitlePos.x.y || undefined,
                    left: xAxisTitlePos.x.x || undefined,
                    // TODO: remove this when fix chartArea not to be undefined after rerender
                    display: 'none',
                }}
            >
                Дата
            </h4>
            <Chart
type="line" ref={chartRef} data={coordsObj}
options={options} style={{ maxWidth: '100%' }} />
            <div ref={tooltipRef} className={classNames(styles.tooltipWrapper)}>
                {tooltipData.map((value: any, index: number) => (
                    <div
                        key={index}
                        style={{ transform: `translateY(-${1 * index}px)` }}
                        className={classNames(styles.tooltip)}
                    >
                        <div className={classNames(styles.textWrapper)}>
                            <p className={classNames(styles.lineWrapper)}>
                                <span className={classNames(styles.title)}>
                                    {isSpfiType ? 'Кривая' : market.xAxisTitle}
                                </span>
                                <span className={classNames(styles.normalText)}>{value.label}</span>
                            </p>
                            <p className={classNames(styles.lineWrapper)}>
                                <span className={classNames(styles.title)}>{'Дата'}</span>
                                <span className={classNames(styles.normalText)}>{value.xValue}</span>
                            </p>
                            <p className={classNames(styles.lineWrapper)}>
                                <span className={classNames(styles.title)}>Доходность</span>
                                <span className={classNames(styles.boldText)} style={{ color: value.color }}>
                                    {value.yValue}
                                </span>
                            </p>
                        </div>
                        <div className={classNames(styles.pinWrapper)}>{/* <PinIcon /> */}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
