import { FreeDataIcon, FreeDataMark, IndividualIcon, IndividualMark, LegalEntityIcon, LegalEntityMark } from "@shared";

type MarkItem = {
  description: string;
  mark: string;
  icon: string;
  color: string;
};

export const mark: Record<string, MarkItem> = {
  green: {
    description: "Есть бесплатные данные",
    mark: FreeDataMark,
    icon: FreeDataIcon,
    color: "#3A9E4A",
  },
  blue: {
    description: "Для физических лиц",
    mark: IndividualMark,
    icon: IndividualIcon,
    color: "#388AF7",
  },
  red: {
    description: "Для юридических лиц",
    mark: LegalEntityMark,
    icon: LegalEntityIcon,
    color: "#FF0508",
  },
};
