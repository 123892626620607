import type Plot from 'react-plotly.js';
import { type RefObject, useEffect, useRef } from 'react';
import { ReactComponent as PanIcon } from '@modules/ProPack/shared/images/svg/PanIcon.svg';
import { ReactComponent as ResetIcon } from '@modules/ProPack/shared/images/svg/ResetCameraIcon.svg';
import { ReactComponent as ZoomIcon } from '@modules/ProPack/shared/images/svg/ZoomIcon.svg';
import { ReactComponent as CameraIcon } from '@modules/ProPack/shared/images/svg/CameraIcon.svg';
import { ReactComponent as TurntableRotationIcon } from '@modules/ProPack/shared/images/svg/TurntableRotationIcon.svg';
import { ReactComponent as OrbitalRotationIcon } from '@modules/ProPack/shared/images/svg/OrbitalRotationIcon.svg';

import styles from './style.module.scss';

type Props = {
    chartRef: RefObject<Plot>;
};

export const ModeBar = ({ chartRef }: Props) => {
    const buttons = useRef<HTMLDivElement[]>([]);

    useEffect(() => {
        if (chartRef.current) {
            // @ts-expect-error Plot type error
            const modebar = chartRef.current?.el.querySelector('.modebar-group');

            if (modebar) {
                buttons.current = modebar.children;
            }
        }
    }, [chartRef.current]);

    const handleDownloadPlot = () => {
        buttons.current?.[0]?.click();
    };

    const handleZoom = () => {
        buttons.current?.[1]?.click();
    };

    const handlePan = () => {
        buttons.current?.[2]?.click();
    };

    const handleOrbitalRotation = () => {
        buttons.current?.[3]?.click();
    };

    const handleTurntableRotation = () => {
        buttons.current?.[4]?.click();
    };

    const handleReset = () => {
        buttons.current?.[5]?.click();
    };

    return (
        <div className={styles.modebar}>
            <CameraIcon onClick={handleDownloadPlot} />
            <ZoomIcon onClick={handleZoom} />
            <PanIcon onClick={handlePan} />
            <OrbitalRotationIcon onClick={handleOrbitalRotation} />
            <TurntableRotationIcon onClick={handleTurntableRotation} />
            <ResetIcon onClick={handleReset} />
        </div>
    );
};
