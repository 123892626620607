type Props = {
    color: string;
};

export const MultiSelectCheckedIcon = ({ color }: Props) => (
    <svg
width="24" height="24" viewBox="0 0 24 24"
fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
d="M6 13L10 17L18.5 8.5" stroke={color} strokeWidth="2"
strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
