import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '@shared/images/svg/ArrowDownGrey.svg';

import styles from './styles.module.scss';

type DropDownEvent = {
    value: any;
    label: string;
};

type Props = {
    value?: unknown | undefined;
    onChange: (e: DropDownEvent) => void;
    options: unknown[];
    optionLabel?: string;
    optionValue?: string;
    className?: string;
    placeholder?: string;
};

// eslint-disable-next-line func-style, prefer-arrow/prefer-arrow-functions
export const Dropdown = ({ value, onChange, options, optionLabel, optionValue, className, placeholder }: Props) => {
    const [selfCurrentValue, setSelfCurrentValue] = useState<unknown>();
    const isObjectType = options.length > 0 ? typeof options[0] === 'object' : false;
    const [isOpen, setIsOpen] = useState(false);
    const [pos, setPos] = useState<'down' | 'up' | undefined>();
    const [isVisible, setIsVisible] = useState(false);
    const timer = useRef<any>(-1);
    const panelRef = useRef<HTMLDivElement>(null);

    const getItemValue = (item: unknown) => {
        if (isObjectType) {
            return (item as any)[optionValue ?? 'value'];
        }

        return item;
    };

    const getItemLabel = (item: unknown) => {
        if (isObjectType) {
            return (item as any)[optionLabel ?? 'label'];
        }

        return item;
    };

    const currentOption = value ?? selfCurrentValue;
    const currentOptionLabel = currentOption ? getItemLabel(currentOption) : placeholder;

    const handleFocus = () => clearTimeout(timer.current);
    const handleBlur = () => {
        timer.current = setTimeout(() => setIsOpen(false), 100);
    };

    const handleChange = (item: unknown, value: string, label: string) => () => {
        onChange({
            value,
            label,
        });
        setIsOpen(false);

        if (!value) {
            setSelfCurrentValue(item);
        }
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setTimeout(() => setIsOpen(false), 300);
        setIsVisible(false);
    };

    const toggleOpen = () => (isOpen ? handleClose() : handleOpen());

    useEffect(() => {
        if (isOpen && panelRef.current) {
            const { top, height } = panelRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const isNotEnoughDownSpace = top + height + 20 > windowHeight;
            setPos(isNotEnoughDownSpace ? 'up' : 'down');
            setIsVisible(true);
        } else {
            setPos(undefined);
        }
    }, [isOpen]);

    return (
        <div
            className={classNames(styles.dropdownWrapper, className)}
            tabIndex={0}
            onFocus={handleFocus}
            onBlur={handleBlur}
        >
            <div className={styles.titleWrapper} onClick={toggleOpen}>
                <div className={styles.title}>{currentOptionLabel}</div>
                <div className={styles.arrowWrapper}>
                    <ArrowDownIcon />
                </div>
            </div>
            {isOpen && (
                <div
                    ref={panelRef}
                    className={classNames(
                        styles.panelWrapper,
                        isVisible && styles.visible,
                        pos && styles[pos],
                        options.length > 5 ? styles.fixedSize : styles.relativeSize,
                    )}
                >
                    <div className={styles.listWrapper}>
                        {options.map((item, index) => {
                            const itemValue = getItemValue(item);
                            const itemLabel = getItemLabel(item);
                            const isActive = item === currentOption;

                            return (
                                <div
                                    tabIndex={0}
                                    key={itemValue}
                                    className={classNames(styles.listItem, isActive && styles.active)}
                                    onClick={handleChange(item, itemValue, itemLabel)}
                                >
                                    {itemLabel}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};
