import styles from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/styles.module.scss';
import classNames from 'classnames';
import { InputSwitch } from 'primereact/inputswitch';
import React from 'react';
import { SelectedPointsModal } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/SelectedPointsModal';

type PriceProfitHeaderProps = {
    profitSelected: boolean;
    onChange: () => void;
};

export const PriceProfitHeader = (props: PriceProfitHeaderProps) => {
    const { profitSelected, onChange } = props;

    return (
        <div className="px-3 w-full flex justify-content-between align-items-center">
            <div className={styles.mapHeaderTitle}>
                <h2
                    className={classNames({
                        [styles['disabled']]: profitSelected,
                    })}
                    onClick={onChange}
                >
                    Цена
                </h2>
                <InputSwitch
                    checked={profitSelected}
                    onChange={onChange}
                    pt={{
                        slider: () => ({
                            className: styles.switch,
                        }),
                    }}
                />
                <h2
                    className={classNames({
                        [styles['disabled']]: !profitSelected,
                    })}
                    onClick={onChange}
                >
                    Доходность
                </h2>
            </div>
            <SelectedPointsModal selectedMarkers={[]} />
        </div>
    );
};
