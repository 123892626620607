import { useState } from 'react';
import classNames from 'classnames';
import { SliderButtons } from '@libs/components';

import styles from './styles.module.scss';

type Props = {
    images: string[];
};

export const Carousel = ({ images }: Props) => {
    const [activeSlide, setActiveSlide] = useState(0);

    return (
        <div className={styles.carouselWrapper}>
            <div
                className={classNames('flex', styles.imagesWrapper)}
                style={{
                    transform: `translateX(-${activeSlide * 100}%)`,
                }}
            >
                {images.map((image, index) => (
                    <img key={index} src={image} alt={`Slide ${index}`} />
                ))}
            </div>
            <div className={styles.sliderWrapper}>
                <SliderButtons
                    className={styles.sliderButton}
                    activeSlide={activeSlide}
                    setActiveSlide={setActiveSlide}
                    totalNumber={images.length}
                />
            </div>
        </div>
    );
};
