import { ScriptableChartContext } from 'chart.js';
import { lineGradientGraphicList } from './constants';
import { CharColor } from './_types';
import { round } from 'lodash';
import { isDate, parse } from 'date-fns';

export const getLinerGradientForLineChart = (
    context: ScriptableChartContext,
    color: CharColor,
): undefined | CanvasGradient => {
    if (!context.chart.chartArea) {
        return;
    }
    const chart = context.chart;

    const {
        ctx,
        chartArea: { top, bottom },
    } = chart;

    const [firstGradientValue, secondGradientValue] = lineGradientGraphicList[color];
    const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);

    gradientBg.addColorStop(1, firstGradientValue);
    gradientBg.addColorStop(0, secondGradientValue);

    return gradientBg;
};

type ChartOptionsConfig = {
    yMax?: number;
    yMin?: number;
    y2Max?: number;
    y2Min?: number;
};

export const getChartOptions = ({ yMax = 2000, y2Max = 2000, yMin = 1000, y2Min = 1000 }: ChartOptionsConfig) => ({
    responsive: true,
    maintainAspectRatio: false,
    parsing: {
        xAxisKey: 'tradeDateTime',
        yAxisKey: 'value',
    },
    elements: {
        point: {
            radius: 0,
        },
        line: {
            borderWidth: 1,
        },
    },
    plugins: {
        legend: false,
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                },

                mode: 'y',
            },
            pan: {
                enabled: true,
                mode: 'xy',
            },
            limits: {
                x: { minRange: 1 },
                y: { minRange: 1 },
            },
        },
    },
    tooltip: {
        enabled: true,
        mode: 'index',
        intersect: true,
        callbacks: {
            label: function (context: any) {
                let label = context.dataset.label || '';

                if (label) {
                    label += ': ';
                }
                if (context.parsed.y !== null) {
                    label += context.parsed.y;
                }
                return label;
            },
        },
    },
    scales: {
        x: {
            grid: {
                drawOnChartArea: false,
                borderColor: 'transparent',
                tickColor: 'transparent',
            },
            ticks: {
                maxRotation: 0,
                minRotation: 0,
                align: 'start',

                color: (c: any) => {
                    //const date = new Date(c.tick.label);
                    const isDate = c?.tick?.label?.split('.').length === 3;
                    return isDate ? '#88919A' : '#B7BDC2'; // возвращает красный для времени и черный для даты
                },
            },
            offset: true,
        },
        y: {
            type: 'linear',
            display: true,
            position: 'left',
            max: yMax,
            min: yMin,

            grid: {
                borderColor: 'transparent',
                drawBorder: false,
                tickLength: 10,
                tickColor: 'transparent',
            },
            border: {
                display: false,
            },
        },
        y2: {
            max: yMax,
            min: yMin,
            display: false,
            type: 'linear',
            position: 'left',
        },
        y3: {
            type: 'linear',
            display: true,
            position: 'right',
            max: y2Max,
            min: y2Min,
            border: {
                display: false,
            },
            grid: {
                display: false,
            },
            ticks: {
                maxTicksLimit: 5,
                stepSize: 5,
                callback: function (value: number) {
                    return `${round(value / 1000, 1)} тыс.`;
                },
            },
        },
    },
});
