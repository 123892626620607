import { Button } from 'primereact/button';
import { ModalWindow } from '@libs/components';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
};

export const InfoModal = ({ isOpen, closeModal }: Props) => {
    return (
        <ModalWindow isVisible={isOpen} closeModal={closeModal}>
            <div className={classNames(styles.infoModalWrapper, 'flex', 'flex-column')}>
                <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                    <h3>продукт в разработке</h3>
                    <p>Этот продукт находится в разработке. Запуск летом 2024.</p>
                    <p>
                        Подпишитесь на{' '}
                        <a
                            href={process.env.REACT_APP_INVESTORPRO_TELEGRAM_LINK}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                        >
                            телеграм канал Datashop
                        </a>
                        , чтобы не пропустить дату запуска.
                    </p>
                </div>
                <div className={classNames(styles.btnWrapper, 'flex')}>
                    <Button
                        label={'Подписаться'}
                        className={styles.blackBtn}
                        onClick={() => window.open(process.env.REACT_APP_INVESTORPRO_TELEGRAM_LINK)}
                    />
                    <Button label={'Закрыть'} onClick={closeModal} className={styles.whiteBtn} />
                </div>
            </div>
        </ModalWindow>
    );
};
