import { LogoBlock } from '@modules/ComplianceToolLanding/MainContent/components/LogoBlock';
import { GainAccess } from '@libs/components';
import { type RefObjectType } from '@libs/types';
import { ComplianceServices } from '@modules/ComplianceToolLanding/MainContent/components/ComplianceServices';

export const MainContent = () => {
    const refs: RefObjectType<HTMLDivElement> = {};

    const setChildRefs = (childRefs: RefObjectType<HTMLDivElement>) => {
        Object.assign(refs, childRefs);
    };

    return (
        <>
            <LogoBlock refs={refs} />
            <ComplianceServices />
            <GainAccess
                setRefs={setChildRefs}
                telephoneNumber={'+7 (495) 363-32-32 доб. 5656'}
                productGuid={process.env.REACT_APP_COMPLIENCETOOL_GUID}
            />
        </>
    );
};
