import report from '../../../../shared/images/png/investorFunctional/Report.png';
import caseImg from '../../../../shared/images/png/investorFunctional/Case.png';
import screener from '../../../../shared/images/png/investorFunctional/Screener.png';

export const functionalList = [
    {
        title: 'Отчет для инвестора',
        description:
            'Всего за несколько минут Вы подготовите для клиента инвестиционный отчет по аудиту портфеля или по инвестиционной идее при помощи встроенного конструктора. Профессиональный и стильный документ легко настроить по содержанию, забрендировать и сразу отправить клиенту или распечатать для личной встречи с ним.',
        image: report,
    },
    {
        title: 'Портфельный функционал',
        description:
            'Моделируйте, тестируйте и сравнивайте портфели – отбирайте лучшие. Анализируйте качество и эффективность инвестиционных идей. Регулярно отслеживайте эффективность инвестиционных решений, которые вы предложили клиентам.',
        image: caseImg,
    },
    {
        title: 'Скринер',
        description:
            'Быстро и эффективно находите актуальные инвестиционные идеи для портфелей клиентов. Анализируйте инструменты по широкому набору параметров.',
        image: screener,
    },
];
