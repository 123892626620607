import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllChartsThunk } from '@store/store/thunk/pricecenter/getAllCharts.thunk';
import { PriceCenterChart, PriceCenterFilter, PriceCenterStateChartsData } from '@store/store/thunk/pricecenter/Models';
import { selectMarketPoint } from '@store/store/thunk/pricecenter/getChartsSelectors.thunk';
import {
    CALC_METHODS_DICT,
    ISSUER_SECTOR_DICT,
    LISTING_LEVEL_DICT,
    SECTOR_DICT,
    STATUS_DICT
} from '@store/store/thunk/pricecenter/consts';

export type IPriceCenterState = {
    charts: IPriceCenterStateCharts;
};

export type IPriceCenterStateCharts = {
    filter: PriceCenterFilter;
    data: PriceCenterStateChartsData;
    isLoading: boolean;
    error: any;
}

export const initialState: IPriceCenterState = {
    charts: {
        filter: {
            tradeDate: '2024-02-08',
            calcMethod: CALC_METHODS_DICT[0],
            status: [STATUS_DICT[2]],
            listingLevel: LISTING_LEVEL_DICT[0],
            sector: SECTOR_DICT[0],
            issuerSector: ISSUER_SECTOR_DICT[0]
        },
        data: {
            marketCharts: [],
            marketSelectedPoints: [],
            priceCharts: [],
            priceSelectedPoints: [],
            profitCharts: [],
            profitSelectedPoints: []
        },
        isLoading: false,
        error: {}
    }
};

export const priceCenterSlice = createSlice({
    name: 'priceCenter',
    initialState,
    reducers: {
        toggleChartVisibility(state, action) {
            const groupCode = action.payload.groupChartCode;
            const chartCode = action.payload.chartCode;
            const toggle = (charts: Array<PriceCenterChart<any>>) => {
                const chart = charts.find(value => value.chartCode === chartCode);
                if (chart) {
                    chart.visible = !chart.visible;
                }
            };
            switch (groupCode) {
                case 'market':
                    toggle(state.charts.data.marketCharts);
                    break;
                case 'price':
                    toggle(state.charts.data.priceCharts);
                    break;
                case 'profit':
                    toggle(state.charts.data.profitCharts);
                    break;
                default:
                    break;
            }
        },
        unSelectMarketPoint(state, action) {
            state.charts.data.marketSelectedPoints = state.charts.data.marketSelectedPoints
                .filter(point => point.code !== action.payload.code);
            state.charts.data.priceSelectedPoints = state.charts.data.priceSelectedPoints
                .filter(point => point.chartCode !== action.payload.code);
            state.charts.data.profitSelectedPoints = state.charts.data.profitSelectedPoints
                .filter(point => point.chartCode !== action.payload.code);
            state.charts.data.priceCharts = state.charts.data.priceCharts
                .filter(chart => chart.chartCode !== action.payload.code);
            state.charts.data.profitCharts = state.charts.data.profitCharts
                .filter(chart => chart.chartCode !== action.payload.code);
        },
        selectPricePoint(state, action) {
            state.charts.data.priceSelectedPoints = [
                ...state.charts.data.priceSelectedPoints,
                action.payload
            ];
        },
        unSelectPricePoint(state, action) {
            state.charts.data.priceSelectedPoints = state.charts.data.priceSelectedPoints
                .filter(point => point.code !== action.payload.code);
        },
        selectProfitPoint(state, action) {
            state.charts.data.profitSelectedPoints = [
                ...state.charts.data.profitSelectedPoints,
                action.payload
            ];
        },
        unSelectProfitPoint(state, action) {
            state.charts.data.profitSelectedPoints = state.charts.data.profitSelectedPoints
                .filter(point => point.code !== action.payload.code);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllChartsThunk.pending, (state, action) => {
                state.charts.isLoading = true;
                state.charts.filter = action.meta.arg;
            })
            .addCase(getAllChartsThunk.fulfilled, (state, action: PayloadAction<any>) => {
                state.charts.isLoading = false;
                state.charts.data = action.payload;
            })
            .addCase(getAllChartsThunk.rejected, (state, action) => {
                state.charts.isLoading = false;
                state.charts.error = action.error;
            })
            .addCase(selectMarketPoint.pending, (state) => {
                state.charts.isLoading = true;
            })
            .addCase(selectMarketPoint.fulfilled, (state, action: PayloadAction<any>) => {
                state.charts.isLoading = false;
                state.charts.data.marketSelectedPoints = [
                    ...state.charts.data.marketSelectedPoints,
                    action.payload.point
                ];
                state.charts.data.priceCharts = [
                    ...state.charts.data.priceCharts,
                    ...action.payload.priceCharts
                ];
                state.charts.data.profitCharts = [
                    ...state.charts.data.priceCharts,
                    ...action.payload.profitCharts
                ];
            });
    }
});

export const priceCenterReducer = priceCenterSlice.reducer;

export const {
    toggleChartVisibility,
    unSelectMarketPoint,
    selectPricePoint,
    unSelectPricePoint,
    selectProfitPoint,
    unSelectProfitPoint
} = priceCenterSlice.actions;
