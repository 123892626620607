import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPersonalInformation, getUserProfileRequest, updateUserProfileRequest } from '@libs/services';
import { type UserProfileUpdateResponseType } from '@libs/types';

const editUserThunk = createAsyncThunk(
    'user/editUser',
    async (user: UserProfileUpdateResponseType, { rejectWithValue }) => {
        try {
            const { data } = await updateUserProfileRequest(user);

            return { data: data.data };
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);

const getUserThunk = createAsyncThunk('user/getUser', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getUserProfileRequest();

        return data.data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

const getPersonalInformationThunk = createAsyncThunk('user/getUserInformationThunk', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getPersonalInformation();
        return data.data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export { editUserThunk, getUserThunk, getPersonalInformationThunk };
