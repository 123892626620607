import contentImg1 from '@shared/images/priceCenter/png/contentImg1.png';
import contentImg2 from '@shared/images/priceCenter/png/contentImg2.png';

export const contentSliderList = [
    {
        text: 'Сравнение выпусков облигаций по широкому перечню параметров',
        heading: 'возможности',
        title: 'Интерактивная карта рынка',
        image: contentImg1,
    },
    {
        text: 'Официальная выписка о стоимости бумаги со стороны НРД',
        heading: 'возможности',
        title: 'Заказ выписки',
        image: contentImg2,
    },
];
