import * as yup from 'yup';

export interface IFormData {
    first_name: string;
    last_name: string;
    company: string;
    user_email: string;
    phone_number: string;
}

const lettersOnlyRegex = /^(?=.{1,128}$)[а-яёА-ЯЁ]+(?:[ ][а-яёА-ЯЁ]+)*$/;
const emailOnlyRegex = /^\S+@\S+\.[a-z]{2,4}$/;
const mobileRegex = /\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}/;

export const schema = yup.object().shape({
    last_name: yup
        .string()
        .required('Поле не заполнено.')
        .min(1, 'Недостаточное количество введенных символов.')
        .max(30, 'Превышено максимальное количество введенных символов.')
        .matches(lettersOnlyRegex, 'Разрешены только буквы русского алфавита.'),
    first_name: yup
        .string()
        .required('Поле не заполнено.')
        .min(1, 'Недостаточное количество введенных символов.')
        .max(30, 'Превышено максимальное количество введенных символов.')
        .matches(lettersOnlyRegex, 'Разрешены только буквы русского алфавита.'),
    company: yup
        .string()
        .required('Поле не заполнено.')
        .max(100, 'Превышено максимальное количество введенных символов.'),
    user_email: yup
        .string()
        .required('Поле не заполнено.')
        .matches(emailOnlyRegex, 'Использованы запрещенные символы при вводе.')
        .min(5, 'Недостаточное количество введенных символов.')
        .max(64, 'Превышено максимальное количество введенных символов.'),
    phone_number: yup
        .string()
        .required('Поле не заполнено.')
        .matches(mobileRegex, 'Введен некорректный номер телефона.'),
});
