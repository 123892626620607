import classNames from 'classnames';
import { DataItem } from '@modules/Algopack/AlgopackPage/components/DataConstitution/DataItem';
import { useResize } from '@libs/utils';

import { DataItems } from './data';
import styles from './styles.module.scss';

export const DataConstitution = () => {
    const { isScreenSm, isScreenLg, isScreenXl } = useResize();

    return (
        <section className={classNames(styles.dataConstitution)}>
            <div className={classNames(styles.titlesWrapper, 'flex', 'justify-content-between')}>
                <h2>Состав данных</h2>
                {!isScreenSm &&
                    (isScreenLg || isScreenXl
? (
                        <div className={classNames('flex', 'justify-content-between')}>
                            <span>Обновление данных</span>
                            <span>Исторические данные</span>
                            <span>Библиотека Python</span>
                        </div>
                    )
: (
                        <div className={classNames('flex')}>
                            <div className={classNames('flex', 'flex-column')}>
                                <span style={{ textAlign: 'right' }}>Обновление данных</span>
                                <span style={{ textAlign: 'right' }}>Исторические данные</span>
                            </div>
                            <span style={{ width: '80px' }}>Библиотека Python</span>
                        </div>
                    ))}
            </div>

            <div className={classNames(styles.dataItemsWrapper, 'flex', 'flex-column')}>
                {DataItems.map((prop, index) => (
                    <DataItem key={prop.title} {...prop} lastElement={index === DataItems.length - 1} />
                ))}
            </div>
        </section>
    );
};
