import classNames from 'classnames';
import { ReactComponent as MarkMarker } from '@shared/images/propack/svg/mark_to_market.svg';
import { ReactComponent as DinamicPercent } from '@shared/images/propack/svg/dinamic_percent.svg';
import { ReactComponent as IncomeLine } from '@shared/images/propack/svg/income_line.svg';

import styles from './styles.module.scss';

export const ProductOpportunity = () => {
    return (
        <section className={classNames(styles.productOpportunityWrapper, 'flex')}>
            <div className={classNames(styles.titleContainer)}>
                <h2>возможности продукта</h2>
            </div>
            <div className={classNames(styles.listWrapper)}>
                <div className={classNames('flex')}>
                    <MarkMarker />
                    <div>
                        <p>Mark-to-Market</p>
                        <span>Определение текущей рыночной стоимости финансовых инструментов</span>
                    </div>
                </div>
                <div className={classNames('flex')}>
                    <DinamicPercent />
                    <div>
                        <p>Динамика процентной ставки</p>
                        <span>
                            Сравнительный графический анализ динамики процентной ставки для различных инструментов на
                            заданные сроки исполнения и выбранный период времени
                        </span>
                    </div>
                </div>
                <div className={classNames('flex')}>
                    <IncomeLine />
                    <div>
                        <p>Построение кривой доходности</p>
                        <span>
                            Сравнительный графический анализ кривых доходности для различных инструментов на заданные
                            даты
                        </span>
                    </div>
                </div>
            </div>
        </section>
    );
};
