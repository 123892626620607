import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
    selectedFilter: string;
    setSelectedFilter: (newVal: string) => void;
    list: string[];
};

export const FilterBlock = ({ selectedFilter, setSelectedFilter, list }: Props) => {
    return (
        <div className={classNames(styles.filterBlockWrapper, 'flex')}>
            {list.map((item) => (
                <div
                    key={item}
                    onClick={() => setSelectedFilter(item)}
                    className={classNames(styles.filterItem, selectedFilter === item && styles.active)}
                >
                    {item}
                </div>
            ))}
        </div>
    );
};
