import { Footer } from '@modules/Investorpro/modules/LandingPage/components/Footer';

import { Header } from './components/Header';
import { DynamicTitle } from './components/DynamicTitle';
import { Advantages } from './components/Advantages';
import { InvestorFunctional } from './components/InvestorFunctional';

export const LandingPage = () => {
    return (
        <>
            <Header />
            <DynamicTitle />
            <Advantages />
            <InvestorFunctional />
            <Footer />
        </>
    );
};
