import React from 'react';

import styles from './styles.module.scss';

export const Code = () => {
    return (
        <>
            <div className={styles.boxBlock}>
                <p className={styles.standartText}>
                    <span className={styles.greenSpan}>import</span> pandas <span className={styles.greenSpan}>as</span>{' '}
                    pd
                </p>
                <p className={styles.standartText}>
                    <span className={styles.greenSpan}>import</span> matplotlib.pyplot{' '}
                    <span className={styles.greenSpan}>as</span> plt
                </p>
                <p className={styles.standartText}>
                    plt.rcParams [<span className={styles.redSpan}>{'\'figure.figsize\''}</span>] = (
                    <span className={styles.greenSpan}>10</span>, <span className={styles.greenSpan}>5</span>)
                </p>
                <p className={styles.standartText}>
                    <span>{'plt.style.use(\''}</span>
                    <span className={styles.redSpan}>ggplot</span>
                    <span>{'\')'}</span>
                </p>
            </div>
            <div className={styles.boxBlock}>
                <p className={styles.standartText}>
                    ticker = <span className={styles.redSpan}>{'\'Si\''}</span>
                </p>
                <p className={styles.standartText}>
                    date1 = <span className={styles.redSpan}>{'\'2024-04-04\''}</span>
                </p>
                <p className={styles.standartText}>
                    date2 = <span className={styles.redSpan}>{'\'2024-04-05\''}</span>
                </p>
            </div>

            <div className={styles.boxBlock}>
                <p className={styles.standartText}># Запрос к API</p>
                <p className={styles.standartText}>
                    url = {'f\'https://iss.moex.com/iss/analyticalproducts/futoi/securities/{'}
                    {'ticker.csv?from={'}
                    {'date1'}
                    {'}&till={'}
                    {'date2'}
                    {'}&iss.only=futoi\''}
                </p>
                <p className={styles.standartText}>
                    df = pd.read_csv(url, sep=
                    {'\';\''}, skiprows= 2)
                </p>
            </div>

            <div className={styles.boxBlock}>
                <p className={styles.standartText}># Преобразование данных</p>
                <p className={styles.standartText}>
                    df[{'"tradedatetime"'}] = df[[
                    {'"tradedate"'}, {'"tradetime"'}]].apply( lambda x: {'" "'}
                    .join(x), axis= 1)
                </p>
                <p className={styles.standartText}>
                    df[{'"tradedatetime"'}] = pd.to_datetime(df[
                    {'"tradedatetime"'}], format=
                    {'"%Y-%m-%d %H:%M:%S"'})
                </p>
                <p className={styles.standartText}>
                    df = df.pivot_table(index={'"tradedatetime"'}, columns=
                    {'"clgroup"'}, values=
                    {'"pos_long"'})
                </p>
            </div>

            <div className={styles.boxBlock}>
                <p className={styles.standartText}># Построение графика</p>
                <p className={styles.standartText}>df[{'"FIZ"'}].plot()</p>{' '}
                <p className={styles.standartText}>
                    df[{'"YUR"'}].plot(secondary_y=True, style=
                    {'"r"'})
                </p>
            </div>

            {/*import matplotlib.pyplot as plt plt.rcParams['figure.figsize'] = (10, 5) plt.style.use('ggplot')*/}
            {/*ticker = 'Si' date1 = '2024-04-04' date2 = '2024-04-05' # Запрос к API url =*/}
            {/*f'https://iss.moex.com/iss/analyticalproducts/futoi/securities/{ticker}.csv?from={date1}&till=*/}
            {/*{date2}&iss.only=futoi' df = pd.read_csv(url, sep=';', skiprows=2) # Преобразование данных*/}
            {/*df["tradedatetime"] = df[["tradedate", "tradetime"]].apply(lambda x: " ".join(x), axis=1)*/}
            {/*df["tradedatetime"] = pd.to_datetime(df["tradedatetime"], format="%Y-%m-%d %H:%M:%S") df =*/}
            {/*df.pivot_table(index='tradedatetime', columns='clgroup', values='pos_long') # Построение графика*/}
            {/*df['FIZ'].plot() df['YUR'].plot(secondary_y=True, style="r")*/}
        </>
    );
};
