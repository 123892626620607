export const createCurvesHoverTemplate = (title: any) => {
    if (title) {
        return `<br>Дата: <b>${title.toString()}</b><br>Лет до погаш.: <b>%{x:.1f}</b><br>Доходность: <b>%{y:.2f}%</b><br><extra></extra>`;
    } else {
        return '';
    }
};

export const createSurfaceHoverTemplate = (xAxistitle: string) => {
    return `<br>Дата(Y):   <b>%{y}</b><br>Доходность(Z):   <b>%{z:.2f} %</b><br>${xAxistitle}(X):  <b>%{x}</b></br><extra></extra>`;
};
