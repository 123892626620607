import { ApiStringProps } from "../ApiString";

export const apiListData: Array<ApiStringProps> = [
    {
        method: "GET",
        url: "/datashop/algopack/eq/tradestats/{ticker}",
    },
    {
        method: "GET",
        url: "/datashop/algopack/eq/orderstats/{ticker}",
    },
    {
        method: "GET",
        url: "/datashop/algopack/eq/obstats/{ticker}",
    },
];
