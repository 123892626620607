import { type Dispatch, type SetStateAction } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowRed } from '@shared/images/svg/ArrowRed.svg';
import { ReactComponent as ArrowWhite } from '@shared/images/svg/ArrowWhite.svg';

import styles from './styles.module.css';

type Props = {
    activeSlide: number;
    setActiveSlide: Dispatch<SetStateAction<number>>;
    totalNumber: number;
    className?: string;
};

export const SliderButtons = ({ activeSlide, setActiveSlide, totalNumber, className }: Props) => {
    const isDisableLeftButton = activeSlide <= 0;
    const isDisableRightButton = activeSlide >= totalNumber - 1;

    const increment = () => {
        setActiveSlide((prevState) => {
            const res = prevState + 1;

            return res > totalNumber - 1 ? prevState : prevState + 1;
        });
    };

    const decrement = () => {
        setActiveSlide((prevState) => {
            const res = prevState - 1;

            return res < 0 ? prevState : res;
        });
    };

    return (
        <div className={classNames('flex', className)}>
            <button
                disabled={isDisableLeftButton}
                onClick={decrement}
                className={classNames(styles.Button, styles.LeftButton)}
            >
                <div className={classNames(styles.IconWrapper, isDisableLeftButton ? styles.Red : styles.White)}>
                    <div>{isDisableLeftButton ? <ArrowRed /> : <ArrowWhite />}</div>
                </div>
            </button>
            <button
                disabled={isDisableRightButton}
                onClick={increment}
                className={classNames(styles.Button, styles.RightButton)}
            >
                <div className={classNames(styles.IconWrapper, isDisableRightButton ? styles.Red : styles.White)}>
                    <div>{isDisableRightButton ? <ArrowRed /> : <ArrowWhite />}</div>
                </div>
            </button>
        </div>
    );
};
