import classNames from 'classnames';
import { useResize } from '@libs/utils';
import { DesktopVersion } from '@modules/Algopack/AlgopackPage/components/DataConstitution/DataItem/DesktopVersion';
import { MobileVersion } from '@modules/Algopack/AlgopackPage/components/DataConstitution/DataItem/MobileVersion';

import styles from './styles.module.scss';

export type DataItemType = {
    title: string;
    tags: string[];
    description: string;
    logo: string;
    dataUpdateTime: string;
    historicalData?: string;
    isPythonLib: boolean;
    lastElement: boolean;
    link: string;
};

export const DataItem = (props: DataItemType) => {
    const { isScreenSm } = useResize();

    return (
        <article
            className={classNames(styles.dataItemWrapper, 'flex', isScreenSm && 'flex-column')}
            onClick={() => {
                window.open(props.link);
            }}
        >
            {isScreenSm ? <MobileVersion {...props} /> : <DesktopVersion {...props} />}
        </article>
    );
};
