import { TabPanel, TabView } from 'primereact/tabview';
import { useState } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { PlusIcon } from 'primereact/icons/plus';

import { ScreenerPage } from './components/Screener';
import styles from './styles.module.scss';
import { ReactComponent as ScreenerLogo } from '../../shared/images/svg/ScreenerLogo.svg';
import { Empty } from './components/Empty';
import { ScreenersFooter } from './components/ScreenersFooter';
import { type ScreenersListGetResponseType } from './services/types';
import { INVESTORPRO_MOCK } from '../../investorpro.mock';

export const ScreenersPage = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [screenersState, setScreenersState] = useState<ScreenersListGetResponseType>(
        INVESTORPRO_MOCK as unknown as ScreenersListGetResponseType,
    );

    // TODO временно убрано для использования мока на демо
    // useEffect(() => {
    //   (async function () {
    //     const response = await screenersListGetRequest();
    //     setScreenersState(response?.data ?? {});
    //   })();
    // }, [1]);

    function handleRenameScreener({ title }: { title: string }) {
        setScreenersState({
            ...screenersState,
            screeners: screenersState.screeners?.map((screener, index) => {
                // TODO при реализации обращения к APIList бекенда убрать условие на локальный индекс вкладки
                if (index === activeIndex) {
                    return {
                        id: screener.id,
                        title,
                    };
                }

                return { ...screener };
            }),
        });
    }

    function handleCopyScreener({ title }: { title: string }) {
        screenersState.screeners.push({
            // TODO при реализации обращения к APIList бекенда убрать условие на локальный индекс вкладки
            id: screenersState.screeners[activeIndex].id,
            title,
        });
        const res = {
            ...screenersState,
        };
        setScreenersState(res);
        setActiveIndex(screenersState.screeners.length - 1);
    }

    function handleDeleteScreener() {
        screenersState.screeners.splice(activeIndex, 1);
        const res = {
            ...screenersState,
        };
        setScreenersState(res);
        const newDefaultActiveIndex = activeIndex - 1;
        setActiveIndex(newDefaultActiveIndex >= 0 ? newDefaultActiveIndex : 0);
    }

    function handleSetIsFavourite({ id, isFavourite }: { id: string; isFavourite: boolean }) {
        const screenerId = screenersState.screeners[activeIndex].id;

        const screenerKey = screenerId === 1 ? 'screener1' : 'screener2';
        setScreenersState({
            ...screenersState,
            [screenerKey]: {
                id: screenerId,
                data: screenersState[screenerKey].data.map((row) => {
                    if (row.id !== id) {
                        return row;
                    }

                    return {
                        ...row,
                        isFavourite,
                    };
                }),
            },
        });
    }

    return (
        <div>
            <div className={classNames(styles.pageBg, 'flex')}>
                <div className={classNames(styles.pageTitle, 'flex align-items-center')}>
                    <ScreenerLogo className={styles.screenerLogo} />
                    Скринеры
                </div>
                {screenersState.screeners?.length
? (
                    <Button className="ml-auto mt-auto mr-5">
                        <PlusIcon />
                    </Button>
                )
: null}
            </div>
            {screenersState.screeners?.length
? (
                <div className="flex flex-column">
                    <TabView
                        className={styles.tabView}
                        activeIndex={activeIndex}
                        onTabChange={({ index }) => setActiveIndex(index)}
                    >
                        {screenersState.screeners.map((screener, index) => (
                            <TabPanel
                                key={screener.id + '' + index}
                                header={screener.title}
                                className={styles.tabPanel}
                            >
                                <ScreenerPage
                                    data={
                                        screener.id === 1
                                            ? screenersState.screener1.data
                                            : screenersState.screener2.data
                                    }
                                    onSetIsFavourite={handleSetIsFavourite}
                                />
                            </TabPanel>
                        ))}
                    </TabView>
                    <ScreenersFooter
                        currentScreenerTitle={screenersState.screeners[activeIndex]?.title ?? ''}
                        onRenameScreener={handleRenameScreener}
                        onCopyScreener={handleCopyScreener}
                        onDeleteScreener={handleDeleteScreener}
                    />
                </div>
            )
: (
                <Empty />
            )}
        </div>
    );
};
