import { type ForwardedRef, forwardRef, useCallback, useEffect, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import './styles.css';
import { type FormEvent, type Nullable } from 'primereact/ts-helpers';
import { addLocale } from 'primereact/api';

type DatePickerProps = {
    label?: string;
    value?: Date | string;
    onChangeHandler?: (event: Nullable<Date>) => void;
};

addLocale('ru', {
    firstDayOfWeek: 1,
    dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    dayNamesShort: ['Вс', 'Пон', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    dayNamesMin: ['Вс', 'Пон', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ],
});

export const DatePicker = forwardRef(({ value, label, ...props }: DatePickerProps, ref: ForwardedRef<Calendar>) => {
    const [chosenValue, setChosenValue] = useState<Date | Nullable<Date>>();

    useEffect(() => {
        if (typeof value === 'string') {
            setChosenValue(new Date(value));
        } else {
            setChosenValue(value);
        }
    }, [value]);

    const updateValue = useCallback((event: FormEvent<Nullable<Date>>) => {
        setChosenValue(event.value);

        if (props.onChangeHandler) {
            props.onChangeHandler(event.value);
        }
    }, []);

    return (
        <div className="calendar-container">
            <div className="p-float-label">
                <Calendar
                    inputId="dateControl"
                    value={chosenValue}
                    showIcon
                    dateFormat="dd.mm.yy"
                    onChange={updateValue}
                    ref={ref}
                    locale="ru"
                    {...props}
                />
                <label htmlFor="dateControl">{label}</label>
            </div>
        </div>
    );
});
