import classNames from 'classnames';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { useResize } from '@libs/utils';

import styles from './styles.module.scss';

export const DataUpload = () => {
    const { isScreenLessSm, isScreenSm } = useResize();

    const [periodToggleActive, setPeriodToggleActive] = useState<boolean>(false);
    const [selectedItems] = useState<TPriceMarkerData[]>(MOCK_SELECTED_MARKERS);

    return (
        <div className={styles.dataUploadContainer}>
            <h1>Выгрузка данных</h1>
            <div className={styles.dataUpload}>
                <div className={styles.toggleButtons}>
                    <Button
                        type="button"
                        label="Списком инструментов на дату"
                        className={classNames('', {
                            [styles['toggleActive']]: !periodToggleActive,
                            [styles['toggleInactive']]: periodToggleActive,
                        })}
                        onClick={() => setPeriodToggleActive(!periodToggleActive)}
                    />
                    <Button
                        type="button"
                        label="Инструмента на период времени"
                        className={classNames('', {
                            [styles['toggleActive']]: periodToggleActive,
                            [styles['toggleInactive']]: !periodToggleActive,
                        })}
                        onClick={() => setPeriodToggleActive(!periodToggleActive)}
                    />
                </div>

                <div className={styles.mainBlock}>
                    <div>
                        <p>Выбора инструмента</p>
                        <div className="flex align-items-center gap-3">
                            <AutoComplete
                                placeholder="ISIN, название инструмента"
                                dropdown
                                dropdownIcon="pi pi-search"
                                className={styles.autoCompleteInput}
                            />
                            {!periodToggleActive && (
                                <Button
                                    type="button"
                                    label={isScreenLessSm || isScreenSm ? undefined : 'Загрузить список'}
                                    icon="pi pi-download"
                                    className={classNames(styles.listDownloadButton)}
                                />
                            )}
                        </div>
                    </div>

                    <div>
                        <p>{periodToggleActive ? 'Данные за период' : 'Данные за дату'}</p>
                        <div className="flex align-items-center gap-2">
                            <Calendar
                                value={new Date()}
                                dateFormat="dd.mm.yy"
                                showIcon
                                className={styles.calendarInput}
                            />
                            {periodToggleActive && (
                                <>
                                    <i className="pi pi-minus text-xs" />
                                    <Calendar
                                        value={new Date()}
                                        dateFormat="dd.mm.yy"
                                        showIcon
                                        className={styles.calendarInput}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex align-items-center flex-wrap gap-2">
                    {selectedItems.map((item) => {
                        return (
                            <Button key={item.code} type="button" className={classNames(styles.selectedItem)}>
                                <span>{item.name}</span>
                                <i className="pi pi-times" />
                            </Button>
                        );
                    })}
                </div>

                <div className={styles.blockDivider} />

                <div className={styles.saveButtons}>
                    <Button
type="button" label="Скачать Excel" icon="pi pi-file-excel"
className={styles.saveButton} />
                    <Button type="button" label="Скачать CSV" className={styles.saveButton} />
                    <Button type="button" label="Заказать выписку ценового центра НРД" className={styles.saveButton} />
                    <Button type="button" label="Сбросить" className={styles.clearButton} />
                </div>
            </div>
        </div>
    );
};

type TPriceMarkerData = {
    code: string;
    name: string;
    value: number;
};

const MOCK_SELECTED_MARKERS: TPriceMarkerData[] = [
    {
        code: '1',
        name: 'ОФЗ-26238-ПД',
        value: 11.86,
    },
    {
        code: '2',
        name: '26238-ПД',
        value: 15.33,
    },
    {
        code: '3',
        name: 'ОФЗ-26238',
        value: 9.16,
    },
];
