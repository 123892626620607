import { ReactComponent as PriceCenterLogo } from '@shared/images/priceCenter/svg/PriceCenterLogo.svg';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import styles from './styles.module.scss';

const navigations = [
    {
        text: 'Интерактивная карта',
        tab: 'interactiveMap',
    },
    {
        text: 'Выгрузка данных',
        tab: 'dataUpload',
    },
    {
        text: 'Описание API',
        tab: 'apiDescription',
    },
];

export const Header = () => {
    const [, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    return (
        <div className="w-full flex justify-content-between align-items-center">
            <Link to={''} onClick={() => navigate(-1)}>
                <PriceCenterLogo className={styles.headerLogo} />
            </Link>
            <nav>
                <ul>
                    {navigations.map(({ text, tab }, index) => (
                        <li
                            key={tab}
                            className={styles.navigationItem}
                            onClick={() => {
                                setSearchParams({ tab });
                            }}
                        >
                            {text}
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};
