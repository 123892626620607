import { TraceObj } from '@modules/ProPack/components/YieldCurves/types';

export const getTrace = (traceObj: TraceObj) => {
    const { x, y, z, color, width, hovertemplate, curveName } = traceObj;
    const trace: Partial<Plotly.PlotData | any> = {
        x,
        y,
        z,
        mode: 'lines',
        line: {
            color: color || 'red',
            width: width || 5,
        },
        type: 'scatter3d',
        hovertemplate: hovertemplate || '',
        curveName,
    };
    return trace;
};
