import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkSubscribePossibility } from "@modules/PriceCenter/services/subscribe.service";

export const checkSubscribePossibilityThunk = createAsyncThunk(
    'subscribe/checkSubscribePossibility',
    async (productId: string, { rejectWithValue }) => {
        try {
            const { data } = await checkSubscribePossibility(productId);

            return data.data;
        } catch (e) {
            console.error('e', e);

            return rejectWithValue(e);
        }
    },
);
