import classNames from 'classnames';

import styles from './styles.module.scss';
import { itemsList } from './data';

type PosItemType = {
    icon: string;
    title: string;
    subtitle: string;
    text: string;
};

const PosItem = ({ icon, title, subtitle, text }: PosItemType) => {
    return (
        <div className={classNames('flex', styles.posItem)}>
            <img src={icon} />
            <div>
                <div>
                    <p>{title}</p>
                    <p>{subtitle}</p>
                </div>
                <span>{text}</span>
            </div>
        </div>
    );
};

export const PosAlgopack = () => {
    return (
        <section className={classNames('flex', 'justify-content-between', styles.posContainer)}>
            <h2>ALGOPACK позволяет</h2>
            <div>
                {itemsList.map((prop) => (
                    <PosItem key={prop.title} {...prop} />
                ))}
            </div>
        </section>
    );
};
