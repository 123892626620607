import contentImg1 from '@shared/images/propack/png/contentImg1.png';
import contentImg2 from '@shared/images/propack/png/contentImg2.png';

export const contentSliderList = [
    {
        text: 'Возможность сравнивать ставки между рынками облигаций, РЕПО и СПФИ',
        heading: 'возможности',
        title: 'Вся информация о временной структуре ставок в едином окне',
        image: contentImg1,
    },
    {
        text: 'Графическое представление динамики ставок за несколько лет для всех сроков (от 1 дня до 10 лет)',
        heading: 'возможности',
        title: 'Многомерный анализ индикаторов временной стоимости денег',
        image: contentImg2,
    },
];
