import { httpClient } from "@configs/httpclient.config";
import { AxiosResponse } from "axios";
import { ToolsRoot, YieldCurvesRoot } from "./types";

export const CURVES_URL = '/moex-propack-integrationservice/api/curves/v1/data-for-graph';
export const TOOLS_URL = '/moex-propack-integrationservice/api/curves/v1/instrument';

export const getCurvesCoords = (
    market: string,
    group: string,
    curveName: string,
    startDate: string,
    endDate?: string | null,
): Promise<AxiosResponse<YieldCurvesRoot>> => {
    // Dates string are YYYY-MM-DD format
    return httpClient.post(CURVES_URL, {
        market,
        group,
        curveName,
        from: startDate,
        till: endDate,
    });
};

export const getToolsData = (startDate: string, endDate?: string | null): Promise<AxiosResponse<ToolsRoot>> => {
    // Dates string are YYYY-MM-DD format
    return httpClient.post(TOOLS_URL, {
        from: startDate,
        till: endDate,
    });
};
