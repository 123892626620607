import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProductByGuid, getProductList } from "@libs/services";
import { type ProductReq } from "@libs/types";

export const getProductListThunk = createAsyncThunk('product/getProductList', async (body: ProductReq, { rejectWithValue }) => {
    try {
        const { data } = await getProductList(body);

        return data.data.rows;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});

export const getProductByGuidThunk = createAsyncThunk('product/getProductByGuidThunk', async (productGuid: string, { rejectWithValue }) => {
    try {
        const { data } = await getProductByGuid(productGuid);

        return data.data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});
