import { createSlice } from "@reduxjs/toolkit";
import { getTariffsThunk } from "@store/store/thunk/tariff.thunk";
import { DatashopTariff, TariffParam } from "@libs/types";

type IState = {
    datashopTariff: DatashopTariff | null;
    periodFilter: TariffParam | null;
    tariffPlan: TariffParam | null;
};

export const initialState: IState = {
    datashopTariff: null,
    periodFilter: null,
    tariffPlan: null,
};

export const tariffSlice = createSlice({
    name: 'tariff',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTariffsThunk.fulfilled, (state, action) => {
            if (action.payload && action.payload[0]) {
                state.datashopTariff = action.payload[0];

                const periodFilter = action.payload[0].billingTables[0].tariffParams.find(
                    (item) => item.position === 'COLUMN',
                )!;
                periodFilter.values.sort((a, b) => a.valueOrder - b.valueOrder);
                state.periodFilter = periodFilter;

                const tariffPlan = action.payload[0].billingTables[0].tariffParams.find(
                    (item) => item.position === 'LINE',
                )!;
                tariffPlan.values.sort((a, b) => b.valueOrder - a.valueOrder);
                state.tariffPlan = tariffPlan;
            }
        });
    },
});
export const tariffReducer = tariffSlice.reducer;
