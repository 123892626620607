import { useEffect } from 'react';
import { createBrowserRouter, Navigate, useNavigate } from 'react-router-dom';
import { ErrorPage } from '@modules/ErrorPage';
import { MainPage } from '@modules/MainPage';
import { TechnicPage } from '@modules/TechnicPage';
import { AlgopackPage } from '@modules/Algopack/AlgopackPage';
import { InvestorPro } from '@modules/Investorpro';
import { ScreenersPage } from '@modules/Investorpro/modules/ScreenersPage';
import { ProductsPage } from '@modules/ProductsPage';
import { CartPage } from '@modules/CartPage';
import { PersonalAccount } from '@modules/PersonalAccount/PersonalAccountPage';
import { PriceCenter } from '@modules/PriceCenter';
import { ProPack } from '@modules/ProPack';
import { PaymentInfoPage } from '@modules/PaymentInfoPage';
import { ProPackLanding } from '@modules/ProPackLanding';
import { ComplianceToolLanding } from '@modules/ComplianceToolLanding';
import { PriceCenterProduct } from '@modules/PriceCenterProduct';
import { LandingPage } from '@modules/Investorpro/modules/LandingPage';
import { useAuthorize } from '@libs/utils';
import { SessionExpiredPage } from '@modules/SessionExpiredPage';
import { SessionEmailExpiredPage } from '@modules/PersonalAccount/SessionEmailExpiredPage';
import { LinkNotValidPage } from '@modules/PersonalAccount/LinkNotValidPage';
import { Layout } from '@libs/components/layout/Layout';

const ProtectedRoute = ({ element }: { element: JSX.Element }) => {
    const { isAuthorized } = useAuthorize();

    return isAuthorized ? element : <Navigate to={'/'} />;
};

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(localStorage.getItem('preLoginPath') || '');
    }, []);

    return <></>;
};

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: <MainPage />,
            },
            {
                path: 'tech-page',
                element: <TechnicPage />,
            },
            {
                path: 'products',
                children: [
                    {
                        path: '',
                        element: <ProductsPage />,
                    },
                    {
                        path: 'algopack',
                        element: <AlgopackPage />,
                    },
                    {
                        path: 'propack',
                        element: <ProPackLanding />,
                    },
                    {
                        path: 'propack/example',
                        element: <ProPack />,
                    },
                    {
                        path: 'investorpro',
                        element: <InvestorPro />,
                        children: [
                            {
                                path: '',
                                element: <LandingPage />,
                            },
                            {
                                path: 'screeners',
                                element: <ScreenersPage />,
                            },
                        ],
                    },
                    {
                        path: 'pricecenter',
                        children: [
                            { index: true, element: <PriceCenter /> },
                            {
                                path: 'product',
                                element: <PriceCenterProduct />,
                            },
                        ],
                    },
                    // {
                    //     path: 'about',
                    //     children: [
                    //         {
                    //             path: 'algopack',
                    //             element: <AboutAlgopackPage />,
                    //         },
                    //     ],
                    // },
                    {
                        path: 'complianceTool',
                        element: <ComplianceToolLanding />,
                    },
                    // {
                    //     path: 'marketDive',
                    //     element: <MarketDive />,
                    // },
                ],
            },
            // {
            //     path: 'help-center',
            //     element: <HelpCenter />,
            // },
            // {
            //     path: 'search-page',
            //     element: <SearchMainPage />,
            // },
            {
                path: 'personal-account',
                children: [
                    {
                        path: '',
                        element: <ProtectedRoute element={<PersonalAccount />} />,
                    },
                    {
                        path: 'session-expired',
                        element: <SessionEmailExpiredPage />,
                    },
                    {
                        path: 'not-valid-link',
                        element: <LinkNotValidPage />,
                    },
                ],
            },
            {
                path: 'cart',
                element: <CartPage />,
            },
            // {
            //     path: 'articles',
            //     children: [
            //         {
            //             path: '',
            //             element: <ArticleListPage />,
            //         },
            //         {
            //             path: ':articleId',
            //             element: <ArticlePage />,
            //         },
            //     ],
            // },
            {
                path: 'payment-info/:status',
                element: <PaymentInfoPage />,
            },
            { path: 'session-expired', element: <SessionExpiredPage /> },
            { path: 'logout', element: <Logout /> },
        ],
    },
]);
