import classNames from 'classnames';

import { mark } from './data';
import styles from './styles.module.scss';

export const ProductMarks = () => {
    return (
        <div className={classNames('flex', styles.markDescriptionWrapper)}>
            {Object.keys(mark).map((key, index) => (
                <div key={index} className={classNames('flex', 'align-items-center', styles.markDescription)}>
                    <div
                        className={classNames('flex', 'justify-content-center', 'align-items-center')}
                        style={{ backgroundColor: mark[key].color }}
                    >
                        <img src={mark[key].icon} />
                    </div>
                    <p>{mark[key].description}</p>
                </div>
            ))}
        </div>
    );
};
