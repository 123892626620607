import classNames from 'classnames';
import { Button } from 'primereact/button';
import { ReactComponent as LockIcon } from '@shared/images/svg/Lock.svg';
import { useModal } from '@libs/utils';
import { ModalWindow } from '@libs/components';

import styles from './styles.module.scss';

export type ServiceItemProps = {
    title: string;
    description: string;
    image: string;
    status: 'ACTIVE' | 'SOON_AVAILABLE';
    modalInfo: {
        title: string;
        description: string;
        points: string[];
    };
};

export const ServiceItem = ({ title, description, image, status, modalInfo }: ServiceItemProps) => {
    const { isOpen, openModal, closeModal } = useModal();

    return (
        <>
            <div className={classNames(styles.serviceItemWrapper, 'flex')}>
                <div className={classNames('flex', 'flex-column', 'justify-content-between', styles.item_content)}>
                    <div className={styles.titleWrapper}>
                        <h6 className={classNames(status !== 'ACTIVE' && styles.notActive)}>{title}</h6>
                        <p className={classNames(status !== 'ACTIVE' && styles.notActive)}>{description}</p>
                    </div>
                    <Button
                        icon={status !== 'ACTIVE' && <LockIcon />}
                        label={status === 'ACTIVE' ? 'Открыть' : 'Релиз в этом году'}
                        onClick={status === 'ACTIVE' ? openModal : undefined}
                        className={classNames(status !== 'ACTIVE' && styles.notActive)}
                    />
                </div>
                <div className={styles.item_image} style={{ backgroundImage: `url(${image})` }} />
            </div>
            <ModalWindow isVisible={isOpen} closeModal={closeModal}>
                <div className={styles.popupWrapper}>
                    <h2>{modalInfo.title}</h2>
                    <p>{modalInfo.description}</p>
                    <ul>
                        {modalInfo.points.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                    <Button label={'Закрыть'} onClick={closeModal} />
                </div>
            </ModalWindow>
        </>
    );
};
