import { Footer, Navigation } from '@libs/components';
import { Outlet } from 'react-router-dom';

import { Header } from './shared/components/Header';
import { YieldCurves } from './components/YieldCurves';

export const ProPack = () => {
    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation menuShowLessWidth={1260} withUnderLine={false}>
                        <Header />
                    </Navigation>

                    {/* TODO: add navigation to other curves in future or change nav menu */}
                    <YieldCurves />
                    <Outlet />
                </div>
            </div>
            <Footer />
        </>
    );
};
