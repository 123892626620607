import { createSlice } from "@reduxjs/toolkit";
import { getProductListThunk } from "../thunk/product.thunk";
import { Product } from "@libs/types";

type IState = {
  productList: Product[];
};

export const initialState: IState = {
  productList: [],
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProductListThunk.fulfilled, (state, action) => {
      state.productList = action.payload!;
    });
  },
});

export const productReducer = productSlice.reducer;
