import classNames from 'classnames';
import { advantagesList } from '@modules/Investorpro/modules/LandingPage/components/Advantages/data';
import { AdvantageItem } from '@modules/Investorpro/modules/LandingPage/components/Advantages/AdvantageItem';

import styles from './styles.module.scss';

export const Advantages = () => {
    return (
        <section className={classNames(styles.advantagesWrapper, 'flex')}>
            {advantagesList.map((item) => (
                <AdvantageItem key={item.title} {...item} />
            ))}
        </section>
    );
};
