import AlgorPay from '@shared/images/algopack/AlgorPay.svg';
import CreateAlg from '@shared/images/algopack/CreateAlg.svg';
import TestStrat from '@shared/images/algopack/TestStrat.svg';
import AccessToSug from '@shared/images/algopack/AccessToSug.svg';

export const itemsList = [
  {
    icon: CreateAlg,
    title: 'Создавать торговые',
    subtitle: 'алгоритмы',
    text: 'Наличие в ALGOPACK online данных позволяет создать и автоматизировать торговый алгоритм ',
  },
  {
    icon: AlgorPay,
    title: 'Торговать алгоритмами',
    subtitle: 'на сигналах',
    text: 'Сигналы ALGOPACK рассчитаны на потоке сделок и заявок, что дает глубокое понимание о движении рынка ',
  },
  {
    icon: TestStrat,
    title: 'Тестировать торговые',
    subtitle: 'стратегии',
    text: 'Через API можно загружать исторические данные и проводить backtest различных торговых стратегий ',
  },
  {
    icon: AccessToSug,
    title: 'Доступ к программным',
    subtitle: 'решениям',
    text: 'Получить доступ к примерам программных решений, созданных на данных ALGOPACK',
  },
];
