import styles from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/styles.module.scss';
import React from 'react';
import { SelectedPointsModal } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/SelectedPointsModal';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { MarketPointPanel } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer/components/ChartTooltips';
import { unSelectMarketPoint } from '@store/store/slices/pricecenter.slice';

export const MarketHeader = () => {
    const dispatch = useDispatch<AppDispatch>();
    const charts = useSelector((state: RootState) => state.priceCenter.charts);

    return (
        <div className="px-3 w-full flex justify-content-between align-items-center">
            <div className={styles.mapHeaderTitle}>
                <h2>Карта рынка</h2>
            </div>
            <SelectedPointsModal
                selectedMarkers={(charts.data.marketSelectedPoints ?? []).map((point) => {
                    return (
                        <MarketPointPanel
                            key={`${point.datasetIndex}_${point.dataPointIndex}}`}
                            point={point}
                            onClick={(point) => {
                                dispatch(unSelectMarketPoint(point));
                            }}
                        />
                    );
                })}
            />
        </div>
    );
};
