import { type PriceCenterChart } from '@store/store/thunk/pricecenter/Models';
import { Checkbox } from 'primereact/checkbox';

import styles from '../../styles.module.scss';

type VisibilityTogglesProps = {
    groupChartCode: string;
    data: Array<PriceCenterChart<any>>;
    toggleChartVisibility: (charCode: string) => void;
    colors: string[];
};

export const VisibilityToggles = (props: VisibilityTogglesProps) => {
    const { groupChartCode, data, toggleChartVisibility, colors } = props;

    return (
        <div className="px-2 w-full flex justify-content-start align-items-center flex-wrap gap-5">
            {data.map((chart: PriceCenterChart<any>, index) => {
                const key = `${groupChartCode}-toggle-${index}`;
                const keyCheckbox = key + '-checkbox';
                const color = colors[index];

                return (
                    <div
                        key={key}
                        className={styles.chartToggleCheckboxContainer}
                        style={{
                            opacity: !chart.visible ? 0.5 : 1,
                        }}
                        onClick={() => toggleChartVisibility(chart.chartCode)}
                    >
                        <Checkbox
                            inputId={keyCheckbox}
                            className={styles.checkbox}
                            pt={{
                                box: { style: { background: color, border: 'none' } },
                            }}
                            checked={chart.visible ?? false}
                        />
                        <label htmlFor={keyCheckbox}>{chart.chartName}</label>
                    </div>
                );
            })}
        </div>
    );
};
