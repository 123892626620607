import { useState } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ModalWindow } from '@libs/components';

import { ReactComponent as PencilIcon } from '../../../../shared/images/svg/Pencil.svg';
import { ReactComponent as CopyIcon } from '../../../../shared/images/svg/Copy.svg';
import { ReactComponent as BinIcon } from '../../../../shared/images/svg/Bin.svg';
import { ReactComponent as FilterIcon } from '../../../../shared/images/svg/Filter.svg';
import styles from './styles.module.scss';

type ScreenersFooterProps = {
    currentScreenerTitle: string;
    onRenameScreener: (event: { title: string }) => void;
    onDeleteScreener: () => void;
    onCopyScreener: (event: { title: string }) => void;
};

export const ScreenersFooter = ({
    currentScreenerTitle,
    onRenameScreener,
    onCopyScreener,
    onDeleteScreener,
}: ScreenersFooterProps) => {
    const [screenerTitle, setScreenerTitle] = useState(currentScreenerTitle ?? '');

    // region RenameDialog
    const [isRenameDialogVisible, setIsRenameDialogVisible] = useState(false);

    function handleHideRenameDialog() {
        setIsRenameDialogVisible(false);
    }

    function handleInitRenameScreener() {
        setScreenerTitle(currentScreenerTitle);
        setIsRenameDialogVisible(true);
    }

    function handleRenameScreener() {
        onRenameScreener({ title: screenerTitle });
        setIsRenameDialogVisible(false);
    }
    // endregion RenameDialog

    // region CopyDialog
    const [isCopyDialogVisible, setIsCopyDialogVisible] = useState(false);

    function handleHideCopyDialog() {
        setIsCopyDialogVisible(false);
    }

    function handleInitCopyScreener() {
        setScreenerTitle(`${currentScreenerTitle} (версия 2)`);
        setIsCopyDialogVisible(true);
    }

    function handleCopyScreener() {
        onCopyScreener({ title: screenerTitle });
        setIsCopyDialogVisible(false);
    }
    // endregion CopyDialog

    // region DeleteDialog
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

    function handleHideDeleteDialog() {
        setIsDeleteDialogVisible(false);
    }

    function handleInitDeleteScreener() {
        setScreenerTitle(currentScreenerTitle);
        setIsDeleteDialogVisible(true);
    }

    function handleDeleteScreener() {
        onDeleteScreener();
        setIsDeleteDialogVisible(false);
    }
    // endregion DeleteDialog

    return (
        <div className={classNames(styles.wrapper, 'flex', 'justify-content-between', 'align-items-center')}>
            <div className={'flex'}>
                <Button
                    severity="secondary"
                    outlined
                    className={classNames(styles.actionButton, 'flex', 'justify-content-center')}
                    onClick={handleInitRenameScreener}
                >
                    <PencilIcon />
                </Button>
                <Button
                    severity="secondary"
                    outlined
                    className={classNames(styles.actionButton, 'flex', 'justify-content-center')}
                    onClick={handleInitCopyScreener}
                >
                    <CopyIcon />
                </Button>
                <Button
                    severity="secondary"
                    outlined
                    className={classNames(styles.actionButton, 'flex', 'justify-content-center')}
                    onClick={handleInitDeleteScreener}
                >
                    <BinIcon />
                </Button>
                <Button size="small" className={styles.filterButton}>
                    <FilterIcon />
                    <span>Фильтр</span>
                </Button>
            </div>
            <ModalWindow
                closeModal={handleHideRenameDialog}
                isVisible={isRenameDialogVisible}
                className={styles.dialogContainer}
            >
                <div className={styles.dialog}>
                    <div className={styles.dialogWrapper}>
                        <div className={styles.dialogHeader}>Переименовать скринер</div>
                        <InputText
                            value={screenerTitle}
                            className={styles.dialogInput}
                            onChange={(e) => setScreenerTitle(e.target.value)}
                        />
                        <div className={classNames('flex')}>
                            <Button
                                className={styles.dialogActionButton}
                                severity="secondary"
                                onClick={handleHideRenameDialog}
                            >
                                Отменить
                            </Button>
                            <Button
                                className={classNames(styles.dialogActionButton, styles.dialogActionButtonSuccess)}
                                onClick={handleRenameScreener}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalWindow>

            <ModalWindow
                closeModal={handleHideCopyDialog}
                isVisible={isCopyDialogVisible}
                className={styles.dialogContainer}
            >
                <div className={styles.dialog}>
                    <div className={styles.dialogWrapper}>
                        <div className={styles.dialogHeader}>Копировать скринер</div>
                        <InputText
                            value={screenerTitle}
                            className={styles.dialogInput}
                            onChange={(e) => setScreenerTitle(e.target.value)}
                        />
                        <div className={classNames('flex')}>
                            <Button
                                className={styles.dialogActionButton}
                                severity="secondary"
                                onClick={handleHideCopyDialog}
                            >
                                Отменить
                            </Button>
                            <Button
                                className={classNames(styles.dialogActionButton, styles.dialogActionButtonSuccess)}
                                onClick={handleCopyScreener}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalWindow>

            <ModalWindow
                closeModal={handleHideDeleteDialog}
                isVisible={isDeleteDialogVisible}
                className={styles.dialogContainer}
            >
                <div className={styles.dialog}>
                    <div className={styles.dialogWrapper}>
                        <div className={styles.dialogHeader}>Удалить скринер</div>
                        <div className={styles.dialogContent}>
                            Вы уверены, что хотите удалить скринер «{screenerTitle}»? После удаления восстановить
                            скринер не получится.
                        </div>
                        <div className={classNames('flex')}>
                            <Button
                                className={styles.dialogActionButton}
                                severity="secondary"
                                onClick={handleHideDeleteDialog}
                            >
                                Отменить
                            </Button>
                            <Button
                                className={classNames(styles.dialogActionButton, styles.dialogActionButtonSuccess)}
                                onClick={handleDeleteScreener}
                            >
                                Удалить
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalWindow>
        </div>
    );
};
