import { configureStore } from '@reduxjs/toolkit';
import {
    algopackReducer,
    cardReducer,
    cartReducer,
    loadingReducer,
    priceCenterReducer,
    productReducer,
    userReducer,
    tariffReducer,
} from './slices';

const store = configureStore({
    reducer: {
        user: userReducer,
        loading: loadingReducer,
        product: productReducer,
        algopack: algopackReducer,
        priceCenter: priceCenterReducer,
        cart: cartReducer,
        card: cardReducer,
        tariff: tariffReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export { store };
export * from './thunk';
