import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';

import { type AlgopackRoot } from './types';

export const SUB_URL = '/moex-datashop-datashopservice/api/subscriptions/v1';

const getActiveSubscriptions = (keysArray: Array<string>): Promise<AxiosResponse<AlgopackRoot>> =>
    httpClient.post(SUB_URL + '/search', {
        productGuids: keysArray,
        statuses: ['ACTIVE'],
        pageSize: 100,
        pageNumber: 0,
    });

const subscribeById = (): Promise<AxiosResponse<any>> => httpClient.post(SUB_URL);

export { getActiveSubscriptions, subscribeById };
