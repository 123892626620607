import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import React, { useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { AutoComplete } from 'primereact/autocomplete';
import { ExtendedSearchMediumForm } from '@modules/PriceCenterProduct/InteractiveMap/FilterContainer/ExtendedSearchMediumForm';
import { parse } from 'date-fns';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { type PriceCenterFilter } from '@store/store/thunk/pricecenter/Models';
import {
    CALC_METHODS_DICT,
    ISSUER_SECTOR_DICT,
    LISTING_LEVEL_DICT,
    SECTOR_DICT,
    STATUS_DICT,
} from '@store/store/thunk/pricecenter/consts';
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';

import styles from './styles.module.scss';

type PriceCenterMainSmallFormFormProps = {
    form: UseFormReturn<PriceCenterFilter>;
    isLoading: boolean;
};

export const MainSmallForm = (props: PriceCenterMainSmallFormFormProps) => {
    const { form, isLoading } = props;
    const [activeIndexes, setActiveIndexes] = useState<number[]>([0]);
    const [searchIndex, setSearchIndex] = useState<number | undefined>();

    return (
        <>
            <div className="w-full flex justify-content-between align-items-center gap-2">
                <p className={styles.inputLabel}>Дата актуальности</p>
                <div className="w-6">
                    <Controller
                        control={form.control}
                        name="tradeDate"
                        render={({ field }) => (
                            <Calendar
                                dateFormat="dd.mm.yy"
                                showIcon
                                className={styles.calendarInput}
                                value={parse(field.value ?? '', 'yyyy-MM-dd', new Date())}
                                disabled
                            />
                        )}
                    />
                </div>
            </div>
            <Divider />

            <div className="w-full flex justify-content-between align-items-center">
                <div className={styles.blockTitle}>
                    <i className="pi pi-circle pb-1" />
                    <p>Метод расчета</p>
                </div>
                <Controller
                    control={form.control}
                    name="calcMethod"
                    render={({ field }) => (
                        <Dropdown
                            value={field.value}
                            onChange={field.onChange}
                            options={CALC_METHODS_DICT}
                            optionLabel="name"
                            placeholder="Выбрать"
                            className={classNames(styles.dropdownInput, 'w-6')}
                            disabled={isLoading}
                        />
                    )}
                />
            </div>

            <div className="w-full flex justify-content-between align-items-center">
                <div className={styles.blockTitle}>
                    <i className="pi pi-circle pb-1" />
                    <p>Сформировать список</p>
                </div>
                <div className="w-6 flex justify-content-between align-items-center gap-2">
                    <AutoComplete
                        placeholder="ISIN, название, номер"
                        className={styles.createListInput}
                        dropdown
                        dropdownIcon="pi pi-search"
                    />
                </div>
            </div>

            <Accordion
                multiple
                activeIndex={activeIndexes}
                onTabChange={(e) => setActiveIndexes((e.index ?? []) as number[])}
                className="w-full"
            >
                <AccordionTab
                    headerTemplate={
                        <div className={styles.blockTitle}>
                            <i className="pi pi-circle pb-1" />
                            <p>Поиск по параметрам</p>
                            <i
                                className={classNames({
                                    'pi pi-chevron-up pb-1 text-sm': activeIndexes.includes(0),
                                    'pi pi-chevron-down pb-1 text-sm': !activeIndexes.includes(0),
                                })}
                            />
                        </div>
                    }
                    pt={{
                        headerIcon: () => ({
                            className: 'hidden',
                        }),
                        headerAction: () => ({
                            className: 'bg-white border-none',
                        }),
                        content: () => ({
                            className: 'border-none pt-3',
                        }),
                    }}
                >
                    <div className="w-full flex flex-column align-items-center gap-3">
                        <div className="w-full flex justify-content-between align-items-center gap-3">
                            <div className="w-6 flex flex-column gap-2">
                                <p className={styles.inputLabel}>Статус</p>
                                <Controller
                                    control={form.control}
                                    name="status"
                                    render={({ field }) => (
                                        <MultiSelect
                                            value={field.value}
                                            onChange={field.onChange}
                                            options={STATUS_DICT}
                                            dataKey="code"
                                            optionLabel="name"
                                            className={classNames(styles.dropdownInput, 'w-full')}
                                            multiple
                                            disabled={isLoading}
                                        />
                                    )}
                                />
                            </div>

                            <div className="w-6 flex flex-column gap-2">
                                <p className={styles.inputLabel}>Листинг</p>
                                <Controller
                                    control={form.control}
                                    name="listingLevel"
                                    render={({ field }) => (
                                        <Dropdown
                                            value={field.value}
                                            onChange={field.onChange}
                                            options={LISTING_LEVEL_DICT}
                                            dataKey="code"
                                            optionLabel="name"
                                            className={classNames(styles.dropdownInput, 'w-full')}
                                            disabled={isLoading}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="w-full flex justify-content-between align-items-center gap-3">
                            <div className="w-6 flex flex-column gap-2">
                                <p className={styles.inputLabel}>Сектор</p>
                                <Controller
                                    control={form.control}
                                    name="sector"
                                    render={({ field }) => (
                                        <Dropdown
                                            value={field.value}
                                            onChange={field.onChange}
                                            options={SECTOR_DICT}
                                            dataKey="code"
                                            optionLabel="name"
                                            className={classNames(styles.dropdownInput, 'w-full')}
                                            disabled={isLoading}
                                        />
                                    )}
                                />
                            </div>

                            <div className="w-6 flex flex-column gap-2">
                                <p className={styles.inputLabel}>Отрасль</p>
                                <Controller
                                    control={form.control}
                                    name="issuerSector"
                                    render={({ field }) => (
                                        <Dropdown
                                            value={field.value}
                                            onChange={field.onChange}
                                            options={ISSUER_SECTOR_DICT}
                                            dataKey="code"
                                            optionLabel="name"
                                            className={classNames(styles.dropdownInput, 'w-full')}
                                            disabled={isLoading}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="w-full flex justify-content-between align-items-center gap-3">
                            <div className="w-full flex flex-column gap-2">
                                <p className={styles.inputLabel}>Доходность (%)</p>
                                <div className="w-full flex justify-content-center align-items-center gap-2">
                                    <Controller
                                        control={form.control}
                                        name="minYield"
                                        render={({ field }) => (
                                            <InputNumber
                                                placeholder="от"
                                                value={field.value}
                                                onChange={field.onChange}
                                                className="flex-grow-1"
                                                inputClassName={styles.profitInput}
                                            />
                                        )}
                                    />
                                    <i className="pi pi-minus text-xs" />
                                    <Controller
                                        control={form.control}
                                        name="maxYield"
                                        render={({ field }) => (
                                            <InputNumber
                                                placeholder="до"
                                                value={field.value}
                                                onChange={field.onChange}
                                                className="flex-grow-1"
                                                inputClassName={styles.profitInput}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <Accordion
                            multiple
                            activeIndex={searchIndex}
                            onTabChange={(e) => setSearchIndex((e.index as number[])[0])}
                            className="w-full"
                        >
                            <AccordionTab
                                unstyled
                                headerTemplate={
                                    <div className={styles.blockTitle}>
                                        <div className={styles.extendedSearchLabel}>Расширенный поиск</div>
                                        <i
                                            className={classNames({
                                                'pi pi-chevron-up text-xs': searchIndex === 0,
                                                'pi pi-chevron-down text-xs': searchIndex === undefined,
                                            })}
                                        />
                                    </div>
                                }
                                pt={{
                                    headerIcon: () => ({
                                        className: 'hidden',
                                    }),
                                    headerAction: () => ({
                                        className: 'bg-white border-none',
                                    }),
                                    content: () => ({
                                        className: 'border-none pt-3',
                                    }),
                                }}
                            >
                                <div className="pt-3 flex flex-column align-items-center gap-5">
                                    <ExtendedSearchMediumForm />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </AccordionTab>
            </Accordion>
        </>
    );
};
