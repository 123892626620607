import { Button } from 'primereact/button';
import { ReactComponent as ComplianceToolLogo } from '@shared/images/complianceTool/svg/logo.svg';
import { ReactComponent as StarIcon } from '@shared/images/complianceTool/svg/star.svg';
import classNames from 'classnames';
import { type RefObjectType } from '@libs/types';
import { scrollElementByRef } from '@libs/utils';

import styles from './styles.module.scss';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
};

export const LogoBlock = ({ refs }: Props) => {
    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return (
        <section className={styles.logoBlockWrapper}>
            <div className={styles.contentWrapper}>
                <ComplianceToolLogo />
                <p>
                    Платформа для участников рынка и эмитентов, включающая в себя набор платных и бесплатных сервисов
                    для решения задач комплаенс, а так же для коммуникации с участниками рынка
                </p>
                <div className={classNames('flex')}>
                    <Button type="button" onClick={() => handleButtonClick('gainAccessRef')}>
                        <StarIcon />
                        <span>Получить доступ</span>
                    </Button>
                </div>
            </div>
        </section>
    );
};
