import React from 'react';
import { Button } from 'primereact/button';
import { ReactComponent as SuccessIcon } from '@shared/images/svg/success.svg';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
    onClick: () => void;
};

export const ConfirmContactRequestModal = ({ onClick }: Props) => {
    return (
        <div className={classNames(styles.confirmContactRequestFormWrapper, 'flex', 'flex-column')}>
            <div className={classNames('flex', 'flex-column', 'align-items-center')}>
                <SuccessIcon />
                <h2>Ваша заявка принята. Команда Datashop свяжется с Вами в течение 2 рабочих дней.</h2>
            </div>

            <Button label={'Закрыть'} onClick={onClick} />
        </div>
    );
};
