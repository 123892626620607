import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { useEffect } from 'react';
import { getProductListThunk } from '@store/store/thunk/product.thunk';
import { type ProductReq } from '@libs/types';
import { ProductCard, ProductMarks } from '@libs/components';

import styles from './styles.module.scss';

const bodyReq: ProductReq = {
    pageable: {
        pageSize: 10,
        pageNumber: 0,
        sortField: 'order',
        sortOrder: 'asc',
    },
    statuses: ['ACTIVE', 'SOON_AVAILABLE'],
};

export const Products = () => {
    const dispatch = useDispatch<AppDispatch>();

    const productList = useSelector((state: RootState) => state.product.productList);

    useEffect(() => {
        dispatch(getProductListThunk(bodyReq));
    }, []);

    return (
        <section className={styles.productWrapper}>
            <ProductMarks />
            <div className={styles.productListWrapper}>
                {productList?.map((data, index) => (
                    <ProductCard
key={data.id} data={data} isProductPage
imagePosition={index % 2 ? 'left' : 'right'} />
                ))}
            </div>
        </section>
    );
};
