import styles from './styles.module.scss';

export const PageDisclaimer = () => {
  return (
    <div className={styles.disclaimerWrapper}>
      <p>
        Любые материалы, информация и данные, представленные в демо-версии аналитического продукта «Algopack», являются
        предварительными, предоставляются в целях тестирования и носят исключительно справочный характер,
        предоставляются исключительно для информационных целей, не являются побуждением к совершению каких-либо действий
        на торгах ПАО Московская Биржа, не являются индивидуальной инвестиционной рекомендацией. ПАО Московская Биржа не
        берет на себя каких-либо обязательств, не гарантирует и не предоставляет каких-либо заверений (будь то явных или
        подразумеваемых) касательно их точности, полноты, качества, коммерческой ценности, соответствия любым
        методологиям и описаниям или пригодности использования для каких-либо целей, а также касательно их объема,
        состава и формы представления данных, сроков и своевременности их предоставления.
      </p>
      <p>
        Материалы, информация и данные, представленные в демо-версии аналитического продукта «Algopack» предназначены
        только для ознакомления и собственного внутреннего использования в некоммерческих целях, без права дальнейшей
        передачи в любом виде и любыми средствами.
      </p>
    </div>
  );
};
