import { format, sub, endOfYesterday } from 'date-fns';

import { type Market } from '../../components/YieldCurves/types';

export const MAX_PLOTS_NUMBER = 5;

export const markerColors: Plotly.Color[] = ['#2380EB', '#EBC713', '#48A31A', 'red', '#2af7ed'];

export const scatterOptions = ['Кривые доходностей', 'Динамика процентной ставки'];

export const marketData: Market[] = [
    {
        id: 1,
        label: 'Облигации',
        value: 'bonds',
        curveTitle: 'Вид процентной кривой ',
        xAxisTitle: 'Лет до погашения',
        curveGroups: [
            {
                id: 1,
                label: 'Процентные кривые',
                value: 'zcyc',
                curves: [{ id: 1, label: 'Кривая бескупонной доходности ОФЗ', value: 'ZCYC' }],
            },
        ],
    },
    {
        id: 2,
        label: 'РЕПО',
        value: 'repo',
        curveTitle: 'Индикатор RUSFAR ',
        xAxisTitle: 'Срок РЕПО',
        curveGroups: [
            {
                id: 1,
                label: 'Индикаторы',
                value: 'indicators',
                curves: [
                    { id: 1, label: 'RUB', value: 'RUB_RUSFAR' },
                    { id: 2, label: 'CNY', value: 'CNY_RUSFAR' },
                ],
            },
        ],
    },
    {
        id: 3,
        label: 'СПФИ',
        value: 'spfi',
        curveTitle: 'Кривая ',
        groupTitle: 'Типы кривых ',
        xAxisTitle: 'Срок',
        curveGroups: [
            {
                id: 1,
                label: 'Форвардные кривые',
                value: 'forward_curves',
                curves: [
                    { id: 1, label: 'CNY_REPO', value: 'CNY_REPO' },
                    { id: 2, label: 'CNY_RUSFAR', value: 'CNY_RUSFAR' },
                    { id: 3, label: 'EUR_ESTR', value: 'EUR_ESTR' },
                    { id: 4, label: 'EUR_EURIBOR', value: 'EUR_EURIBOR' },
                    { id: 5, label: 'EUR_EURIBOR_1M', value: 'EUR_EURIBOR_1M' },
                    { id: 6, label: 'EUR_EURIBOR_3M', value: 'EUR_EURIBOR_3M' },
                    { id: 7, label: 'EUR_EURIBOR_6M', value: 'EUR_EURIBOR_6M' },
                    { id: 8, label: 'RUB_KEYRATE', value: 'RUB_KEYRATE' },
                    { id: 9, label: 'RUB_MOSPRIME', value: 'RUB_MOSPRIME' },
                    { id: 10, label: 'RUB_MOSPRIME_1M', value: 'RUB_MOSPRIME_1M' },
                    { id: 11, label: 'RUB_MOSPRIME_3M', value: 'RUB_MOSPRIME_3M' },
                    { id: 12, label: 'RUB_MOSPRIME_6M', value: 'RUB_MOSPRIME_6M' },
                    { id: 12, label: 'RUB_OISFX', value: 'RUB_OISFX' },
                    { id: 13, label: 'RUB_RUONIA', value: 'RUB_RUONIA' },
                    { id: 14, label: 'RUB_RUSFAR', value: 'RUB_RUSFAR' },
                    { id: 15, label: 'RUB_RUSFAR_3M', value: 'RUB_RUSFAR_3M' },
                    { id: 16, label: 'USD_LIBOR', value: 'USD_LIBOR' },
                    { id: 17, label: 'USD_LIBOR_1M', value: 'USD_LIBOR_1M' },
                    { id: 18, label: 'USD_LIBOR_3M', value: 'USD_LIBOR_3M' },
                    { id: 19, label: 'USD_LIBOR_6M', value: 'USD_LIBOR_6M' },
                    { id: 20, label: 'USD_SOFR', value: 'USD_SOFR' },
                ],
            },
            {
                id: 2,
                label: 'Дисконтные кривые',
                value: 'discount_curves',
                curves: [
                    { id: 1, label: 'CNY_CNY', value: 'CNY_CNY' },
                    { id: 2, label: 'CNY_EUR', value: 'CNY_EUR' },
                    { id: 3, label: 'CNY_RUB', value: 'CNY_RUB' },
                    { id: 4, label: 'CNY_USD', value: 'CNY_USD' },
                    { id: 5, label: 'EUR_CHF', value: 'EUR_CHF' },
                    { id: 6, label: 'EUR_CNY', value: 'EUR_CNY' },
                    { id: 7, label: 'EUR_EUR', value: 'EUR_EUR' },
                    { id: 8, label: 'EUR_RUB', value: 'EUR_RUB' },
                    { id: 9, label: 'EUR_USD', value: 'EUR_USD' },
                    { id: 10, label: 'RUB_CHF', value: 'RUB_CHF' },
                    { id: 11, label: 'RUB_CNY', value: 'RUB_CNY' },
                    { id: 12, label: 'RUB_EUR', value: 'RUB_EUR' },
                    { id: 13, label: 'RUB_RUB', value: 'RUB_RUB' },
                    { id: 14, label: 'RUB_USD', value: 'RUB_USD' },
                    { id: 15, label: 'USD_CHF', value: 'USD_CHF' },
                    { id: 16, label: 'USD_CNY', value: 'USD_CNY' },
                    { id: 17, label: 'USD_EUR', value: 'USD_EUR' },
                    { id: 18, label: 'USD_RUB', value: 'USD_RUB' },
                    { id: 19, label: 'USD_USD', value: 'USD_USD' },
                ],
            },

            {
                id: 3,
                label: 'СВОП-кривые',
                value: 'swap_prices',
                curves: [
                    { id: 1, label: 'CNY/RUB', value: 'CNY_RUB' },
                    { id: 2, label: 'EUR/RUB', value: 'EUR_RUB' },
                    { id: 3, label: 'EUR/USD', value: 'EUR_USD' },
                    { id: 4, label: 'USD/CHF', value: 'USD_CHF' },
                    { id: 5, label: 'USD/CNY', value: 'USD_CNY' },
                    { id: 6, label: 'USD/RUB', value: 'USD_RUB' },
                ],
            },
            {
                id: 4,
                label: 'Ставки',
                value: 'rates',
                curves: [
                    { id: 1, label: 'CNY_FRA', value: 'CNY_FRA' },
                    { id: 2, label: 'CNY_IRS', value: 'CNY_IRS' },
                    { id: 3, label: 'CNY_RUSFAR_OIS', value: 'CNY_RUSFAR_OIS' },
                    { id: 4, label: 'EUR_ESTR_OIS', value: 'EUR_ESTR_OIS' },
                    { id: 5, label: 'EUR_FRA', value: 'EUR_FRA' },
                    { id: 6, label: 'EUR_IRS', value: 'EUR_IRS' },
                    { id: 7, label: 'EUR_USD_XCCY3L', value: 'EUR_USD_XCCY3L' },
                    { id: 8, label: 'RUB_FRA', value: 'RUB_FRA' },
                    { id: 9, label: 'RUB_IRS3M', value: 'RUB_IRS3M' },
                    { id: 10, label: 'RUB_KEYRATE_IRS', value: 'RUB_KEYRATE_IRS' },
                    { id: 11, label: 'RUB_OIS', value: 'RUB_OIS' },
                    { id: 12, label: 'RUB_RUSFAR3M_FRA', value: 'RUB_RUSFAR3M_FRA' },
                    { id: 13, label: 'RUB_RUSFAR3M_IRS', value: 'RUB_RUSFAR3M_IRS' },
                    { id: 14, label: 'RUB_RUSFAR_OIS', value: 'RUB_RUSFAR_OIS' },
                    { id: 15, label: 'RUB_XCCY3L', value: 'RUB_XCCY3L' },
                    { id: 16, label: 'USD_FRA', value: 'USD_FRA' },
                    { id: 17, label: 'USD_IRS3L', value: 'USD_IRS3L' },
                    { id: 18, label: 'USD_SOFR_OIS', value: 'USD_SOFR_OIS' },
                    { id: 19, label: 'RUB_KEYRATE_1600', value: 'RUB_KEYRATE_1600' },
                    { id: 20, label: 'RUB_KEYRATE_1800', value: 'RUB_KEYRATE_1800' },
                    { id: 21, label: 'RUB_RUONIA_1600', value: 'RUB_RUONIA_1600' },
                    { id: 22, label: 'RUB_RUONIA_1800', value: 'RUB_RUONIA_1800' },
                ],
            },
        ],
    },
];

export const firstDateWithData = new Date('2023-01-01');

export const formatDateStr = 'yyyy-MM-dd';

export const initSurfaceEndDate = format(new Date(), formatDateStr);

export const initSurfaceStartDate = format(sub(new Date(), { years: 1 }), formatDateStr);

export const lastDate = format(endOfYesterday(), formatDateStr);
