import { DataTable, type DataTableValueArray } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useState } from 'react';
import { PaginationController } from '@libs/components';

import styles from './styles.module.scss';
import { sectorReference, tickerReference } from './screener.model';
import { ReactComponent as SortIcon } from '../../../../shared/images/svg/Sort.svg';
import { costFormat } from '../../../../shared/utils/format.util';
import { IsFavourite } from './components/IsFavourite';
import { IsFavouriteHeader } from './components/IsFavouriteHeader';
import { type ScreenerRowType } from '../../services/types';

type ScreenerPagePropsType = {
    data: ScreenerRowType[];
    onSetIsFavourite: (event: { id: string; isFavourite: boolean }) => void;
};

const LENGTH_OF_TABLE_PAGE = 15;

export const ScreenerPage = ({ data, onSetIsFavourite }: ScreenerPagePropsType) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [dataForCurrentPage, setDataForCurrentPage] = useState<DataTableValueArray>(getDataForCurrentPage(0));

    function getDataForCurrentPage(currentPage: number): DataTableValueArray {
        return [...data].splice(currentPage * LENGTH_OF_TABLE_PAGE, LENGTH_OF_TABLE_PAGE);
    }

    function handleChangeCurrentPage(newCurrentPage: number) {
        setCurrentPage(newCurrentPage);
        setDataForCurrentPage(getDataForCurrentPage(newCurrentPage));
    }

    // region Templates
    // region Name templates
    const nameBodyTemplate = (item: ScreenerRowType) => (
        <div>{tickerReference.find((ticker) => ticker.id === item.ticker)?.title ?? ''}</div>
    );
    // endregion Name Templates

    // region Capitalization templates
    const capitalizationBodyTemplate = (item: ScreenerRowType) => (
        <div className={'align-right'}>
            <span>{item.capitalization}</span>&nbsp;
            <span className={styles.currency}>RUB</span>
        </div>
    );
    // endregion Capitalization templates

    // region LastCost templates
    const lastCostBodyTemplate = (item: ScreenerRowType) => (
        <div className={'align-right'}>
            <span>{costFormat(item.lastCost)}</span>&nbsp;
            <span className={styles.currency}>RUB</span>
        </div>
    );
    // endregion LastCost templates

    // region Delta templates
    const deltaBodyTemplate = (item: ScreenerRowType) => (
        <div className={'align-right'}>
            <span className={item.delta >= 0 ? styles.positiveNumber : styles.negativeNumber}>
                {costFormat(item.delta)}
            </span>
        </div>
    );
    // endregion Delta templates

    // region Section templates
    const sectorBodyTemplate = (item: ScreenerRowType) => (
        <div>{sectorReference.find((sector) => sector.id === item.sector)?.title}</div>
    );
    // endregion Section templates

    // endregion tradingVolume templates
    const tradingVolumeBodyTemplate = (item: ScreenerRowType) => (
        <div className={'align-right'}>
            <span>{item.tradingVolume}</span>&nbsp;
            <span className={styles.currency}>RUB</span>
        </div>
    );
    // endregion tradingVolume templates

    // endregion tradingVolume templates

    const dividendYieldBodyTemplate = (item: ScreenerRowType) => (
        <div className={'align-right'}>{item.dividendYield}%</div>
    );
    // endregion tradingVolume templates

    // endregion isFavourite templates
    const isFavoriteBodyTemplate = (item: ScreenerRowType) => (
        <IsFavourite data={item} onSetIsFavourite={onSetIsFavourite} />
    );
    // endregion isFavourite templates

    // endregion Templates

    return (
        <>
            <DataTable
value={dataForCurrentPage} stripedRows sortIcon={<SortIcon />}
className={styles.table}>
                <Column
field={'ticker'} header={'Тикер'} sortable
className={styles.tickerColumn} />
                <Column
body={nameBodyTemplate} header={'Название'} sortable
className={styles.nameColumn} />
                <Column
                    body={capitalizationBodyTemplate}
                    header={'Капитализация'}
                    sortable
                    className={styles.capitalizationColumn}
                />
                <Column
                    body={lastCostBodyTemplate}
                    header={'Цена последней сделки'}
                    sortable
                    className={styles.lastCostColumn}
                />
                <Column
body={deltaBodyTemplate} header={'Изменение за 1д'} sortable
className={styles.deltaColumn} />
                <Column
                    body={tradingVolumeBodyTemplate}
                    header={'Объем торгов'}
                    sortable
                    className={styles.tradingVolumeColumn}
                />
                <Column
                    body={dividendYieldBodyTemplate}
                    header={'Дивидендная доходность'}
                    sortable
                    className={styles.dividendYieldColumn}
                />
                <Column body={sectorBodyTemplate} header={'Сектор'} sortable />
                <Column body={isFavoriteBodyTemplate} header={IsFavouriteHeader} className={styles.isFavouriteColumn} />
            </DataTable>
            <div className="mt-3">
                <PaginationController
                    total={Math.ceil(data.length / 15)}
                    current={currentPage}
                    setAsyncCurrent={handleChangeCurrentPage}
                />
            </div>
        </>
    );
};
