import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import { type RefObjectType } from '@libs/types';
import { ModalWindow } from '@libs/components';
import { useModal } from '@libs/utils';

import { ContactRequestForm } from './components/ContactRequestForm';
import { ConfirmContactRequestModal } from './components/ConfirmContactRequestModal';
import styles from './styles.module.scss';

type Props = {
    setRefs: (refs: RefObjectType<HTMLDivElement>) => void;
    telephoneNumber?: string;
    sendEmail?: boolean;
    productGuid?: string;
};

export const GainAccess = ({
    setRefs,
    telephoneNumber = '+7 (495) 363-32-32 доб. 5656',
    sendEmail = true,
    productGuid,
}: Props) => {
    const { closeModal, openModal, isOpen } = useModal();

    const gainAccessRef = useRef<HTMLDivElement>(null);

    const openRequestForm = useCallback(() => {
        openModal();
    }, []);

    useEffect(() => {
        setRefs({ gainAccessRef });
    }, [setRefs]);

    return (
        <section ref={gainAccessRef} className={classNames(styles.gainAccessWrapper, 'flex')}>
            <div className={classNames(styles.titleContainer)}>
                <h2>Получить доступ</h2>
                <p>
                    Отправьте заявку. Наш менеджер свяжется с Вами в ближайшее время.
                    <br />
                    <br />
                    Так же вы можете связаться с нами по телефону:
                </p>
                <span>{telephoneNumber}</span>
            </div>

            <div className={classNames(styles.formWrapper)}>
                <ContactRequestForm onSubmit={openRequestForm} sendEmail={sendEmail} productGuid={productGuid} />
            </div>
            <ModalWindow isVisible={isOpen} closeModal={closeModal}>
                <ConfirmContactRequestModal onClick={closeModal} />
            </ModalWindow>
        </section>
    );
};
