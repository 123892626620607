import { Button } from 'primereact/button';
import { ReactComponent as ProPackLogo } from '@shared/images/propack/svg/logo.svg';
import { ReactComponent as StarIcon } from '@shared/images/propack/svg/star.svg';
import { ReactComponent as PlayIcon } from '@shared/images/svg/playBlack.svg';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { type RefObjectType } from '@libs/types';
import { scrollElementByRef, useAuthorize } from '@libs/utils';

import styles from './styles.module.scss';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
};

export const LogoBlock = ({ refs }: Props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuthorized, loginHandler } = useAuthorize();

    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return (
        <section className={styles.logoBlockWrapper}>
            <div className={styles.contentWrapper}>
                <ProPackLogo />
                <p>
                    Сервис анализа временной структуры процентных ставок, включающий в себя семейство кривых на
                    различных рынках Московской Биржи:
                </p>
                <ul>
                    <li>Рынок облигаций – кривая бескупонной доходности</li>
                    <li>Рынок РЕПО – индикаторы RUSFAR</li>
                    <li>Рынок СПФИ – дисконтные и форвардные кривые</li>
                </ul>
                <div className={classNames('flex')}>
                    <Button type="button" onClick={() => handleButtonClick('gainAccessRef')}>
                        <StarIcon />
                        <span>Получить доступ</span>
                    </Button>
                    <Button
                        onClick={() => (isAuthorized ? navigate(location.pathname + '/example', {}) : loginHandler())}
                    >
                        <PlayIcon />
                        <span>Пробная версия</span>
                    </Button>
                </div>
            </div>
        </section>
    );
};
