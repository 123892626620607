import classNames from 'classnames';

import styles from './styles.module.scss';

export type ApiStringProps = {
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    url: string;
};

export const ApiString = ({ method, url }: ApiStringProps) => {
    return (
        <div className={classNames('flex', 'align-items-center', styles.container)}>
            <div className={classNames('flex', 'justify-content-center', 'align-items-center')}>
                <span>{method}</span>
            </div>
            <p>{url}</p>
        </div>
    );
};
