import classNames from 'classnames';
import { Footer, Navigation } from '@libs/components';
import { Button } from 'primereact/button';
import { useAuthorize } from '@libs/utils';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

export const LinkNotValidPage = () => {
    const navigate = useNavigate();
    const { isAuthorized, loginHandler } = useAuthorize();

    return (
        <>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <div className={classNames('flex', 'flex-column', styles.linkNotValidPageWrapper)}>
                        <div className={styles.pageContent}>
                            <div className={styles.textBlock}>
                                <h1>ссылка недействительна</h1>
                                <span>Ссылка подтверждения почты больше не действительна.</span>
                            </div>
                            <Button
                                label={'Вернуться'}
                                onClick={() => (isAuthorized ? navigate('/personal-account') : loginHandler())}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
