import classNames from 'classnames';
import { Button } from 'primereact/button';

import { apiListData } from './data';
import { ApiString } from '../ApiString';
import styles from './styles.module.scss';

export const APIList = () => {
    return (
        <div className={classNames(styles.APIList)}>
            <p>
                [ Base URL: <a>https://iss.moex.com/iss</a> ]
            </p>
            {apiListData.map(({ url, method }) => (
                <ApiString key={method + url} method={method} url={url} />
            ))}
            <Button
                onClick={() => {
                    window.open('https://moexalgo.github.io/');
                }}
                label={'Документация'}
            />
        </div>
    );
};
