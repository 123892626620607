import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlgoPackRow } from "@modules/Algopack/AlgopackPage/services/types";
import { getAllSubscriptionsThunk, subscribeThunk } from "../thunk";

export type IState = {
  data: AlgoPackRow[] | null;
  isSubscribed: boolean;
  isLoading: boolean;
  error: any;
};

export const initialState: IState = {
  data: null,
  isSubscribed: false,
  isLoading: false,
  error: {},
};

export const algopackSlice = createSlice({
  name: "algopack",
  initialState,
  reducers: {
    updateIsSubscribedOnExit(state) {
      state.isSubscribed = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubscriptionsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSubscriptionsThunk.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.data = action.payload?.data;
        state.isSubscribed = state.data?.some((item) => item.productGuid === action.payload.productId) || false;
      })
      .addCase(getAllSubscriptionsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(subscribeThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(subscribeThunk.fulfilled, (state) => {
        state.isLoading = false;
        state.isSubscribed = true;
      })
      .addCase(subscribeThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const algopackReducer = algopackSlice.reducer;
export const { updateIsSubscribedOnExit } = algopackSlice.actions;

