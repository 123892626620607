import { httpClient } from '@configs/httpclient.config';
import { AxiosResponse } from 'axios';
import { Product, ProductReq, ProductRes } from '../types/product.type';
import { ResponseInfo } from '@libs/types';

export const PRODUCT_URL = '/moex-datashop-productservice/api/product/v1/';

export const getProductList = (body: ProductReq): Promise<AxiosResponse<ProductRes>> =>
    httpClient.post(PRODUCT_URL + 'find', body);

export const getProductByGuid = (productGuid: string): Promise<AxiosResponse<ResponseInfo<Product>>> =>
    httpClient.get(`${PRODUCT_URL}get-by-guid/${productGuid}`);
