import { PlotlyCoordsObj } from '@modules/ProPack/components/YieldCurves/types';
import { ChartData } from 'chart.js';

type LabelAxisType = 'xAxis' | 'yAxis';

export const plotlyToChartjsCoordsMapper = (
    plotlyObj: Partial<PlotlyCoordsObj | any>,
    labelAxis: LabelAxisType,
): Partial<ChartData> => {
    const isXAxisLabel = labelAxis === 'xAxis';
    const mainCoordsArr = isXAxisLabel ? plotlyObj.x : plotlyObj.y;
    const label = isXAxisLabel ? plotlyObj.y?.[0] : plotlyObj.x?.[0];

    return {
        datasets: [
            {
                label: label,
                data: mainCoordsArr.map((coordValue: any, index: number): any => ({
                    x: coordValue,
                    y: plotlyObj?.z?.[index],
                })),
                borderColor: plotlyObj?.line?.color,
                pointHoverBackgroundColor: plotlyObj?.line?.color,
            },
        ],
    };
};

export const plotlyToSpfiChartjsCoordsMapper = (
    plotlyObj: Partial<PlotlyCoordsObj | any>,
    labelAxis: LabelAxisType,
): Partial<ChartData> => {
    const isXAxisLabel = labelAxis === 'xAxis';
    const mainCoordsArr = isXAxisLabel ? plotlyObj.x : plotlyObj.y;
    return {
        datasets: [
            {
                label: plotlyObj.curveName,
                data: mainCoordsArr.map((coordValue: any, index: number): any => ({
                    x: coordValue,
                    y: plotlyObj?.z?.[index],
                })),
                borderColor: plotlyObj?.line?.color,
                tension: 0.4,
                pointRadius: 0,
                pointHoverBackgroundColor: plotlyObj?.line?.color,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 2,
                clip: false,
            },
        ],
    };
};
