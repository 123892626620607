import classNames from 'classnames';
import { ReactComponent as PlusIcon } from '@shared/images/algopack/plusIcon.svg';
import { ReactComponent as CrossIcon } from '@shared/images/algopack/crossIcon.svg';
import PythonExample from '@shared/images/algopack/PythonExample.png';
import { useResize, useAuthorize } from '@libs/utils';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import type { RootState } from '@store/store';

import { APIList } from './APIList';
import styles from './styles.module.scss';

export const ApiData = () => {
    const [openedTabs, setOpenedTabs] = useState<number[]>([]);
    const addTab = (tab: number) => setOpenedTabs([...openedTabs, tab]);
    const closeTab = (tab: number) => setOpenedTabs(openedTabs.filter((tb) => tb !== tab));
    const { width } = useResize();

    const { isAuthorized } = useAuthorize();
    const isSubscribed = useSelector((state: RootState) => state.algopack.isSubscribed);

    const isTabOpen = (tab: number) => openedTabs.includes(tab);

    const accTabPt = {
        header: {
            className: styles.accordionHeader,
        },
        content: {
            className: styles.accordionContent,
        },
    };

    return (
        <section
            className={classNames(
                'flex',
                styles.apiDataSection,
                'justify-content-between',
                'flex-row',
                width < 1024 && 'flex-column',
            )}
        >
            <h4>Данные ALGOPACK можно получать через REST API и Python клиент</h4>
            <Accordion
                multiple={true}
                className={classNames(styles.apiContainer)}
                onTabClose={(e) => closeTab(e.index)}
                onTabOpen={(e) => addTab(e.index)}
            >
                <AccordionTab
                    pt={accTabPt}
                    children={<APIList />}
                    header={() => {
                        return (
                            <div
                                className={classNames(
                                    'flex',
                                    'justify-content-between',
                                    styles.apiItem,
                                    isTabOpen(0) && styles.open,
                                )}
                            >
                                <h6>REST API</h6>
                                {isTabOpen(0) ? <CrossIcon /> : <PlusIcon />}
                            </div>
                        );
                    }}
                />
                <AccordionTab
                    pt={accTabPt}
                    children={
                        <>
                            <img className={styles.pythonImage} src={PythonExample} />
                            <Button
                                onClick={() => window.open('https://pypi.org/project/moexalgo/')}
                                className={styles.blackButton}
                                label={'Библиотека'}
                            />
                        </>
                    }
                    header={
                        <div
                            className={classNames(
                                'flex',
                                'justify-content-between',
                                styles.apiItem,
                                isTabOpen(1) && styles.open,
                            )}
                        >
                            <h6>PYTHON</h6>
                            {isTabOpen(1) ? <CrossIcon /> : <PlusIcon />}
                        </div>
                    }
                />
            </Accordion>
        </section>
    );
};
