import { PythonIcon, TelegramIcon } from '@shared';
import DocumentIcon from '@shared/images/algopack/documentIcon.svg';
import React from 'react';
import classNames from 'classnames';
import { useAuthorize } from '@libs/utils';

import { ContactArticle } from './ContactArticle';
import styles from './style.module.scss';

export const Links = () => {
    const { isAuthorized } = useAuthorize();

    return (
        <section className={classNames(styles.linkSection, 'flex')}>
            <h2>Ссылки</h2>
            <div className={classNames('flex')}>
                <ContactArticle
                    title={'Документация'}
                    text={'Полное описание возможностей и данных ALGOPACK вы можете найти в документации'}
                    buttonText={'Смотреть'}
                    svg={DocumentIcon}
                    link={'https://moexalgo.github.io/'}
                    blankLink
                />
                <ContactArticle
                    title={'Python библиотека'}
                    text={'Для упрощения работы с AlgoPack API разработана Python библиотека'}
                    buttonText={'Подключить'}
                    link={'https://pypi.org/project/moexalgo/'}
                    blankLink
                    svg={PythonIcon}
                />
                <ContactArticle
                    title={'ALGO PACK в Telegram'}
                    text={'Вопросы по продуктам ALGO PACK и общение в сообществе алготрейдеров'}
                    buttonText={'@moex_algopack'}
                    link={process.env.REACT_APP_MOEXALGO_TELEGRAM}
                    blankLink
                    svg={TelegramIcon}
                />
            </div>
        </section>
    );
};
