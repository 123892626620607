import { createSlice } from "@reduxjs/toolkit";
import { type Cart } from "@libs/types";
import {
    deleteCartItemThunk,
    getCartCountThunk,
    getCartThunk,
    updateCartItemThunk
} from "@store/store/thunk/cart.thunk";

type IState = {
    cartCount: number;
    cart: Cart | null;
};

export const initialState: IState = {
    cartCount: 0,
    cart: null,
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCartCountThunk.fulfilled, (state, action) => {
            state.cartCount = action.payload!;
        });
        builder.addCase(getCartThunk.fulfilled, (state, action) => {
            state.cart = action.payload!;
        });
        builder.addCase(deleteCartItemThunk.fulfilled, (state, action) => {
            state.cart = action.payload!;
        });
        builder.addCase(updateCartItemThunk.fulfilled, (state, action) => {
            state.cart = action.payload!;
        });
    },
});

export const cartReducer = cartSlice.reducer;
