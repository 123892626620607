import { httpClient } from '@configs/httpclient.config';
import { AxiosResponse } from 'axios';
import { FutoiReqBody, FutoiResponseBody, GraphRoot, LongShortRoot, PersonType } from './types';

export const SUB_PRICE_URL = '/moex-propack-integrationservice/api/v1/graph/priceContract';
export const SUB_LONG_SHORT_URL = '/moex-propack-integrationservice/api/v1/graph/longShortPosition';
export const FUTOI_URL = '/moex-propack-integrationservice/api/v1/graph/priceContractAndLongShortPosition';

const getPriceContract = (date: string): Promise<AxiosResponse<GraphRoot>> =>
    httpClient.post(SUB_PRICE_URL, {
        graph_name: 'futoi',
        date,
    });

const getLongShortPostions = (personType: PersonType, date: string): Promise<AxiosResponse<LongShortRoot>> =>
    httpClient.post(SUB_LONG_SHORT_URL, {
        graph_name: 'futoi',
        person_type: personType,
        date,
    });

const getFutouiData = ({ date, graph_name = 'futoi' }: FutoiReqBody): Promise<AxiosResponse<FutoiResponseBody>> =>
    httpClient.post(FUTOI_URL, {
        date,
        graph_name,
    });
export { getPriceContract, getLongShortPostions, getFutouiData };
