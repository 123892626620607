import classNames from 'classnames';
import { Carousel } from '@libs/components';

import { images } from './data';
import styles from './styles.module.scss';

export const Hackathon = () => {
    return (
        <section className={classNames(styles.hackathonSection, 'flex')}>
            <div className={classNames(styles.hackathonInfo)}>
                <h2>Хакатон Go Algo</h2>
                <p>
                    В рамках запуска <b>ALGOPACK</b> Московская биржа провела хакатон GO ALGO, в рамках которого
                    участники создавали торговые алгоритмы и разрабатывали сервисы на основе данных <b>ALGOPACK</b>.
                </p>
                <p>
                    Хакатон стал отличной возможностью для участников применить на практике свои знания и компетенции, а
                    также открыть для себя новые перспективы инвестиционного рынка.
                </p>
            </div>
            <div>
                <Carousel images={images} />
            </div>
        </section>
    );
};
