import { functionalList } from '@modules/Investorpro/modules/LandingPage/components/InvestorFunctional/data';
import { FunctionalItem } from '@modules/Investorpro/modules/LandingPage/components/InvestorFunctional/FunctionalItem';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const InvestorFunctional = () => {
    return (
        <section className={classNames(styles.investorFunctionalWrapper, 'flex', 'flex-column')}>
            {functionalList.map((item, index) => (
                <FunctionalItem key={item.title} imagePosition={index % 2 ? 'right' : 'left'} {...item} />
            ))}
        </section>
    );
};
