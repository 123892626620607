import { MapsContainer } from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer';
import { FiltersContainer } from '@modules/PriceCenterProduct/InteractiveMap/FilterContainer';

import styles from './styles.module.scss';

export const InteractiveMap = () => {
    return (
        <div className={styles.interactiveContainer}>
            <MapsContainer />
            <FiltersContainer />
        </div>
    );
};
