import React from 'react';
import { Feedback, Footer, Navigation } from '@libs/components';

import { Header } from './components/Header';
import { ProductsList } from './components/ProductsList';

export const CartPage = () => {
    return (
        <>
            <Navigation />
            <Header />
            <ProductsList />
            <Feedback />
            <Footer />
        </>
    );
};
