import { type CapabilityItem as Props } from '@modules/PriceCenter/ProductCapabilities/data';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const CapabilityItem = ({ logo, title, description }: Props) => {
    return (
        <div className={classNames(styles.capabilityItemWrapper, 'flex')}>
            <img src={logo} alt={title} />
            <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
        </div>
    );
};
