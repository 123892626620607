import { TabPanel, type TabPanelHeaderTemplateOptions, TabView, type TabViewTabChangeEvent } from 'primereact/tabview';
import classNames from 'classnames';

import styles from './styles.module.scss';

type TabsComponentType = {
    activeIndex: number;
    changeTab: (index: number) => void;
};

const tabs = [
    { name: 'Покупка', isNewMessage: false },
    { name: 'Продление', isNewMessage: true },
];

const tabHeaderTemplate = (
    options: TabPanelHeaderTemplateOptions,
    name: string,
    isNewMessage: boolean,
    activeIndex: number,
) => {
    return (
        <p
            onClick={options.onClick}
            className={classNames(
                styles.headerTemplate,
                isNewMessage && styles.withNew,
                options.index === activeIndex && styles.active,
            )}
        >
            {name}
        </p>
    );
};

export const Tabs = ({ activeIndex, changeTab }: TabsComponentType) => {
    return (
        <TabView
            pt={{
                inkbar: () => ({
                    className: styles.tabInkBar,
                }),
            }}
            onTabChange={(e: TabViewTabChangeEvent) => changeTab(e.index)}
            activeIndex={activeIndex}
            className={styles.tabViewContainer}
        >
            {tabs.map(({ name, isNewMessage }) => {
                return (
                    <TabPanel
                        className={classNames(styles.tabPanel)}
                        key={name}
                        headerTemplate={(options) => tabHeaderTemplate(options, name, isNewMessage, activeIndex)}
                    />
                );
            })}
        </TabView>
    );
};
