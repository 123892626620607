import { Navigation } from '@libs/components';
import { useSearchParams } from 'react-router-dom';
import React from 'react';
import { Footer } from '@modules/Investorpro/shared/components/Footer';
import { Header } from '@modules/PriceCenterProduct/Header';
import { InteractiveMap } from '@modules/PriceCenterProduct/InteractiveMap';
import { DataUpload } from '@modules/PriceCenterProduct/DataUpload';
import { ApiDescription } from '@modules/PriceCenterProduct/ApiDescription';

export const PriceCenterProduct = () => {
    const [searchParams] = useSearchParams();

    return (
        <div>
            <Navigation>
                <Header />
            </Navigation>
            {searchParams.get('tab') === 'interactiveMap' && <InteractiveMap />}
            {searchParams.get('tab') === 'dataUpload' && <DataUpload />}
            {searchParams.get('tab') === 'apiDescription' && <ApiDescription />}
            <Footer />
        </div>
    );
};
