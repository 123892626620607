import { ReactComponent as MainLogo } from '@modules/Investorpro/shared/images/svg/InvestorProLogoNew.svg';
import { ReactComponent as StarIcon } from '@shared/images/svg/star.svg';
import { Button } from 'primereact/button';
import { useModal } from '@libs/utils';
import { InfoModal } from '@modules/Investorpro/shared/components/InfoModal';

import styles from './styles.module.scss';

export const Header = () => {
    const { closeModal, openModal, isOpen } = useModal();

    return (
        <section className={styles.headerWrapper}>
            <div className={styles.contentWrapper}>
                <div className={styles.logoWrapper}>
                    <MainLogo />
                </div>
                <p>Аналитический сервис для работы с инвестиционными портфелями</p>
                <Button
                    className={'flex align-items-end'}
                    icon={<StarIcon />}
                    label={'Получить доступ'}
                    onClick={openModal}
                />
            </div>
            <InfoModal isOpen={isOpen} closeModal={closeModal} />
        </section>
    );
};
