export const INVESTORPRO_MOCK = {
  screeners: [
    {
      id: 1,
      title: "IMOEX",
    },
    {
      id: 2,
      title: "Мой скринер",
    },
  ],
  screener1: {
    id: 1,
    data: [
      {
        id: 1,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "3.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 2,
        ticker: "GAZP",
        capitalization: "5.866Т",
        lastCost: 1266,
        delta: 0.38,
        tradingVolume: "2.866Т",
        dividendYield: 6.68,
        sector: "finance",
        isFavourite: true,
      },
      {
        id: 3,
        ticker: "SNGS",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "1.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 4,
        ticker: "MAGN",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "0.866Т",
        dividendYield: 6.68,
        sector: "mineralResources",
      },
      {
        id: 5,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 6,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "mineralResources",
      },
      {
        id: 7,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 8,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 9,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 10,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 11,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 12,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 13,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 14,
        ticker: "GAZP",
        capitalization: "5.006Т",
        lastCost: 1066,
        delta: 2.38,
        tradingVolume: "5.866Т",
        dividendYield: 5.68,
        sector: "finance",
      },
      {
        id: 15,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 14,
        ticker: "GAZP",
        capitalization: "5.006Т",
        lastCost: 1066,
        delta: 2.38,
        tradingVolume: "5.866Т",
        dividendYield: 5.68,
        sector: "finance",
        isFavourite: true,
      },
      {
        id: 14,
        ticker: "GAZP",
        capitalization: "5.006Т",
        lastCost: 1066,
        delta: 2.38,
        tradingVolume: "5.866Т",
        dividendYield: 5.68,
        sector: "finance",
      },
    ],
  },
  screener2: {
    id: 2,
    data: [
      {
        id: 1,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 66,
        delta: 1.38,
        tradingVolume: "3.866Т",
        dividendYield: 4.68,
        sector: "finance",
        isFavourite: true,
      },
      {
        id: 2,
        ticker: "GAZP",
        capitalization: "5.866Т",
        lastCost: 1266,
        delta: 0.38,
        tradingVolume: "2.866Т",
        dividendYield: 6.68,
        sector: "finance",
        isFavourite: true,
      },
      {
        id: 3,
        ticker: "SNGS",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "1.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 4,
        ticker: "MAGN",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "0.866Т",
        dividendYield: 6.68,
        sector: "mineralResources",
      },
      {
        id: 5,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 6,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "mineralResources",
      },
      {
        id: 7,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 8,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 9,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 10,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 11,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 12,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 13,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 14,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
      {
        id: 15,
        ticker: "SBER",
        capitalization: "5.866Т",
        lastCost: 1066,
        delta: -2.38,
        tradingVolume: "5.866Т",
        dividendYield: 6.68,
        sector: "finance",
      },
    ],
  },
};
