import { ReactComponent as PriceCenterLogo } from '@shared/images/priceCenter/svg/PriceCenterLogo.svg';
import { ReactComponent as StarIcon } from '@shared/images/svg/star.svg';
import { ReactComponent as PlayBlackIcon } from '@shared/images/svg/playBlack.svg';
import { ReactComponent as PlayWhiteIcon } from '@shared/images/svg/playWhite.svg';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { scrollElementByRef, useAuthorize } from '@libs/utils';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '@store/store';
import { useEffect, useState } from 'react';
import { checkSubscribePossibilityThunk } from '@store/store/thunk/subscribe.thunk';
import { useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { type RefObjectType } from '@libs/types';

import styles from './styles.module.scss';

type Props = {
    refs: RefObjectType<HTMLDivElement>;
};

export const Header = ({ refs }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { isAuthorized, loginHandler } = useAuthorize();

    const [isSubscribed, setIsSubscribed] = useState<boolean>(false);

    useEffect(() => {
        dispatch(checkSubscribePossibilityThunk(process.env.REACT_APP_PRICECENTER_GUID!))
            .then((resp) => {
                const result = unwrapResult(resp);

                if (result) {
                    setIsSubscribed(!result.canSubscribe);
                }
            })
            .catch((e) => console.error(e));
    }, []);

    const handleButtonClick = (refName: string) => {
        const ref = refs[refName];

        if (ref && ref.current) {
            scrollElementByRef(ref);
        }
    };

    return (
        <div className={styles.headerWrapper}>
            <div className={styles.contentWrapper}>
                <PriceCenterLogo />
                <p>Мы делаем процесс оценки стоимости облигаций простым и удобным</p>
                <div className={classNames(styles.buttonWrapper, 'flex')}>
                    {isAuthorized && isSubscribed
? (
                        <Button
                            className={classNames(styles.redBtn)}
                            label={'Открыть'}
                            icon={<PlayWhiteIcon />}
                            onClick={() => navigate('product?tab=interactiveMap')}
                        />
                    )
: (
                        <>
                            <Button
                                className={classNames(styles.redBtn)}
                                label={'Выбрать тариф'}
                                icon={<StarIcon />}
                                onClick={() => handleButtonClick('tariffsRef')}
                            />
                            <Button
                                className={classNames(styles.whiteBtn)}
                                label={'Пробная версия'}
                                icon={<PlayBlackIcon />}
                                onClick={() => (isAuthorized ? navigate('product?tab=interactiveMap') : loginHandler())}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
