import { Content } from '@modules/PaymentInfoPage/Content';
import { Footer } from '@libs/components';

export const PaymentInfoPage = () => {
    return (
        <>
            <Content />
            <Footer />
        </>
    );
};
