import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { AutoComplete } from 'primereact/autocomplete';
import React, { useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { type PriceCenterFilter } from '@store/store/thunk/pricecenter/Models';
import { parse } from 'date-fns';
import {
    CALC_METHODS_DICT,
    ISSUER_SECTOR_DICT,
    LISTING_LEVEL_DICT,
    SECTOR_DICT,
    STATUS_DICT,
} from '@store/store/thunk/pricecenter/consts';
import { MultiSelect } from 'primereact/multiselect';
import { InputNumber } from 'primereact/inputnumber';

import styles from './styles.module.scss';

type PriceCenterMainMediumFormProps = {
    openModal: () => void;
    form: UseFormReturn<PriceCenterFilter>;
    isLoading: boolean;
};

export const PriceCenterMainMediumForm = (props: PriceCenterMainMediumFormProps) => {
    const { openModal, form, isLoading } = props;
    const [activeIndexes, setActiveIndexes] = useState<number[]>([0, 1, 2]);

    return (
        <>
            <div className="flex justify-content-between align-items-center gap-2">
                <p className={styles.inputLabel}>Дата актуальности</p>
                <Controller
                    control={form.control}
                    name="tradeDate"
                    render={({ field }) => (
                        <Calendar
                            dateFormat="dd.mm.yy"
                            showIcon
                            className={styles.calendarInput}
                            value={parse(field.value ?? '', 'yyyy-MM-dd', new Date())}
                            disabled
                        />
                    )}
                />
            </div>

            <div className={styles.blockDivider} />

            <Accordion
                multiple
                activeIndex={activeIndexes}
                onTabChange={(e) => setActiveIndexes((e.index ?? []) as number[])}
                className="w-full flex flex-column gap-5"
            >
                <AccordionTab
                    headerTemplate={
                        <div className={styles.blockTitle}>
                            <i className="pi pi-circle pb-1" />
                            <p>Метод расчета</p>
                            <i
                                className={classNames({
                                    'pi pi-chevron-up pb-1 text-sm': activeIndexes.includes(0),
                                    'pi pi-chevron-down pb-1 text-sm': !activeIndexes.includes(0),
                                })}
                            />
                        </div>
                    }
                    pt={ACCORDION_PT}
                >
                    <Controller
                        control={form.control}
                        name="calcMethod"
                        render={({ field }) => (
                            <Dropdown
                                value={field.value}
                                onChange={field.onChange}
                                options={CALC_METHODS_DICT}
                                dataKey="code"
                                optionLabel="name"
                                placeholder="Выбрать"
                                className={classNames(styles.dropdownInput, 'w-full')}
                                disabled={isLoading}
                            />
                        )}
                    />
                    <div className={classNames(styles.blockDivider, 'pt-5')} />
                </AccordionTab>

                <AccordionTab
                    headerTemplate={
                        <div className={styles.blockTitle}>
                            <i className="pi pi-circle pb-1" />
                            <p>Сформировать список</p>
                            <i
                                className={classNames({
                                    'pi pi-chevron-up pb-1 text-sm': activeIndexes.includes(1),
                                    'pi pi-chevron-down pb-1 text-sm': !activeIndexes.includes(1),
                                })}
                            />
                        </div>
                    }
                    pt={ACCORDION_PT}
                >
                    <div className="w-full flex flex-column gap-2">
                        <AutoComplete
                            placeholder="ISIN, название, номер"
                            className={classNames(styles.autoCompleteInput, 'w-full')}
                            dropdown
                            dropdownIcon="pi pi-search"
                        />
                    </div>
                    <div className={classNames(styles.blockDivider, 'pt-5')} />
                </AccordionTab>

                <AccordionTab
                    headerTemplate={
                        <div className={styles.blockTitle}>
                            <i className="pi pi-circle pb-1" />
                            <p>Поиск по параметрам</p>
                            <i
                                className={classNames({
                                    'pi pi-chevron-up pb-1 text-sm': activeIndexes.includes(2),
                                    'pi pi-chevron-down pb-1 text-sm': !activeIndexes.includes(2),
                                })}
                            />
                        </div>
                    }
                    pt={ACCORDION_PT}
                >
                    <div className={styles.blockParamSearch}>
                        <div className="w-full flex justify-content-between align-items-center gap-2">
                            <p className={styles.inputLabel}>Статус</p>
                            <Controller
                                control={form.control}
                                name="status"
                                render={({ field }) => (
                                    <MultiSelect
                                        value={field.value}
                                        onChange={field.onChange}
                                        options={STATUS_DICT}
                                        dataKey="code"
                                        optionLabel="name"
                                        className={styles.blockParamSearchInput}
                                        multiple
                                        disabled={isLoading}
                                    />
                                )}
                            />
                        </div>

                        <div className="w-full flex justify-content-between align-items-center gap-2">
                            <p className={styles.inputLabel}>Листинг</p>
                            <Controller
                                control={form.control}
                                name="listingLevel"
                                render={({ field }) => (
                                    <Dropdown
                                        value={field.value}
                                        onChange={field.onChange}
                                        options={LISTING_LEVEL_DICT}
                                        dataKey="code"
                                        optionLabel="name"
                                        className={styles.blockParamSearchInput}
                                        disabled={isLoading}
                                    />
                                )}
                            />
                        </div>

                        <div className="w-full flex justify-content-between align-items-center gap-2">
                            <p className={styles.inputLabel}>Сектор</p>
                            <Controller
                                control={form.control}
                                name="sector"
                                render={({ field }) => (
                                    <Dropdown
                                        value={field.value}
                                        onChange={field.onChange}
                                        options={SECTOR_DICT}
                                        dataKey="code"
                                        optionLabel="name"
                                        className={styles.blockParamSearchInput}
                                        disabled={isLoading}
                                    />
                                )}
                            />
                        </div>

                        <div className="w-full flex justify-content-between align-items-center gap-2">
                            <p className={styles.inputLabel}>Отрасль</p>
                            <Controller
                                control={form.control}
                                name="issuerSector"
                                render={({ field }) => (
                                    <Dropdown
                                        value={field.value}
                                        onChange={field.onChange}
                                        options={ISSUER_SECTOR_DICT}
                                        dataKey="code"
                                        optionLabel="name"
                                        className={styles.blockParamSearchInput}
                                        disabled={isLoading}
                                    />
                                )}
                            />
                        </div>

                        <div className={styles.profitParam}>
                            <p className={styles.inputLabel}>Доходность (%)</p>
                            <div className="flex justify-content-center align-items-center gap-2">
                                <Controller
                                    control={form.control}
                                    name="minYield"
                                    render={({ field }) => (
                                        <InputNumber
                                            placeholder="от"
                                            value={field.value}
                                            onChange={field.onChange}
                                            inputClassName={styles.profitInput}
                                        />
                                    )}
                                />
                                <i className="pi pi-minus" />
                                <Controller
                                    control={form.control}
                                    name="maxYield"
                                    render={({ field }) => (
                                        <InputNumber
                                            placeholder="до"
                                            value={field.value}
                                            onChange={field.onChange}
                                            inputClassName={styles.profitInput}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className={styles.extendedSearchLabel} onClick={openModal}>
                            Расширенный поиск
                        </div>
                    </div>
                    <div className={classNames(styles.blockDivider, 'pt-5')} />
                </AccordionTab>
            </Accordion>
        </>
    );
};

const ACCORDION_PT = {
    headerIcon: () => ({
        className: 'hidden',
    }),
    headerAction: () => ({
        className: 'bg-white border-none',
    }),
    content: () => ({
        className: 'border-none pt-3',
    }),
};
