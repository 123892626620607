export const sectorReference = [
  {
    id: "finance",
    title: "Финансы",
  },
  {
    id: "mineralResources",
    title: "Энергетические и сырьевые ресурсы",
  },
  {
    id: "nonprimaryMinerals",
    title: "Несырьевые полезные ископаемые",
  },
];

export const tickerReference = [
    {
        id: "SBER",
        title: "Сбербанк",
    },
    {
        id: "GAZP",
        title: "Газпром",
    },
    {
        id: "SNGS",
        title: "Сургутнефтегаз ПАО",
    },
    {
        id: "MAGN",
        title: "Магнитогорск.мет.комб. ПАО",
    },
];
