import { getActiveSubscriptions } from "@modules/Algopack/AlgopackPage/services/algopack.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

const getAllSubscriptionsThunk = createAsyncThunk('algopack/getAllSubscriptions', async (productId: string, { rejectWithValue }) => {
  const keysArr = [productId];
  try {
    const { data } = await getActiveSubscriptions(keysArr);

    return { data: data?.data?.rows, productId };
  } catch (e) {
    console.error('e', e);

    return rejectWithValue(e);
  }
});

export { getAllSubscriptionsThunk };
