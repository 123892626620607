import { type ForwardedRef, type ReactNode, forwardRef, useCallback, useState } from 'react';
import { Dropdown, type DropdownChangeEvent } from 'primereact/dropdown';
import './styles.css';
import classNames from 'classnames';

type SelectProps = {
    value?: unknown;
    placeholder?: string;
    label?: string | ReactNode;
    options: unknown[];
    optionLabel: string;
    onChangeHandler?: (value: DropdownChangeEvent['value']) => void;
    valueTemplate?: React.ReactNode | ((option: any) => React.ReactNode);
    itemTemplate?: React.ReactNode | ((option: any) => React.ReactNode);
};

export const Select = forwardRef(({ value, ...props }: SelectProps, ref: ForwardedRef<Dropdown>) => {
    const [chosenValue, setChosenValue] = useState<unknown>(value);

    const updateValue = useCallback((event: DropdownChangeEvent) => {
        setChosenValue(event.value);

        if (props.onChangeHandler) {
            props.onChangeHandler(event.value);
        }
    }, []);

    return (
        <div>
            <span className="p-float-label">
                <Dropdown
                    inputId="select-input"
                    onChange={updateValue}
                    value={chosenValue}
                    ref={ref}
                    className={classNames('w-full')}
                    {...props}
                />
                <label htmlFor="select-input">{props.placeholder}</label>
            </span>
        </div>
    );
});
