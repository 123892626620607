import { createAsyncThunk } from "@reduxjs/toolkit";
import { type OrderReq } from "@libs/types";
import { createOrder } from "@libs/services";

export const createOrderThunk = createAsyncThunk('order/createOrder', async (body: OrderReq, { rejectWithValue }) => {
    try {
        const { data } = await createOrder(body);

        return data.data;
    } catch (e) {
        console.error('e', e);

        return rejectWithValue(e);
    }
});
