import React, { useEffect, useState } from 'react';
import {
    type PriceCenterBond,
    type PriceCenterChart,
    type PriceCenterChartPoint,
    type PriceCenterValuation,
} from '@store/store/thunk/pricecenter/Models';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import {
    priceCenterMarketColors,
    priceCenterPriceProfitColor,
} from '@modules/PriceCenterProduct/InteractiveMap/MapsContainer';
import { createPortal } from 'react-dom';
import { isEmpty } from 'lodash';

import styles from './styles.module.scss';

export type MarketPointPanelProps = {
    point: PriceCenterChartPoint<PriceCenterBond>;
    onClick?: (point: PriceCenterChartPoint<PriceCenterBond>) => void;
};

/**
 * Шаблон тултипа Карты рынка
 */
export const MarketPointPanel = (props: MarketPointPanelProps) => {
    const {
        point,
        onClick = () => {
            return null;
        },
    } = props;

    return (
        <Button
            type="button"
            className={classNames(styles.marketPointPanel)}
            onClick={() => {
                if (onClick) {
                    onClick(point);
                }
            }}
        >
            <span>{point.rawData?.name ?? 'unknown'}</span>
            <span
                style={{
                    color: priceCenterMarketColors[point?.datasetIndex],
                }}
            >
                {`${point?.rawData?.value}%`}
            </span>
        </Button>
    );
};

export type PricePointPanelProps = {
    point: PriceCenterChartPoint<PriceCenterValuation>;
    onClick?: (point: PriceCenterChartPoint<PriceCenterValuation>) => void;
};

/**
 * Шаблон тултипа Цены/Доходности
 */
export const PricePointPanel = (props: PricePointPanelProps) => {
    const {
        point,
        onClick = () => {
            return null;
        },
    } = props;

    return (
        <Button
            type="button"
            className={classNames(styles.pricePointPanel)}
            onClick={() => {
                if (onClick) {
                    onClick(point);
                }
            }}
        >
            <span
                style={{
                    color: priceCenterPriceProfitColor[point?.datasetIndex],
                }}
            >
                {`${point?.rawData?.value}`}
            </span>
        </Button>
    );
};

export type PointHoverData<T> = {
    groupChartCode: string;
    styles: React.CSSProperties;
    point: PriceCenterChartPoint<T>;
};

/**
 * Позиционирование и отрисовка блока тултипа
 * по заданным координатам
 */
export const PointHover = (props: PointHoverData<any>) => {
    const [target, setTarget] = useState<Element>(document.body);

    useEffect(() => {
        const element = document.getElementById(`${props.groupChartCode}-hover-tooltip`);

        if (element) {
            element.style.display = 'block';
            element.style.top = String(props.styles.top) ?? '0';
            element.style.left = String(props.styles.left) ?? '0';
            setTarget(element);
        }
    }, [props]);

    return createPortal(
        props.groupChartCode === 'market'
? (
            <MarketPointPanel point={props.point} />
        )
: (
            <PricePointPanel point={props.point} />
        ),
        target,
    );
};

/**
 * Функция настроек внешнего тултипа для ChartJS.
 * Забирает координаты тултипа с кладет их в стейт
 */
export const priceCenterTooltipCallback = (
    groupChartCode: string,
    charts: Array<PriceCenterChart<any[]>>,
    selectedPoints: Array<PriceCenterChartPoint<any>>,
    onHover: (object: PointHoverData<any> | null) => void,
) => {
    return (context: any) => {
        if (context.tooltip.opacity !== 0) {
            const tooltipDataPoint = context.tooltip.dataPoints[0];

            if (tooltipDataPoint) {
                const { datasetIndex } = tooltipDataPoint;
                const pointIndex = tooltipDataPoint.dataIndex;

                // не показываем тултип для кривой доходности
                if (groupChartCode === 'market' && datasetIndex === 0) {
                    return;
                }

                const alreadySelected = selectedPoints.find(
                    (point) => point.datasetIndex === datasetIndex && point.dataPointIndex === pointIndex,
                );

                if (alreadySelected === undefined) {
                    const chart = charts[datasetIndex];
                    const chartPoint = chart.data[pointIndex];

                    if (chartPoint) {
                        const meta = context.chart.getDatasetMeta(datasetIndex);
                        const coordinates = meta.data[pointIndex].getCenterPoint();
                        onHover({
                            groupChartCode,
                            styles: {
                                left: coordinates.x + 'px',
                                top: coordinates.y - 43 + 'px',
                            },
                            point: {
                                datasetIndex,
                                dataPointIndex: pointIndex,
                                code: chartPoint.code,
                                chartCode: chart.chartCode,
                                rawData: chartPoint,
                            },
                        });
                    }
                }
            }
        } else {
            onHover(null);
        }
    };
};

/**
 * Функция обработки клика по точке для ChartJS.
 */
export const priceCenterClickCallback = (
    charts: Array<PriceCenterChart<any[]>>,
    selectedPoints: Array<PriceCenterChartPoint<any>>,
    onSelect: (point: PriceCenterChartPoint<any>) => void,
    onUnSelect: (point: PriceCenterChartPoint<any>) => void,
) => {
    return (event: any, elements: any[], chart: any) => {
        if (elements && !isEmpty(elements)) {
            const { datasetIndex } = elements[0];
            const pointIndex = elements[0].index;
            const alreadySelected = selectedPoints.find(
                (point) => point.datasetIndex === datasetIndex && point.dataPointIndex === pointIndex,
            );

            if (alreadySelected) {
                onUnSelect(alreadySelected);
            } else {
                const chart = charts[datasetIndex];
                const chartPoint = chart.data[pointIndex];

                if (chartPoint) {
                    onSelect({
                        datasetIndex,
                        dataPointIndex: pointIndex,
                        code: chartPoint.code,
                        chartCode: chart.chartCode,
                        rawData: chartPoint,
                    });
                }
            }
        }
    };
};
