import GoAlgo1 from "@shared/images/algopack/hacatonImages/GoAlgo-Photo-1.png";
import GoAlgo2 from "@shared/images/algopack/hacatonImages/GoAlgo-Photo-2.png";
import GoAlgo3 from "@shared/images/algopack/hacatonImages/GoAlgo-Photo-3.png";
import GoAlgo4 from "@shared/images/algopack/hacatonImages/GoAlgo-Photo-4.png";
import GoAlgo5 from "@shared/images/algopack/hacatonImages/GoAlgo-Photo-5.png";
import GoAlgo6 from "@shared/images/algopack/hacatonImages/GoAlgo-Photo-6.png";
import GoAlgo7 from "@shared/images/algopack/hacatonImages/GoAlgo-Photo-7.png";
import GoAlgo8 from "@shared/images/algopack/hacatonImages/GoAlgo-Photo-8.png";
import GoAlgo9 from "@shared/images/algopack/hacatonImages/GoAlgo-Photo-9.png";
import GoAlgo10 from "@shared/images/algopack/hacatonImages/GoAlgo-Photo-10.png";
import GoAlgo11 from "@shared/images/algopack/hacatonImages/GoAlgo-Photo-11.png";
import GoAlgo12 from "@shared/images/algopack/hacatonImages/GoAlgo-Photo-12.png";
import GoAlgo13 from "@shared/images/algopack/hacatonImages/GoAlgo-Photo-13.png";

export const images = [
  GoAlgo1,
  GoAlgo2,
  GoAlgo3,
  GoAlgo4,
  GoAlgo5,
  GoAlgo6,
  GoAlgo7,
  GoAlgo8,
  GoAlgo9,
  GoAlgo10,
  GoAlgo11,
  GoAlgo12,
  GoAlgo13,
];
