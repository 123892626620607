import { Footer, Navigation } from '@libs/components';
import { Outlet } from 'react-router-dom';
import React from 'react';

import styles from './styles.module.scss';

export const InvestorPro = () => {
    return (
        <div className={styles.pageWrapper}>
            <div className="main-wrapper">
                <div>
                    <Navigation />
                    <Outlet />
                </div>
            </div>
            <Footer />
        </div>
    );
};
