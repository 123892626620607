import { FilterBlock } from '@modules/ComplianceToolLanding/MainContent/components/ComplianceServices/FilterBlock';
import {
    filterList,
    servicesList,
} from '@modules/ComplianceToolLanding/MainContent/components/ComplianceServices/data';
import { useState } from 'react';
import classNames from 'classnames';
import { ServiceBlock } from '@modules/ComplianceToolLanding/MainContent/components/ComplianceServices/ServiceBlock';
import { useResize } from '@libs/utils';

import styles from './styles.module.scss';

export const ComplianceServices = () => {
    const [selectedFilter, setSelectedFilter] = useState<string>(filterList[0]);
    const { isScreenXl } = useResize();

    return (
        <section className={styles.complianceServicesWrapper}>
            <div
                className={classNames(
                    styles.titleWrapper,
                    'flex',
                    isScreenXl && 'justify-content-between',
                    !isScreenXl && 'flex-column',
                )}
            >
                <h2>Сервисы</h2>
                <FilterBlock selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} list={filterList} />
            </div>
            <div className={classNames(styles.contentWrapper, 'flex', 'flex-column')}>
                {servicesList
                    .filter((item) => selectedFilter === 'Все' || item.title === selectedFilter)
                    .map((item) => (
                        <ServiceBlock key={item.title} {...item} />
                    ))}
            </div>
        </section>
    );
};
