import classNames from 'classnames';
import { Button } from 'primereact/button';
import { ReactComponent as AlgopackIcon } from '@shared/images/algopack/AlgopackIcon.svg';
import { ReactComponent as StarIcon } from '@shared/images/svg/star.svg';
import { useModal, useAuthorize } from '@libs/utils';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState, getAllSubscriptionsThunk, subscribeThunk } from '@store/store';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ArrangmentModal, NoticeModal } from '@libs/components';

import { disclamerText } from './constants';
import styles from './styles.module.scss';

export const MainLogo = () => {
    const { loginHandler, isAuthorized } = useAuthorize();
    const { pathname } = useLocation();
    const { isOpen, closeModal, openModal } = useModal();
    const { closeModal: closeArrModal, openModal: openArrModal, isOpen: isOpenArr } = useModal();
    const productId = useMemo(() => pathname.split('/')[pathname.split('/').length - 1], [pathname]);
    const dispatch = useDispatch<AppDispatch>();
    const isSubscribed = useSelector((state: RootState) => state.algopack.isSubscribed);

    useEffect(() => {
        if (isAuthorized) {
            dispatch(getAllSubscriptionsThunk(productId));
        }
    }, [isAuthorized]);

    const handleGetAccess = async () => {
        if (isAuthorized) {
            if (!isSubscribed) {
                await dispatch(subscribeThunk());
                closeArrModal();
                openModal();
            }
        } else {
            loginHandler();
        }
    };

    const navigateToGetAccessPage = async () => {
        // if (isAuthorized) {
        await dispatch(subscribeThunk());
        window.open('https://www.moex.com/ru/analyticalproducts?futoi');
        // } else {
        //     loginHandler();
        // }
    };

    return (
        <section className={classNames(styles.mainLogoContainer)}>
            <div className={classNames(styles.header, 'flex', 'align-items-baseline')}>
                <AlgopackIcon />
            </div>
            <p>Алгоритмическая торговля через API: данные, сигналы и аналитика.</p>
            <p>
                ALGOPACK предоставляет доступ к большому набору исторических и онлайн данных по всем акциям MOEX. Теперь
                клиенты смогут тестировать и запускать собственных торговых роботов на уникальных данных
            </p>
            <Button
                className={classNames(styles.getAccessButton)}
                onClick={navigateToGetAccessPage}
                label={'Получить доступ'}
                icon={<StarIcon />}
            />
            {/*<NoticeModal*/}
            {/*    isVisible={isOpen}*/}
            {/*    closeModal={closeModal}*/}
            {/*    closeButtonText={'Закрыть'}*/}
            {/*    headerText={'Доступ предоставлен'}*/}
            {/*    mainText={'Отлично!\nВы получили доступ к продукту AlgoPack'}*/}
            {/*    className={styles.noticeModal}*/}
            {/*/>*/}
            {/*<ArrangmentModal*/}
            {/*    confirmAction={handleGetAccess}*/}
            {/*    html={disclamerText}*/}
            {/*    closeModal={closeArrModal}*/}
            {/*    isVisible={isOpenArr}*/}
            {/*/>*/}
        </section>
    );
};
