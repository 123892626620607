import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'primereact/chart';
import { type PriceCenterChartPoint } from '@store/store/thunk/pricecenter/Models';
import { isEmpty } from 'lodash';
import { Slider } from 'primereact/slider';
import zoomPlugin from 'chartjs-plugin-zoom';
import classNames from 'classnames';

import styles from './styles.module.scss';

type RangeChartProps = {
    groupChartCode: string;
    rawChartData: object | undefined;
    rawChartOptions: object | undefined;
    selectedPoints?: Array<PriceCenterChartPoint<any>>;
    generatePointTooltip?: (point: PriceCenterChartPoint<any>) => React.ReactNode;
};

export const RangeChart = (props: RangeChartProps) => {
    const {
        groupChartCode,
        rawChartData = {},
        rawChartOptions = {},
        selectedPoints = [],
        generatePointTooltip = () => null,
    } = props;

    const chartRef = useRef<Chart>(null);
    const chartContainerRef = useRef<HTMLDivElement>(null);

    const [chartData, setChartData] = useState<object | undefined>();
    const [chartOptions, setChartOptions] = useState<object | undefined>();
    const [sliderChartData, setSliderChartData] = useState<object | undefined>();

    const [hRangeValue, setHRangeValue] = useState<[number, number]>([0, 100]);
    const [vRangeValue, setVRangeValue] = useState<[number, number]>([0, 100]);

    useEffect(() => {
        setChartData(rawChartData);
        setChartOptions(rawChartOptions);
        const newSliderChartData = {
            datasets: ((rawChartData as any).datasets ?? []).filter((set: any) => set.showLine === true),
            labels: [],
        };
        setSliderChartData(newSliderChartData);
    }, [rawChartData]);

    useEffect(() => {
        const chart = chartRef.current?.getChart();

        if (chart && hRangeValue) {
            const initMin = chart.getInitialScaleBounds().x.min ?? 0;
            const initMax = chart.getInitialScaleBounds().x.max ?? 100;
            const onePercent = (initMax - initMin) / 100;
            chart.zoomScale(
                'x',
                {
                    min: initMin + onePercent * hRangeValue[0],
                    max: initMin + onePercent * hRangeValue[1],
                },
                'default',
            );
        }
    }, [chartRef, hRangeValue]);

    useEffect(() => {
        const chart = chartRef.current?.getChart();

        if (chart && vRangeValue) {
            const initMin = chart.getInitialScaleBounds().y.min ?? 0;
            const initMax = chart.getInitialScaleBounds().y.max ?? 100;
            const onePercent = (initMax - initMin) / 100;
            chart.zoomScale(
                'y',
                {
                    min: initMin + onePercent * vRangeValue[0],
                    max: initMin + onePercent * vRangeValue[1],
                },
                'default',
            );
        }
    }, [chartRef, vRangeValue]);

    /**
     * Позиционирование статичных тултипов
     */
    useEffect(() => {
        let containerDivs: any[] = [];

        if (chartContainerRef.current) {
            containerDivs = Array.from(chartContainerRef.current.children);
        }

        if (chartRef.current && !isEmpty(selectedPoints)) {
            const chart = chartRef.current.getChart();

            if (chart) {
                selectedPoints.forEach((point) => {
                    const id = `${groupChartCode}-tooltip-${point.code}`;
                    const tooltipDiv = containerDivs.find((element) => id === element.getAttribute('id'));

                    if (tooltipDiv) {
                        const meta = chart.getDatasetMeta(point.datasetIndex);
                        const coordinates = meta?.data[point.dataPointIndex]?.getCenterPoint();

                        if (coordinates) {
                            tooltipDiv.style.display = 'block';
                            tooltipDiv.style.left = coordinates.x + 'px';
                            tooltipDiv.style.top = coordinates.y - 43 + 'px';
                        } else {
                            tooltipDiv.style.display = 'none';
                        }
                    }
                });
            }
        }
    }, [selectedPoints, chartRef, chartContainerRef, hRangeValue, vRangeValue]);

    return (
        <div className="w-full h-full flex flex-column">
            <div id={`ds-${groupChartCode}-chart-container`} ref={chartContainerRef} className="relative w-full flex">
                {selectedPoints.map((point) => {
                    const key = `${groupChartCode}-tooltip-${point.code}`;

                    return (
                        <div
                            id={key}
                            key={key}
                            style={{
                                display: 'none',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 10,
                            }}
                        >
                            {generatePointTooltip(point)}
                        </div>
                    );
                })}
                <div
                    id={`${groupChartCode}-hover-tooltip`}
                    key={`${groupChartCode}-hover-tooltip`}
                    style={{
                        display: 'none',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 10,
                    }}
                />
                <Chart
                    className="w-full h-full flex-grow-1"
                    ref={chartRef}
                    data={chartData}
                    options={chartOptions}
                    plugins={[zoomPlugin]}
                />
                <Slider
                    className={styles.verticalSliderRoot}
                    value={vRangeValue}
                    onChange={(e) => setVRangeValue(e.value as any)}
                    orientation="vertical"
                    pt={{
                        range: {
                            className: styles.range,
                        },
                        handle: {
                            className: classNames(styles.handle),
                        },
                    }}
                    range
                />
            </div>
            <div className={styles.horizontalSliderContainer}>
                <Chart
                    data={sliderChartData}
                    className={styles.chartPreview}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        animation: false,
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                enabled: false,
                            },
                        },
                        scales: {
                            x: {
                                display: false,
                            },
                            y: {
                                display: false,
                            },
                        },
                    }}
                />
                <div className={styles.sliderOverlay}>
                    <Slider
                        className={styles.sliderRoot}
                        value={hRangeValue}
                        onChange={(e) => setHRangeValue(e.value as any)}
                        pt={{
                            range: {
                                className: styles.range,
                            },
                            handle: {
                                className: classNames(styles.handle),
                            },
                        }}
                        range
                    />
                </div>
            </div>
        </div>
    );
};
