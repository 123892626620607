import {
    fraudDetect,
    insiderList,
    interactionWithExchange,
    libraryEconomicConstraints,
    libraryMethodologicalRecommendations,
    monitoringCenter,
    signalsAndRecommendations,
    significantDeviations,
    signs445p,
    suspiciousActivityReport,
} from '@shared/images/complianceTool/png/complianceServices';
import { ServiceItemProps } from '@modules/ComplianceToolLanding/MainContent/components/ComplianceServices/ServiceBlock/ServiceItem';

export const filterList = ['Все', 'ПНИИИ/МР', 'ПОД/ФТ', 'Экономические ограничения', 'Внутреннее мошенничество'];

export const servicesList: { title: string; items: ServiceItemProps[] }[] = [
    {
        title: 'ПНИИИ/МР',
        items: [
            {
                title: 'Списки инсайдеров',
                description: 'Сервис подачи и ведения списков инсайдеров для профучастников и эмитентов',
                image: insiderList,
                status: 'ACTIVE',
                modalInfo: {
                    title: 'Списки инсайдеров',
                    description:
                        'Сервис подачи и ведения списков инсайдеров на Московской Бирже для профучастников и эмитентов',
                    points: ['Подача списков инсайдеров по запросу Московской биржи', 'Ведение списка инсайдеров'],
                },
            },
            {
                title: 'Центр мониторинга',
                description:
                    'WEB-интерфейс для просмотра уровня риска активности клиентов и формирования итогового отчета для регулятора',
                image: monitoringCenter,
                status: 'ACTIVE',
                modalInfo: {
                    title: 'Центр мониторинга',
                    description:
                        'WEB-интерфейс для просмотра уровня риска активности клиентов и формирования итогового отчета для регулятора',
                    points: [
                        'Интерфейс имеет функциональность анализа, расследования выявленных случаев и редактирования итогового отчета для регулятора',
                        'Уровень риска рассчитывается по результатам мониторинга и выявления неправомерных действий клиентов и сотрудников участника торгов по 224-ФЗ и 445-П',
                        'Полное покрытие законодательства',
                        'Экспорт в удобном формате',
                    ],
                },
            },
            {
                title: 'Существенные отклонения',
                description: 'Поток данных со сделками с признаками существенных отклонений для участников торгов',
                image: significantDeviations,
                status: 'ACTIVE',
                modalInfo: {
                    title: 'Существенные отклонения',
                    description: 'Поток данных со сделками с признаками существенных отклонений для участников торгов',
                    points: [
                        'Публикация единого потока сделок с существенными отклонениями по всему рынку',
                        'Подписка на API с публикацией потока сделок с существенными отклонениями конкретного участника торгов',
                    ],
                },
            },
            {
                title: 'Взаимодействие с Биржей',
                description:
                    'Коммуникационный сервис, повышающий эффективность взаимодействия по вопросам внутреннего контроля',
                image: interactionWithExchange,
                status: 'SOON_AVAILABLE',
                modalInfo: {
                    title: 'Взаимодействие с Биржей',
                    description:
                        'Коммуникационный сервис, повышающий эффективность взаимодействия по вопросам внутреннего контроля',
                    points: [
                        'Получение запросов и формирование ответов',
                        'Структурированный обмен документами и сообщениями',
                        'Прозрачность и последовательность коммуникаций',
                    ],
                },
            },
            {
                title: 'Fraud Detect',
                description: 'Отчет об операциях с признаками предварительной договоренности контрагентов',
                image: fraudDetect,
                status: 'ACTIVE',
                modalInfo: {
                    title: 'Fraud Detect для комплаенс',
                    description:
                        'CSV-отчет, содержащий алерты (сигналы), сформированные по результатам запуска на Бирже моделей детектирования, выявляющих сделки с признаками предварительной договоренности контрагентов. Доступ к отчёту осуществляется с помощью подключения к API',
                    points: [
                        'Выявление вовлечения сотрудников и клиентов в договорные сделки',
                        'Снижение рисков потерь в рамках профессиональной деятельности на рынке ценных бумаг',
                    ],
                },
            },
            {
                title: 'Сигналы и рекомендации',
                description:
                    'CSV-отчеты, содержащие сгруппированные алерты о манипуляциях, сформированные на основе рекомендаций различных профессиональных сообществ',
                image: signalsAndRecommendations,
                status: 'ACTIVE',
                modalInfo: {
                    title: 'Сигналы и рекомендации',
                    description:
                        'CSV-отчеты, содержащие сгруппированные алерты (сигналы), сформированные по результатам запуска на Бирже моделей детектирования неправомерных действий, составленных на основе рекомендаций различных профессиональных сообществ. Доступ к отчёту осуществляется с помощью подключения к API',
                    points: [
                        'Сигналы по рекомендациям Московской биржи',
                        'Сигналы по рекомендациям НАУФОР',
                        'Сигналы по рекомендациям НСФР',
                    ],
                },
            },
            {
                title: 'Библиотека методических рекомендаций',
                description:
                    'Профильный раздел, включающий в себя наиболее актуальный набор методических рекомендаций для сотрудников СВК',
                image: libraryMethodologicalRecommendations,
                status: 'SOON_AVAILABLE',
                modalInfo: {
                    title: 'Библиотека методических рекомендаций',
                    description:
                        'Профильный раздел, включающий в себя наиболее актуальный набор методических рекомендаций для сотрудников СВК',
                    points: [
                        'Перечень рекомендаций Банка России',
                        'Перечень рекомендаций Московской Биржи',
                        'Перечень рекомендаций НАУФОР, НСФР и других профессиональных сообществ',
                    ],
                },
            },
        ],
    },
    {
        title: 'ПОД/ФТ',
        items: [
            {
                title: 'Признаки 445-П',
                description: 'Отчет об операциях, подпадающих под признаки 445-П',
                image: signs445p,
                status: 'SOON_AVAILABLE',
                modalInfo: {
                    title: 'Признаки 445-П',
                    description: 'Отчет об операциях, подпадающих под признаки 445-П',
                    points: ['Подписка на API с регулярной публикацией сомнительных операций'],
                },
            },
        ],
    },
    {
        title: 'Экономические ограничения',
        items: [
            {
                title: 'Библиотека по экономическим ограничениям',
                description: 'Профильный раздел с материалами по специальным экономическим мерам',
                image: libraryEconomicConstraints,
                status: 'SOON_AVAILABLE',
                modalInfo: {
                    title: 'Библиотека по экономическим ограничениям',
                    description: '',
                    points: [],
                },
            },
        ],
    },
    {
        title: 'Внутреннее мошенничество',
        items: [
            {
                title: 'Отчет о подозрительной активности',
                description:
                    'CSV-отчет, содержащий алерты о манипуляциях, сформированные на основе рекомендаций СРО, с подсветкой по величине ущерба',
                image: suspiciousActivityReport,
                status: 'SOON_AVAILABLE',
                modalInfo: {
                    title: 'Отчет о подозрительной активности',
                    description:
                        'CSV-отчет, содержащий сгруппированные алерты (сигналы), сформированные по результатам запуска на Бирже моделей детектирования манипулятивных практик, составленных на основе рекомендаций профессиональных сообществ. Сигналы ранжированы по величине ущерба для организации – участника торгов. Доступ к отчету осуществляется с помощью подключения к API',
                    points: [
                        'Ранжирование групп сделок по величине потенциального ущерба',
                        'Регулярная публикация выявленных подозрительных групп сделок',
                        'Удобная возможность интеграции API с системами участника торгов для эффективного проведения внутреннего расследования',
                    ],
                },
            },
        ],
    },
];
