import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as PinIcon } from '@shared/images/priceCenter/svg/pinIcon.svg';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { type MenuItem } from 'primereact/menuitem';

import styles from './styles.module.scss';

type ChartMarkersModalProps = {
    selectedMarkers: React.ReactNode[];
};

export const SelectedPointsModal = (props: ChartMarkersModalProps) => {
    const { selectedMarkers } = props;

    const selectedMarkersMenuRef = useRef(null);

    const [markersMenu, setMarkersMenu] = useState<MenuItem[]>([]);

    useEffect(() => {
        const newMenu: MenuItem[] = selectedMarkers.map((marker, index) => {
            return {
                label: String(index),
                template: () => marker,
            };
        });
        newMenu.push({
            label: 'divider',
            template: () => <div className={styles.divider} />,
        });
        newMenu.push({
            label: 'clearButton',
            template: () => <Button type="button" label="Сбросить" className={styles.clearButton} />,
        });
        newMenu.push({
            label: 'addButton',
            template: () => <Button type="button" label="Добавить в выгрузку" className={styles.saveButton} />,
        });
        setMarkersMenu(newMenu);
    }, [selectedMarkers]);

    return (
        <>
            <Button
                className={styles.pinButton}
                onClick={(event) => {
                    if (selectedMarkersMenuRef && selectedMarkersMenuRef.current && selectedMarkers.length > 0) {
                        (selectedMarkersMenuRef.current as any).toggle(event);
                    }
                }}
                aria-controls="selected_markers_menu"
                aria-haspopup
            >
                <PinIcon
                    fill={selectedMarkers.length > 0 ? 'var(--ds-main-blue)' : 'var(--ds-main-light-gray)'}
                    stroke={selectedMarkers.length > 0 ? 'var(--ds-main-blue)' : 'var(--ds-main-light-gray)'}
                />
                {selectedMarkers.length > 0 && <div className={styles.pinButtonCount}>{selectedMarkers.length}</div>}
            </Button>
            <Menu
                id="selected_markers_menu"
                ref={selectedMarkersMenuRef}
                model={markersMenu}
                popup
                popupAlignment="right"
                pt={{
                    root: { className: styles.markerModal },
                    menu: { className: styles.menu },
                }}
            />
        </>
    );
};
