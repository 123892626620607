import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { type RootState } from '@store/store';

import styles from './styles.module.scss';

export const Basket = () => {
    const cart = useSelector((state: RootState) => state.cart.cart);

    return (
        <div className={styles.basketContainer}>
            <div className={styles.productsContainer}>
                <div className={styles.title}>Ваша корзина</div>
                <div className={classNames('flex', 'justify-content-between', 'align-items-center')}>
                    <div className={styles.productItem}>Всего продуктов</div>
                    <div className={classNames(styles.price)}>
                        <span>{cart ? cart.summary.itemsCount : '0'}</span>
                    </div>
                </div>
                <div className={classNames('flex', 'justify-content-between', 'align-items-center')}>
                    <div className={styles.productItem}>Общая сумма</div>
                    <div className={classNames(styles.price)}>
                        <span>{cart ? cart.summary.itemsCost : '0'}</span> <span>₽</span>
                    </div>
                </div>
                <div className={classNames('flex', 'justify-content-between', 'align-items-center')}>
                    <div className={styles.productItem}>НДС</div>
                    <div className={classNames(styles.price)}>
                        <span>{cart ? cart.summary.itemsVatCost : '0'}</span> <span>₽</span>
                    </div>
                </div>
            </div>
            <div className={classNames(styles.totalContainer, 'flex', 'align-items-center', 'justify-content-between')}>
                <div>Итого к оплате:</div>
                <div>
                    <div className={classNames(styles.totalPrice)}>
                        <span>{cart ? cart.summary.totalCost : '0'}</span> <span>₽</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
