import {
    ServiceItem,
    type ServiceItemProps,
} from '@modules/ComplianceToolLanding/MainContent/components/ComplianceServices/ServiceBlock/ServiceItem';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
    title: string;
    items: ServiceItemProps[];
};

export const ServiceBlock = ({ title, items }: Props) => {
    return (
        <div className={classNames(styles.serviceBlockWrapper, 'flex', 'flex-column')}>
            <h4>{title}</h4>
            <div className={classNames('flex')}>
                {items.map((item) => (
                    <ServiceItem key={item.title} {...item} />
                ))}
            </div>
        </div>
    );
};
