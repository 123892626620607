import { httpClient } from '@configs/httpclient.config';
import { type AxiosResponse } from 'axios';
import {
    GetMarketBondsReqBody,
    GetMarketBondsRespBody,
    type GetMarketPointsReqBody,
    GetMarketPointsRespBody,
    GetPricePointsReqBody,
    GetPricePointsRespBody,
    GetProfitPointsRespBody,
    type ResponseInfo
} from '@libs/types';
import { type PriceCenterBond } from '@store/store/thunk/pricecenter/Models';

export const PROFIT_CURVE_URL = '/moex-propack-integrationservice/api/curves/v1/profit-curve';

export const MARKET_POINT_URL = '/moex-propack-integrationservice/api/v1/instrument-points/';

export const BONDS_INSTRUMENT_URL = '/moex-datashop-instrument-service/api/v1/bonds/bonds_params';

export const PRICES_POINT_URL = '/moex-propack-integrationservice/api/v1/instrument-prices/isin/';

export const PROFIT_POINT_URL = '/moex-propack-integrationservice/api/v1/instrument-points/isin';

export const getProfitCurves = (date: string): Promise<AxiosResponse<ResponseInfo<PriceCenterBond[]>>> =>
    httpClient.post(PROFIT_CURVE_URL, { date });

export const getMarketPoints = (body: GetMarketPointsReqBody): Promise<AxiosResponse<GetMarketPointsRespBody[]>> =>
    httpClient.post(MARKET_POINT_URL, body);

export const getMarketBonds = (body: GetMarketBondsReqBody): Promise<AxiosResponse<GetMarketBondsRespBody>> =>
    httpClient.post(BONDS_INSTRUMENT_URL, body);

export const getPricePoints = (body: GetPricePointsReqBody): Promise<AxiosResponse<GetPricePointsRespBody>> =>
    httpClient.post(PRICES_POINT_URL, body);

export const getProfitPoints = (body: GetPricePointsReqBody): Promise<AxiosResponse<GetProfitPointsRespBody>> =>
    httpClient.post(PROFIT_POINT_URL, body);
