import classNames from 'classnames/bind';
import React, { useEffect, useMemo, useState } from 'react';
import { type ResizeFlags, useResize } from '@libs/utils/hooks/useResize';
import { ReactComponent as MediumSmallGraph } from '@shared/images/algopack/openFutures/MediumSmallGraph.svg';
import { ReactComponent as SmallGraph } from '@shared/images/algopack/openFutures/SmallGraph.svg';
import { ReactComponent as ScalableGraph } from '@shared/images/algopack/openFutures/ScalableGraph.svg';
import { Code } from '@modules/Algopack/AlgopackPage/components/OpenFutures/Code';

import styles from './styles.module.scss';
import { getFutouiData, getLongShortPostions, getPriceContract } from '../../services/futoi.service';
import { type FutoiGraphData, type GraphItem, type LongShortGraphItem, type PersonType } from '../../services/types';
import FutoiChart from './FutoiChart';

type TabItemsType = TabTypes[];
type TabTypes = { label: string; value: PersonType };

const todaysdate = '2024-03-29';

const tabItems: TabItemsType = [
    { label: 'Юридические лица', value: 'legal_person' },
    { label: 'Физические лица', value: 'physical_person' },
];

const cx = classNames.bind(styles);

const extractSizeByTrueValue = (obj: ResizeFlags): keyof ResizeFlags => {
    const keys = Object.keys(obj) as Array<keyof ResizeFlags>;
    const keyWithTrueValue = keys.find((key) => obj[key]);

    return keyWithTrueValue ?? keys[0];
};

const graphImages = {
    isScreenLessSm: <SmallGraph />,
    isScreenSm: <SmallGraph />,
    isScreenMd: <ScalableGraph />,
    isScreenLg: <MediumSmallGraph />,
    isScreenXl: <ScalableGraph />,
};

const INITIAL_FUTOI_GRAPH_DATA = {
    longPosition: [],
    shortPosition: [],
    priceContract: [],
};

export const OpenFutures = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const [fetchGraphData, setFetchGraphData] = useState<FutoiGraphData>(INITIAL_FUTOI_GRAPH_DATA);
    const [futoiKey, setFutoiKey] = useState<number>(0);

    // TODO: remove when BE changed DTO
    const longShortResMapper = (inputArray: LongShortGraphItem[]) => {
        const allShortPositions: GraphItem[] = [];
        const allLongPositions: GraphItem[] = [];

        inputArray.forEach((item) => {
            allShortPositions.push({
                tradeDateTime: `${item.tradeDate}T${item.tradeTime}`,
                value: item.shortPosition,
            });
            allLongPositions.push({
                tradeDateTime: `${item.tradeDate}T${item.tradeTime}`,
                value: item.longPosition,
            });
        });

        return { shortPositions: allShortPositions, longPositions: allLongPositions };
    };

    useEffect(() => {
        const fetchGraphData = async () => {
            try {
                const { data: futoiDataResponse } = await getFutouiData({ date: '2023-12-05', graph_name: 'futoi' });
                setFetchGraphData({ ...futoiDataResponse.data });
            } catch (error) {
                console.log('error: ', error);
            }
        };

        fetchGraphData();
    }, [activeIndex, todaysdate]);

    const { width, ...sizeFlags } = useResize();

    useEffect(() => {
        setFutoiKey((prev) => prev + 1);
    }, [width]);

    const currentGraphImage = useMemo(() => graphImages[extractSizeByTrueValue(sizeFlags)], [sizeFlags]);

    return (
        <section className={styles.examplesWrapper}>
            <h2 className={styles.examplesWrapper__title}>Открытые позиции по фьючерсам</h2>
            <p className={styles.examplesWrapper__description}>
                Визуализируйте потоки торговых данных — графики позволяют сделать наглядным движение рынка. Пример кода
                ниже.
            </p>

            <div className={styles.examplesWrapper__chartWrapper}>
                <div className={styles.examplesWrapper__chartWrapper__chartSide}>
                    <h4 className={styles.subTitle}>ПРИМЕР ВИЗУАЛИЗАЦИИ ДАННЫХ FUTOI</h4>
                    <div className={cx('tabs', 'examplesWrapper__chartWrapper__chartSide__tabs')}>
                        <button
                            className={cx('tabs__button', { activeTab: activeIndex === 0 })}
                            onClick={() => setActiveIndex(0)}
                        >
                            Физические лица
                        </button>
                    </div>

                    {/*TODO: Раскомментировать после релиза*/}
                    <div className={cx('chart')}>
                        {fetchGraphData ? <FutoiChart key={futoiKey} data={fetchGraphData} /> : null}
                    </div>
                    {/* {currentGraphImage} */}
                    <div className={cx('legend', 'examplesWrapper__chartWrapper__chartSide__legend')}>
                        <div className={cx('legend__item')}>Цена контракта</div>
                        <div className={cx('legend__item', 'greenLegendItem')}>Длинные позиции</div>
                        <div className={cx('legend__item', 'redLegendItem')}>Короткие позиции</div>
                    </div>
                </div>
                <div className={styles.examplesWrapper__chartWrapper__codeSide}>
                    <h4 className={styles.subTitle}>ПРИМЕР КОДА</h4>
                    <div className={styles.examplesWrapper__chartWrapper__codeSide__codeWrapper}>
                        <div className={styles.examplesWrapper__chartWrapper__codeSide__code}>
                            <Code />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
