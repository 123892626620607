import classNames from 'classnames';
import mainStyles from '@modules/Algopack/AlgopackPage/components/DataConstitution/DataItem/styles.module.scss';
import { ReactComponent as UpdateLogo } from '@shared/images/algopack/dataConstitution/updateLogo.svg';
import { ReactComponent as WhiteMark } from '@shared/images/algopack/dataConstitution/whiteMark.svg';
import { ReactComponent as Minus } from '@shared/images/algopack/dataConstitution/minus.svg';
import { type DataItemType } from '@modules/Algopack/AlgopackPage/components/DataConstitution/DataItem';
import { useResize } from '@libs/utils';

import styles from './styles.module.scss';

export const DesktopVersion = ({
    title,
    tags,
    description,
    logo,
    dataUpdateTime,
    historicalData,
    isPythonLib,
    lastElement,
}: DataItemType) => {
    const { isScreenXl, isScreenLg, isScreenMd } = useResize();

    const dataUpdateElement = (
        <div
            className={classNames(
                styles.dataUpdateWrapper,
                'flex',
                'align-items-center',
                !lastElement && isScreenXl && styles.borderBtm,
            )}
        >
            <UpdateLogo />
            <span>{dataUpdateTime}</span>
        </div>
    );

    const historicalDataElement = (
        <p
            className={classNames(
                styles.historicalDataWrapper,
                'flex',
                'align-items-center',
                !lastElement && isScreenXl && styles.borderBtm,
            )}
        >
            {historicalData || '-'}
        </p>
    );

    const pythonLibElement = (
        <div
            className={classNames(
                styles.pythonLibWrapper,
                'flex',
                'justify-content-center',
                'align-items-center',
                !lastElement && isScreenXl && styles.borderBtm,
            )}
        >
            {isPythonLib ? <WhiteMark /> : <Minus />}
        </div>
    );

    return (
        <>
            <div className={classNames(styles.mainDataWrapper, 'flex')}>
                <img src={logo} />
                <div className={classNames('flex', 'flex-column', !lastElement && isScreenXl && styles.borderBtm)}>
                    <div className={classNames(mainStyles.titleWrapper, 'flex', isScreenMd && 'flex-column')}>
                        <h4>{title}</h4>
                        <div className={classNames(mainStyles.tagsWrapper, 'flex')}>
                            {tags.map((tag) => (
                                <span key={tag}>{tag}</span>
                            ))}
                        </div>
                    </div>

                    <p>{description}</p>
                </div>
            </div>

            <div className={classNames(styles.tableDataWrapper, 'flex')}>
                {isScreenLg || isScreenXl
? (
                    <>
                        {dataUpdateElement}
                        {historicalDataElement}
                        {pythonLibElement}
                    </>
                )
: (
                    <>
                        <div className={classNames('flex', 'flex-column', 'align-items-end')}>
                            {dataUpdateElement}
                            {historicalDataElement}
                        </div>
                        {pythonLibElement}
                    </>
                )}
            </div>
        </>
    );
};
