import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
type ContactArticleProps = {
    title: string;
    text: string;
    svg: string;
    buttonText: string;
    link?: string;
    blankLink?: boolean;
    disabled?: boolean;
};

export const ContactArticle = ({
    text,
    buttonText,
    svg,
    title,
    link,
    blankLink,
    disabled = false,
}: ContactArticleProps) => {
    const navigate = useNavigate();
    const navigateToLink = (e: any) => {
        if (link) {
            e.preventDefault();

            if (blankLink) {
                window.open(link);
            } else {
                navigate(link);
            }
        }
    };

    return (
        <article
            className={classNames(
                styles.articleWrapper,
                'flex',
                'justify-content-start',
                'align-items-start',
                'flex-column',
            )}
        >
            <h6>{title}</h6>
            <div>
                <p>{text}</p>
                <div
                    onClick={disabled ? () => null : navigateToLink}
                    className={classNames('inline-flex', 'justify-content-center', 'align-items-center')}
                    style={disabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                >
                    <img src={svg} />
                    <span>{buttonText}</span>
                </div>
            </div>
        </article>
    );
};
