import { ReactComponent as Trash } from '@shared/images/svg/Trash.svg';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const TrashBlock = ({ onClick }: { onClick: () => void }) => {
    return (
        <div onClick={onClick} className={classNames(styles.trashContainer, 'flex', 'align-items-center')}>
            <div className={classNames('flex', 'align-items-center')}>
                <span>Удалить</span>
                <Trash />
            </div>
        </div>
    );
};
