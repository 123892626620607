import { useState } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import { ModalWindow } from '@libs/components';

import { ReactComponent as SettingsIcon } from '../../../../../../shared/images/svg/Settings.svg';
import { Search } from '../../../../../../shared/components/Search';
import styles from './styles.module.scss';

export const IsFavouriteHeader = () => {
    const [isVisible, setIsVisible] = useState(false);
    const tabMock = [
        {
            id: 2,
            title: 'Описательные',
            body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis ducimus ex mollitia saepe sequi voluptatibus. Corporis fugiat id minus molestias nam placeat quae quos saepe sapiente soluta. Culpa, eius tempora?',
        },
        {
            id: 3,
            title: 'Технические',
            body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus enim harum illo maxime minima rem sed suscipit ullam! At ex impedit iusto laudantium provident rerum sed sint sunt vitae voluptates?',
        },
        {
            id: 4,
            title: 'Финансовые',
            body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A accusantium autem delectus dolore repudiandae. Asperiores eveniet hic iure quasi? Ad alias, fugit molestias quidem repudiandae sint sit tempore ut voluptas.',
        },
    ];

    function handleCellClick() {
        setIsVisible(false);
    }

    return (
        <>
            <SettingsIcon className={styles.isFavouriteIcon} onClick={handleCellClick} />
            <ModalWindow isVisible={isVisible} closeModal={() => setIsVisible(false)}>
                <div>Настроить колонки</div>
                <Search />
                <div className={styles.tabWrapper}>
                    <TabView>
                        <TabPanel header="Популярные">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque, maiores quidem? Dolor
                            ex quibusdam ullam? A accusantium, eos eum, hic illo molestias nesciunt, officia pariatur
                            perspiciatis placeat ratione sed vero?
                        </TabPanel>
                        {tabMock.map((tab) => (
                            <TabPanel key={tab.id} header={tab.title}>
                                {tab.body}
                            </TabPanel>
                        ))}
                    </TabView>
                    <div className={styles.sortWrapper} />
                </div>
            </ModalWindow>
        </>
    );
};
