import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@store/store';
import { getProductListThunk } from '@store/store/thunk/product.thunk';
import { type ProductReq } from '@libs/types';
import { ProductCard, ProductMarks } from '@libs/components';
import { useLocation } from 'react-router-dom';

import styles from './styles.module.scss';

const bodyReq: ProductReq = {
    pageable: {
        pageSize: 10,
        pageNumber: 0,
        sortField: 'order',
        sortOrder: 'asc',
    },
    statuses: ['ACTIVE', 'SOON_AVAILABLE'],
};

export const Products = () => {
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const { goToProducts, productsRef } = location.state || {};

    const productList = useSelector((state: RootState) => state.product.productList);

    useEffect(() => {
        dispatch(getProductListThunk(bodyReq));
    }, []);

    useEffect(() => {
        if (goToProducts && productsRef?.current) {
            productsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [goToProducts, productsRef]);

    return (
        <section ref={productsRef} className={classNames(styles.productsWrapper)}>
            <h3>DATA ПРОДУКТЫ</h3>
            {/*<ProductMarks />*/}
            <div className={styles.cardWrapper}>
                {productList?.map((data) => {
                    return <ProductCard key={data.id} data={data} />;
                })}
            </div>
        </section>
    );
};
