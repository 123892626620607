import { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { type PlotData } from 'plotly.js';
import type Plotly from 'plotly.js';
import classNames from 'classnames';
import { hideLoader, showLoader } from '@store/store/slices/loading.slice';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '@store/store';
import { type ChartData } from 'chart.js';
import {
    plotlyToChartjsCoordsMapper,
    plotlyToSpfiChartjsCoordsMapper,
} from '@modules/ProPack/shared/utils/plotlyToChartjsCoordsMapper.util';
import { isEqual } from 'lodash';
import { addMinutes, format } from 'date-fns';
import {
    scatterOptions,
    marketData,
    markerColors,
    MAX_PLOTS_NUMBER,
    initSurfaceStartDate,
    initSurfaceEndDate,
    formatDateStr,
    lastDate,
} from '@modules/ProPack/shared/utils/dataConfig';
import { type Nullable } from 'primereact/ts-helpers';

import { getTrace } from '../../shared/utils/getTrace.util';
import { getCurvesCoords } from './services/getPropackCurves.service';
import { SurfaceControlPanel } from './components/SurfaceControlPanel';
import { SurfaceChart } from './components/SurfaceChart';
import { ScatterControlPanel } from './components/ScatterControlPanel';
import { YieldCurvesBg } from '../../shared/images/png';
import styles from './styles.module.scss';
import { CurvesChartWithTooltips } from './components/CurvesChartWithTooltips';
import { type Curve, type CurveGroup, type Market } from './types';
import { InterestRateChartWithTooltips } from './components/InterestrateChartWithTooltip';

export const YieldCurves = () => {
    const [initCoordsData, setInitCoordsData] = useState<Partial<PlotData | any>>({});
    const [scatterValue, setScatterValue] = useState(scatterOptions[0]);
    const [dates, setDates] = useState<Date[] | any>([]);

    const [surfaceData, setSurfaceData] = useState<Array<Partial<PlotData | any>>>([]);
    const [yieldCurves, setYieldCurves] = useState<Partial<ChartData> | any>({ datasets: [] });
    const [interestRates, setInterestRates] = useState<Partial<ChartData> | any>({ datasets: [] });
    const [spfiDate, setSpfiDate] = useState<Nullable<Date>>(new Date(lastDate));
    const [spfiRate, setSpfiRate] = useState<string>();
    const [selectedSpfiCurves, setSelectedSpfiCurves] = useState<string[]>([]);
    const [selectedSpfiRates, setSelectedSpfiRates] = useState<string[]>([]);
    const [rateOptions, setRateOptions] = useState<string[]>([]);

    const [selectedMarket, setSelectedMarket] = useState<Market>(marketData[0]);
    const [selectedCurveGroup, setSelectedCurveGroup] = useState<CurveGroup>(selectedMarket.curveGroups[0]);
    const [selectedCurve, setSelectedCurve] = useState<Curve>(selectedCurveGroup.curves[0]);

    const [yieldCurvesColors, setYieldCurvesColors] = useState<Plotly.Color[]>(markerColors);
    const [interestRateColors, setInterestRateColors] = useState<Plotly.Color[]>(markerColors);

    const dispatch = useDispatch<AppDispatch>();
    const dateChips = useMemo(() => {
        return yieldCurves?.datasets?.map((item: any) => {
            return { date: item.label, color: item.borderColor };
        });
    }, [yieldCurves]);
    const rateChips = useMemo(() => {
        return interestRates?.datasets?.map((item: any) => {
            return { rate: item.label, color: item.borderColor };
        });
    }, [interestRates]);

    const initSetCoords = async (
        market: string,
        curveGroup: string,
        curveName: string,
        startDate: string,
        endDate: string,
    ) => {
        try {
            dispatch(showLoader());
            setYieldCurvesColors(markerColors);
            setInterestRateColors(markerColors);
            const newCoordsResponse = await getCurvesCoords(market, curveGroup, curveName, startDate, endDate);
            const rawCoordsData = newCoordsResponse.data.data;
            setInitCoordsData({ x: rawCoordsData.x, y: rawCoordsData.y, z: rawCoordsData.z });
            setSurfaceData([rawCoordsData]);
            setRateOptions(rawCoordsData.x?.[0]);
            setYieldCurves({ datasets: [] });
            setInterestRates({ datasets: [] });
            dispatch(hideLoader());
        } catch (e) {
            console.error('error = ', e);
            dispatch(hideLoader());
        }
    };

    useEffect(() => {
        setDates([new Date(initSurfaceStartDate), new Date(initSurfaceEndDate)]);
    }, []);

    useEffect(() => {
        if (dates[1]) {
            const lastDateIndex = dates.length - 1;
            const today = new Date();
            const isEndCurrentDate = dates[lastDateIndex].toDateString() === today.toDateString();
            const offset = today.getTimezoneOffset() + 180;
            dates[lastDateIndex] = isEndCurrentDate ? addMinutes(today, offset) : dates[1];
            const strDatesStart = format(dates[0], formatDateStr);
            const strDatesEnd = format(dates[lastDateIndex], formatDateStr);

            setSelectedSpfiCurves([]);
            setSelectedSpfiRates([]);
            initSetCoords(
                selectedMarket.value,
                selectedCurveGroup.value,
                selectedCurve.value,
                strDatesStart,
                strDatesEnd,
            );
        }
    }, [dates, selectedMarket.value, selectedCurveGroup.value, selectedCurve.value]);

    const updateScatterType = (scatterName: string) => {
        setScatterValue(scatterName);
    };

    const updateMarketData = (market: Market) => {
        setSelectedMarket(market);
        setDates([new Date(initSurfaceStartDate), new Date(initSurfaceEndDate)]);
        setSelectedCurveGroup(market.curveGroups[0]);
        setSelectedCurve(market.curveGroups[0].curves[0]);
        setYieldCurvesColors(markerColors);
        setInterestRateColors(markerColors);
    };
    const updateCurveGroup = (curveGroupName: string) => {
        const curveGroupObj = selectedMarket.curveGroups.find((curveGroup) => curveGroup.value === curveGroupName);

        if (curveGroupObj) {
            setSelectedCurveGroup(curveGroupObj);
            setSelectedCurve(curveGroupObj.curves[0]);
            setYieldCurvesColors(markerColors);
            setInterestRateColors(markerColors);
        }
    };
    const updateCurve = (curveName: string) => {
        const curveObj = selectedCurveGroup.curves.find((curve) => curve.value === curveName);

        if (curveObj) {
            setSelectedCurve(curveObj);
        }
    };

    const addYieldCurve = (yieldCurveCoordIndex: number) => {
        const isSpfiMarket = selectedMarket.value === 'spfi';

        if (
            yieldCurves.datasets.length < MAX_PLOTS_NUMBER ||
            (isSpfiMarket && yieldCurves.datasets.length <= MAX_PLOTS_NUMBER)
        ) {
            const lineColor = yieldCurvesColors[0];
            const newTrace: Partial<PlotData> = getTrace({
                x: surfaceData[0]?.x[yieldCurveCoordIndex],
                y: surfaceData[0]?.y[yieldCurveCoordIndex],
                z: surfaceData?.[0]?.z[yieldCurveCoordIndex],
                color: lineColor,
                curveName: surfaceData[0]?.curveName,
            });
            const hasNewTrace = surfaceData.some((item) => (item.z && newTrace.z ? isEqual(item.z, newTrace.z) : true));

            if (!hasNewTrace) {
                const newData = isSpfiMarket ? [surfaceData[0], newTrace] : [...surfaceData, newTrace];
                setSurfaceData(newData);
                const newYieldCurve = isSpfiMarket
                    ? plotlyToSpfiChartjsCoordsMapper(newTrace, 'xAxis')
                    : plotlyToChartjsCoordsMapper(newTrace, 'xAxis');
                const newYieldCurveObj = {
                    datasets: isSpfiMarket
                        ? [newYieldCurve.datasets?.[0]]
                        : [...yieldCurves.datasets, newYieldCurve.datasets?.[0]],
                };
                setYieldCurves(newYieldCurveObj);

                if (isSpfiMarket) {
                    const newDate = new Date(newTrace.y?.[0] as string);
                    setSpfiDate(newDate);
                    setSelectedSpfiCurves([newYieldCurve.datasets?.[0].label!]);
                } else {
                    setYieldCurvesColors(yieldCurvesColors.filter((el) => el !== lineColor));
                }
            }
        }
    };

    const addInterestRate = (interetRateCoordIndex: number) => {
        const isSpfiMarket = selectedMarket.value === 'spfi';

        if (
            interestRates.datasets.length < MAX_PLOTS_NUMBER ||
            (isSpfiMarket && interestRates.datasets.length <= MAX_PLOTS_NUMBER)
        ) {
            const lineColor = interestRateColors[0];

            const newInterestArr = Object.fromEntries(
                Object.entries(initCoordsData).map(([key, value]: [string, Plotly.Datum[] | any]) => [
                    key,
                    value?.map((item: Plotly.Datum[]) => item[interetRateCoordIndex]),
                ]),
            );
            const newTrace: Partial<PlotData> = getTrace({
                ...newInterestArr,
                color: lineColor,
                curveName: surfaceData[0].curveName,
            });
            const hasNewTrace = surfaceData.some((item) => item.z && newTrace.z ? isEqual(item.z, newTrace.z) : false,
            );

            if (!hasNewTrace) {
                const newData = isSpfiMarket ? [surfaceData[0], newTrace] : [...surfaceData, newTrace];
                setSurfaceData(newData);
                const newInterestRate = isSpfiMarket
                    ? plotlyToSpfiChartjsCoordsMapper(newTrace, 'yAxis')
                    : plotlyToChartjsCoordsMapper(newTrace, 'yAxis');
                const newInterestRateObj = {
                    datasets: isSpfiMarket
                        ? [newInterestRate.datasets?.[0]]
                        : [...interestRates.datasets, newInterestRate.datasets?.[0]],
                };
                setInterestRates(newInterestRateObj);

                if (isSpfiMarket) {
                    setSpfiRate(newTrace.x?.[0] as string);
                    setSelectedSpfiRates([newInterestRate.datasets?.[0].label!]);
                } else {
                    setInterestRateColors(interestRateColors.filter((el) => el !== lineColor));
                }
            }
        }
    };

    const updateSpfiChart = async (curves: string[]): Promise<void> => {
        const isYieldCurvesSelected = scatterValue === scatterOptions[0];

        const isCurveRemoved = isYieldCurvesSelected
            ? selectedSpfiCurves.length > curves.length
            : selectedSpfiRates.length > curves.length;

        if (curves.length <= MAX_PLOTS_NUMBER) {
            if (isYieldCurvesSelected) {
                setSelectedSpfiCurves(curves);
            } else {
                setSelectedSpfiRates(curves);
            }

            if (isCurveRemoved) {
                const curvesDatasetArr = isYieldCurvesSelected ? yieldCurves.datasets : interestRates.datasets;
                const removedChart = curvesDatasetArr.find((obj: any) => !curves.includes(obj['label']));

                if (removedChart) handleRemoveTrace(removedChart.label);
            } else {
                try {
                    dispatch(showLoader());
                    const startDate = isYieldCurvesSelected
                        ? format(spfiDate!, formatDateStr)
                        : format(dates[0], formatDateStr);
                    const endDate = isYieldCurvesSelected ? null : format(dates[dates.length - 1], formatDateStr);
                    const newCoordsResponse = await getCurvesCoords(
                        selectedMarket.value,
                        selectedCurveGroup.value,
                        curves[curves.length - 1],
                        startDate,
                        endDate,
                    );
                    const rawCoordsData = newCoordsResponse.data.data;

                    if (isYieldCurvesSelected) {
                        const usedColors: Plotly.Color[] = yieldCurves.datasets.map(
                            (dataset: any) => dataset.borderColor,
                        );

                        const availableColors = markerColors.filter((color) => !usedColors.includes(color));
                        setYieldCurvesColors(availableColors);
                        const newTrace = getTrace({
                            x: rawCoordsData.x[0],
                            y: rawCoordsData.y[0],
                            z: rawCoordsData.z[0],
                            color: availableColors[0],
                            curveName: rawCoordsData.curveName,
                        });
                        const newYieldCurve = plotlyToSpfiChartjsCoordsMapper(newTrace, 'xAxis');
                        setYieldCurves({ datasets: [...yieldCurves.datasets, newYieldCurve.datasets?.[0]] });
                    } else {
                        const usedColors: Plotly.Color[] = interestRates.datasets.map(
                            (dataset: any) => dataset.borderColor,
                        );

                        const availableColors = markerColors.filter((color) => !usedColors.includes(color));
                        setInterestRateColors(availableColors);
                        const rawCoords = {
                            x: rawCoordsData.x,
                            y: rawCoordsData.y,
                            z: rawCoordsData.z,
                        };
                        const rateIndex = rawCoords.x[0].indexOf(spfiRate!);
                        const newInterestArr = Object.fromEntries(
                            Object.entries(rawCoords).map(([key, value]: [string, Plotly.Datum[] | any]) => [
                                key,
                                value?.map((item: Plotly.Datum[]) => item[rateIndex]),
                            ]),
                        );
                        const newTrace: Partial<PlotData> = getTrace({
                            ...newInterestArr,
                            color: availableColors[0],
                            curveName: rawCoordsData.curveName,
                        });
                        const newInterestRate = plotlyToSpfiChartjsCoordsMapper(newTrace, 'yAxis');
                        setInterestRates({
                            datasets: [...interestRates.datasets, newInterestRate.datasets?.[0]],
                        });
                    }
                    dispatch(hideLoader());
                } catch (e) {
                    console.error('error = ', e);
                    dispatch(hideLoader());
                }
            }
        }
    };
    const handleRemoveTrace = (removedCurveTitle: string | number) => {
        if (scatterValue === scatterOptions[0]) {
            const newSurfData = surfaceData.filter((plot) => plot?.y?.[0] !== removedCurveTitle.toString());
            setSurfaceData(newSurfData);
            const removedCurve = yieldCurves.datasets.find((item: any) => item?.label === removedCurveTitle.toString());
            const newYieldCurvesData = yieldCurves.datasets.filter(
                (item: any) => item?.label !== removedCurveTitle.toString(),
            );
            setYieldCurves({
                datasets: newYieldCurvesData,
            });
            setYieldCurvesColors([removedCurve?.borderColor, ...yieldCurvesColors]);
        } else {
            const newSurfData = surfaceData.filter((plot) => plot?.x?.[0] !== removedCurveTitle);
            setSurfaceData(newSurfData);
            const removedCurve = interestRates.datasets.find(
                (item: any) => item?.label === removedCurveTitle.toString(),
            );
            const newInterestRateData = interestRates.datasets.filter(
                (item: any) => item?.label !== removedCurveTitle.toString(),
            );
            setInterestRates({ datasets: newInterestRateData });
            setInterestRateColors([removedCurve?.borderColor, ...interestRateColors]);
        }
    };

    const addYieldCurveByDate = (date: Nullable<Date>) => {
        if (date) {
            const dateStr = format(date, formatDateStr);
            const yIndex = surfaceData[0].y?.findIndex((arr: Plotly.Datum[] | any) => arr[0] === dateStr);

            if (yIndex) {
                addYieldCurve(yIndex);
            }
        }
    };
    const addInterestByRate = (rate: Nullable<number>) => {
        if (rate) {
            const xCoordsArr = surfaceData[0].x?.[0] as Plotly.Datum[];
            const xIndex = xCoordsArr?.findIndex((element: number | any) => element === rate);

            if (xIndex !== -1) {
                addInterestRate(xIndex);
            }
        }
    };

    const handleChartClick = debounce((event: Readonly<Plotly.PlotMouseEvent>) => {
        const { pointNumber } = event.points[0] as any;
        const elementIndex = pointNumber[0];
        const arrIndex = pointNumber[1];

        switch (scatterValue) {
            case scatterOptions[0]:
                addYieldCurve(arrIndex);
                break;
            case scatterOptions[1]:
                addInterestRate(elementIndex);
                break;
        }
    }, 500);

    return (
        <div
            style={{ background: `url(${YieldCurvesBg}) top / auto no-repeat` }}
            className={classNames(styles.curvesWrapper)}
        >
            <div className={classNames(styles.blockWrapper)}>
                <SurfaceControlPanel
                    calendarDates={dates}
                    setCalendarDates={setDates}
                    selectedMarket={selectedMarket}
                    handleMarketChange={updateMarketData}
                    selectedCurveGroup={selectedCurveGroup}
                    handleCurveGroupChange={updateCurveGroup}
                    selectedCurve={selectedCurve}
                    handleCurveChange={updateCurve}
                />
                <SurfaceChart surfaceData={surfaceData} handleChartClick={handleChartClick} market={selectedMarket} />
            </div>
            <div className={classNames(styles.blockWrapper)}>
                <ScatterControlPanel
                    dateChips={dateChips}
                    rateChips={rateChips}
                    scatterType={scatterValue}
                    selectedOption={scatterValue}
                    handleScatterChange={updateScatterType}
                    removeTrace={handleRemoveTrace}
                    selectedMarket={selectedMarket}
                    selectedCurveGroup={selectedCurveGroup}
                    handleCurveGroupChange={updateCurveGroup}
                    handleDatePicked={addYieldCurveByDate}
                    rateOptions={rateOptions}
                    handleRatePicked={addInterestByRate}
                    spfiDate={spfiDate}
                    spfiRate={spfiRate}
                    handleSpfiChartChange={updateSpfiChart}
                    selectedSpfiCurves={selectedSpfiCurves}
                    selectedSpfiRates={selectedSpfiRates}
                />
                {scatterValue === scatterOptions[0]
? (
                    <CurvesChartWithTooltips coordsObj={yieldCurves} market={selectedMarket} />
                )
: (
                    <InterestRateChartWithTooltips coordsObj={interestRates} market={selectedMarket} />
                )}
            </div>
        </div>
    );
};
