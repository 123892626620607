import { ModalWindow } from '@libs/components';
import { ReactComponent as WatchVideoIcon } from '@shared/images/algopack/WatchVideoIcon.svg';
import { ReactComponent as PresentationIcon } from '@shared/images/algopack/PresentationIcon.svg';
import { ReactComponent as CodeIcon } from '@shared/images/algopack/CodeIcon.svg';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { useResize } from '@libs/utils';

import { type SolutionType } from '../data';
import styles from './styles.module.scss';

type DetailsModal = {
    closeModal: () => void;
    isOpen: boolean;
    solution?: SolutionType;
};

export const DetailsModal = ({ closeModal, isOpen, solution }: DetailsModal) => {
    const { isScreenSm } = useResize();

    return (
        <ModalWindow closeModal={closeModal} isVisible={isOpen}>
            <div className={classNames(styles.wrapper)}>
                <div className={isScreenSm ? classNames('flex', 'flex-column-reverse') : classNames('inline-flex')}>
                    <h6>{solution?.name}</h6>
                    {solution?.label && solution?.color && (
                        <span className={styles[solution?.color]}>{solution?.label}</span>
                    )}
                </div>
                {parse(solution?.fullHTmlDescription || '')}
                <div className={classNames(styles.blueBlock, 'flex')}>
                    <div className={classNames('inline-flex')}>
                        <CodeIcon />
                        <a
                            target="_blank"
                            href={solution?.codeLink}
                            rel="noreferrer"
                            className={classNames(!solution?.codeLink && styles.disabledLink)}
                        >
                            Исходный код
                        </a>
                    </div>
                    {solution?.gideLink && (
                        <div className={classNames('inline-flex')}>
                            <PresentationIcon />
                            <a target="_blank" rel="noreferrer" href={solution?.gideLink}>
                                Гайд
                            </a>
                        </div>
                    )}
                    {solution?.presentationLink && (
                        <div className={classNames('inline-flex')}>
                            <PresentationIcon />
                            <a target="_blank" rel="noreferrer" href={solution?.presentationLink}>
                                Презентация
                            </a>
                        </div>
                    )}
                    {solution?.videoLink && (
                        <div className={classNames('inline-flex')}>
                            <WatchVideoIcon />
                            <a target="_blank" rel="noreferrer" href={solution?.videoLink}>
                                Видео
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </ModalWindow>
    );
};
